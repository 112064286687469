import back from "assets/back.svg";
import menu from "assets/menu.svg";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { startCase } from "lodash";
import { useEffect, useRef, useState } from "react";

import AccessDenied from "components/AccessDenied";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AnyObject } from "utils/types";
import { useFetchRoles, useFetchUsersByRole } from "./useAdminHandlers";
import Loader from "components/Loader";

type ShowType = "menu" | "modal" | undefined;
const ManageRole = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState("");
  const [show, setShow] = useState<ShowType>(undefined);
  const popRef = useRef(null);

  const { roles, isError, error, loadingRoles } = useFetchRoles();

  const onClickShow = (type: ShowType) => {
    setShow(type);
  };
  useOnClickOutside(popRef, () => onClickShow(undefined));

  const role = roles?.find((role: AnyObject) => role.slug === tab);

  const { users } = useFetchUsersByRole(role?.id ?? "");

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    } else {
      setTab(roles?.[0]?.slug);
    }
  }, [error, isError, roles]);

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  return (
    <>
      <div
        tabIndex={1}
        className="pt-2 mb-8 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate("/administrators")}
      >
        <img src={back} alt="back" />
        Administrators
      </div>

      {loadingRoles ? (
        <div className="bg-white shadow-sm mx-24 flex border border-btnInactive gap-8 p-12 items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="bg-white shadow-sm mx-24 flex border border-btnInactive gap-8">
          <div className=" flex flex-col">
            {roles?.map((role: AnyObject) => {
              return (
                <div
                  key={role.id}
                  tabIndex={1}
                  onClick={() => setTab(role.slug)}
                  className={`p-7 text-dark text-center font-bold border-b border-line cursor-pointer ${
                    tab === role.slug ? "bg-offWhite" : ""
                  }`}
                >
                  {role.name}
                </div>
              );
            })}

            <div
              tabIndex={1}
              role="button"
              onClick={() => navigate("/create-role")}
              className="p-7 text-primary text-center font-medium border-b border-line cursor-pointer"
            >
              + New Role
            </div>
          </div>
          <div className="flex-1 px-8 py-6">
            <div className="mb-8">
              <p className="text-blackDark font-medium">
                {startCase(tab)}: {users?.length} Users
              </p>
              <div className="mt-4 flex gap-2 flex-wrap">
                {users?.length > 0 ? (
                  users.map(({ id, firstName, lastName }: AnyObject) => (
                    <span
                      key={id}
                      className="w-fit rounded-2xl bg-offWhite text-xs py-1 px-2 flex items-center gap-2"
                    >
                      {firstName} {lastName}
                    </span>
                  ))
                ) : (
                  <div className="mx-24 flex gap-8 p-12 items-center justify-center">
                    <p>No users for this role yet</p>
                  </div>
                )}
              </div>
            </div>

            <div className="relative">
              <div className="flex justify-between items-center">
                <p className="text-blackDark font-medium mb-4">Permissions</p>
                <img
                  src={menu}
                  alt="menu"
                  className="cursor-pointer"
                  onClick={() => onClickShow("menu")}
                />
              </div>
              {show === "menu" && (
                <div
                  ref={popRef}
                  className="absolute bg-white shadow-md border right-0 top-3 py-5 px-5 rounded flex flex-col gap-3"
                >
                  <span
                    className="text-sm font-normal text-blackDark cursor-pointer"
                    onClick={() => navigate(`/edit-role/${role.id}`)}
                  >
                    Edit{" "}
                  </span>
                </div>
              )}
              <div className="flex justify-start gap-36">
                <div>
                  <p className="text-primary text-sm font-bold">Users Can:</p>
                  <div className="mt-3">
                    {role?.permissions?.map((permission: string) => {
                      const [action, entity] = permission.split(":");
                      return (
                        <p
                          key={permission}
                          className="text-sm mb-4 text-blackDark"
                        >
                          {startCase(action)} {startCase(entity)}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ManageRole;
