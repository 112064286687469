import Document from "components/Document";
import Modal from "components/Modal";
import { ViewDocument } from "components/ViewDocument";
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { AnyObject, ContextType } from "utils/types";

export default function Details() {
  const { data } = useOutletContext<ContextType>();
  const [imageToShow, setImageToShow] = useState("");
  const [type, setType] = useState("");
  const showDocument = (image: string, type: string) => {
    setImageToShow(image);
    setType(type);
  };
  return (
    <>
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <p className="text-primary font-medium">Personal Information</p>
        <div className="grid grid-cols-userCard mt-8 gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">Account Name</p>
            <p className="text-blackDark text-sm font-medium">{data.name}</p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Phone Number</p>
            <p className="text-blackDark text-sm font-medium">
              {data?.dialCode}
              {data.phone}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Email</p>
            <p className="text-blackDark text-sm font-medium">{data.email}</p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">How old are you?</p>
            <p className="text-blackDark text-sm font-medium">{data.age}</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <p className="text-primary font-medium">Educational Background</p>

        <div className="grid grid-cols-userCard mt-8 gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">
              What are your highest levels of education?
            </p>
            <div className="flex flex-col">
              {data?.educations?.map((edu: AnyObject) => (
                <div className="flex justify-between mb-2" key={edu._id}>
                  <div>
                    <p className="text-coolGrey text-sm mb-3">Education</p>
                    <p className="text-blackDark text-sm font-medium">
                      {edu.level}
                    </p>
                  </div>

                  <div>
                    <p className="text-coolGrey text-sm mb-3">Year</p>
                    <p className="text-blackDark text-sm font-medium">
                      {edu.graduationYear}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Was your most recent educational diploma, degree, or certificate
              received at a school in a foreign country?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.countryOfLastDegree !== "" ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">What Country?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.countryOfLastDegree || "N/A"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Have you taken an English language test in the past two years?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.hasTakenEnglishTest}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Please share your results
            </p>
            {data.hasTakenEnglishTest.toLowerCase() === "yes" ? (
              <p className="text-blackDark text-sm font-medium">
                Speaking - {data?.englishTestScore?.speaking}, Writing -{" "}
                {data?.englishTestScore?.writing}, Listening -{" "}
                {data?.englishTestScore?.listening}, Reading -{" "}
                {data?.englishTestScore?.reading}
              </p>
            ) : (
              <p className="text-blackDark text-sm font-medium">N/A</p>
            )}
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you understand any other foreign language?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.understandForeignLanguage ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">What language?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.foreignLanguage || "N/A"}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <p className="text-primary font-medium">Work Experience</p>
        <div className="grid grid-cols-userCard mt-8 gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you have any work experience?
            </p>
            <p className="text-blackDark text-sm font-medium">{data.yearsOfExperience !== "" ? "Yes" : "No"}</p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">What Occupation?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.occupation}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              How long did you work in that role?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.yearsOfExperience}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you have any work experience outside your country of birth?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.foreignCountryOccupation !== "" ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">What Occupation?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.foreignCountryOccupation}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              How long did you work in that role?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.foreignCountryYearsOfExperience}
            </p>
          </div>

          <div>
            <ViewDocument
              file={data.resume}
              name="CV/Resume"
              showDocument={() => showDocument(data.resume, "Resume")}
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <p className="text-primary font-medium">Migration Goal</p>
        <div className="grid grid-cols-userCard mt-8 gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">
              Why do you want to migrate?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.relocationGoal}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Where would you like to migrate to?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.proposedCountryOfRelocation}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              What is your current country of Citizenship?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.countryOfCitizenship}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you currently live in a different country?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.countryOfCitizenship === data.countryOfResidence
                ? "No"
                : "Yes"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">Where do you live?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.countryOfResidence}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <p className="text-primary font-medium">Family Status</p>
        <div className="grid grid-cols-userCard mt-8 gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">Do you have a spouse?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.hasSpouse ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Does your spouse have a foreign immigration status?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseHasForeignImmigrationStatus ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              In what country does your spouse have a foreign immigration
              status?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseForeignImmigrationStatusCountry}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">Which one applies?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseForeignImmigrationStatus}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Will your spouse or common-law partner come with you?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.isAccompaniedBySpouse ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              How many children/dependant do you have?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.noOfKids ?? "N/A"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              What educational qualifications does your spouse have?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseQualifications.toString()}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Was your spouse's educational diploma, degree, or certificate
              received at a school in a foreign country?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseHasForeignEducation ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">What country?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseForeignEducationCountry}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              What's your spouse's occupation?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseOccupation}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Does your spouse speak any foreign language?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseSpeakForeignLanguage ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">What Language?</p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseForeignLanguage}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Has your spouse taken an English language test in the past two
              years?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseHasEnglishTest ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Please share your results
            </p>
            {data.spouseHasEnglishTest ? (
              <p className="text-blackDark text-sm font-medium">
                Speaking - {data?.spouseEnglistTestScore?.speaking}, Writing -{" "}
                {data?.spouseEnglistTestScore?.writing}, Listening -{" "}
                {data?.spouseEnglistTestScore?.listening}, Reading -{" "}
                {data?.spouseEnglistTestScore?.reading}
              </p>
            ) : (
              <p className="text-blackDark text-sm font-medium">N/A</p>
            )}
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you or your spouse have a business where you have over 10%
              voting right? Do you own at least 10% voting rights of a new
              startup company that brings innovative technology-based products
              and/or services to market?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseHasVotingRightInABusiness ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              What's the age of your spouse?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.spouseAge}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <p className="text-primary font-medium">Financial Preparedness</p>
        <div className="grid grid-cols-userCard mt-8 gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">
              How much do you currently have to commence your migration journey?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.cashAtHand}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              How much money would you have for your migration journey within 12
              months?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.futureCash}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you have an asset that would be sold or used as collateral
              (Land, car e.t.c)?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.hasAsset ? "Yes" : "No"}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">
              what is the value of that asset?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.assetValue}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm">
        <p className="text-primary font-medium">
          Document and Legal Preparedness
        </p>
        <div className="grid grid-cols-userCard mt-8 gap-x-24 gap-y-8">
          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you have a valid passport with at least 6 months of validity
              remaining?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.hasValidPassport ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you possess outstanding ability in the arts, sciences,
              athletics, or business?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.hasOutstandingAbility ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you have any medical conditions that require ongoing treatment
              or could impact your migration?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.hasMedicalConditions ? "Yes" : "No"}
            </p>
          </div>
          <div>
            <p className="text-coolGrey text-sm mb-3">
              Have you ever committed, been arrested for, been charged with or
              convicted of any criminal offence in any country or territory?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.hasCriminalConditions ? "Yes" : "No"}
            </p>
          </div>

          <div>
            <p className="text-coolGrey text-sm mb-3">
              Do you have friends or family in the destination country who can
              support you initially?
            </p>
            <p className="text-blackDark text-sm font-medium">
              {data.hasSupport ? "Yes" : "No"}
            </p>
          </div>
        </div>
      </div>

      <Modal isShown={type === "resume"}>
        <Document
          onClose={() => {
            setImageToShow("");
            setType("");
          }}
          image={imageToShow}
          type={type}
        />
      </Modal>
    </>
  );
}
