import React, { ChangeEvent } from "react";

export default function Switch({checked, onChange}: {checked: boolean; onChange: (e: ChangeEvent<HTMLInputElement>) => void;}) {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input type="checkbox" checked={checked} onChange={onChange} className="sr-only peer" />
      <div 
        className="w-8 
                    h-4
                    bg-grey
                    peer-focus:outline-none 
                    peer-focus:ring-1
                    peer-focus:ring-white 
                    dark:peer-focus:ring-primary 
                    rounded-full 
                    peer 
                    dark:bg-primary 
                    peer-checked:after:translate-x-full 
                    rtl:peer-checked:after:-translate-x-full 
                    peer-checked:after:border-white 
                    after:content-[''] 
                    after:absolute 
                    after:top-[0px] 
                    after:start-[2px] 
                    after:bg-white 
                    after:border-grey 
                    after:border 
                    after:rounded-full 
                    after:h-4
                    after:w-[.9rem]
                    after:transition-all 
                    dark:border-primary 
                    peer-checked:bg-btnInactive" />
      {/* <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
        Toggle me
      </span> */}
    </label>
  );
}
