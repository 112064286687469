import SideBar from "components/SideBar";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const isAuthenticated = !!user?.tokens?.access?.token;
  return (
    <div className="flex">
      <SideBar />
      <div className="flex-1 ml-52 py-12 px-8">
        {isAuthenticated ? <Outlet /> : <Navigate to="/login" />}
      </div>
    </div>
  );
}

export default PrivateRoute;
