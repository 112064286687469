import  { useEffect, useRef } from 'react';
import { Bar, Doughnut, Radar } from 'react-chartjs-2';
import { AnyObject } from 'utils/types';
import { motion } from 'framer-motion';
import IpLocationMap from './IpLocationMap';
import { useNavigate } from 'react-router-dom';
import { ChartEvent, TooltipItem, Chart as ChartJS } from 'chart.js';

type Props = {
  data: AnyObject[];
};

type ChartType = 'bar' | 'doughnut' | 'radar';

// interface ChartContext {
//   formattedValue: string;
//   raw: number;
//   label: string;
//   dataset: {
//     data: number[];
//   };
// }

export default function MigrationInsights({ data }: Props) {
  const navigate = useNavigate();
  const chartRefs = useRef<{ [key: string]: ChartJS | null }>({});

  // Cleanup charts when component unmounts
  useEffect(() => {
    const currentChartRefs = chartRefs.current;
    return () => {
      // Destroy all charts on unmount
      Object.values(currentChartRefs).forEach(chart => {
        if (chart) {
          chart.destroy();
        }
      });
    };
  }, []);

  // When creating new charts, store the references
  // const setChartRef = (id: string, chart: ChartJS | null) => {
  //   if (chartRefs.current[id]) {
  //     chartRefs.current[id]?.destroy();
  //   }
  //   chartRefs.current[id] = chart;
  // };

  // Status Distribution
  const statusCounts = data.reduce((acc: { [key: string]: number }, item) => {
    const status = item.status || 'Not Specified';
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  // Top Earners (based on financial readiness score)
  const calculateTotalScore = (item: AnyObject): number => {
    // Q1: Cash at hand (30%), normalized by dividing by 10,000,000
    const cashAtHandScore = ((parseFloat(item.cashAtHand?.replace(/[^0-9.]/g, '') || '0') / 10000000) * 0.3);

    // Q2: Future cash (20%), normalized by dividing by 10,000,000
    const futureCashScore = ((parseFloat(item.futureCash?.replace(/[^0-9.]/g, '') || '0') / 10000000) * 0.2);

    // Q3: Has assets (20%), boolean value
    const hasAssetScore = (item.hasAsset ? 1 : 0) * 0.2;

    // Q4: Asset value (30%), normalized by dividing by 10,000,000
    const assetValueScore = ((parseFloat(item.assetValue?.replace(/[^0-9.]/g, '') || '0') / 10000000) * 0.3);

    return cashAtHandScore + futureCashScore + hasAssetScore + assetValueScore;
  };

  // Process top earners data
  const topEarnersData = Object.entries(
    data.reduce((acc: { [key: string]: number }, item: AnyObject) => {
      const name = item.name || 'Unknown';
      const score = calculateTotalScore(item);
      return {
        ...acc,
        [name]: score
      };
    }, {})
  ).sort(([, a], [, b]) => (b as number) - (a as number))
   .slice(0, 10);

  const topEarnersLabels = topEarnersData.map(([name]) => name);
  const topEarnersScores = topEarnersData.map(([, score]) => Number((score as number).toFixed(2)));

  // Financial Capacity Analysis
  const financialData = data.reduce((acc: { [key: string]: number }, item) => {
    const cashAtHand = item.cashAtHand?.includes('More than') ? 5000000 : 
                     parseInt(item.cashAtHand?.replace(/[^0-9]/g, '')) || 0;
    const futureCash = item.futureCash?.includes('More than') ? 5000000 :
                     parseInt(item.futureCash?.replace(/[^0-9]/g, '')) || 0;
    const assetValue = parseInt(item.assetValue?.replace(/[^0-9]/g, '')) || 0;
    
    acc.cashAtHand = (acc.cashAtHand || 0) + cashAtHand;
    acc.futureCash = (acc.futureCash || 0) + futureCash;
    acc.assetValue = (acc.assetValue || 0) + assetValue;
    return acc;
  }, {});

  // Age Distribution
  const ageGroups = data.reduce((acc: { [key: string]: number }, item) => {
    const age = parseInt(item.age);
    if (isNaN(age)) return acc;
    
    const group = 
      age < 25 ? '18-24' :
      age < 35 ? '25-34' :
      age < 45 ? '35-44' :
      age < 55 ? '45-54' :
      '55+';
    
    acc[group] = (acc[group] || 0) + 1;
    return acc;
  }, {});

  // Education Level Distribution
  const educationLevels = data.reduce((acc: { [key: string]: number }, item) => {
    const level = item.educations?.[0]?.level || 'Not Specified';
    acc[level] = (acc[level] || 0) + 1;
    return acc;
  }, {});

  // English Proficiency Analysis
  const avgEnglishScores = data.reduce((acc: { [key: string]: number }, item) => {
    if (item.englishTestScore) {
      acc.speaking = (acc.speaking || 0) + (parseInt(item.englishTestScore.speaking) || 0);
      acc.reading = (acc.reading || 0) + (parseInt(item.englishTestScore.reading) || 0);
      acc.listening = (acc.listening || 0) + (parseInt(item.englishTestScore.listening) || 0);
      acc.writing = (acc.writing || 0) + (parseInt(item.englishTestScore.writing) || 0);
      acc.count = (acc.count || 0) + 1;
    }
    return acc;
  }, { count: 0 });

  const englishScores = {
    speaking: avgEnglishScores.speaking / avgEnglishScores.count || 0,
    reading: avgEnglishScores.reading / avgEnglishScores.count || 0,
    listening: avgEnglishScores.listening / avgEnglishScores.count || 0,
    writing: avgEnglishScores.writing / avgEnglishScores.count || 0
  };

  return (
    <>
      {/* Status Distribution */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="bg-white/95 backdrop-blur-sm p-6 rounded-lg shadow-sm border border-btnInactive"
      >
        <h3 className="text-lg font-medium text-coolGrey mb-4">Status Distribution</h3>
        <div className="h-[300px] flex items-center justify-center">
          <Doughnut
            data={{
              labels: Object.keys(statusCounts),
              datasets: [{
                data: Object.values(statusCounts),
                backgroundColor: [
                  '#3B82F6',
                  '#10B981',
                  '#F59E0B',
                  '#EF4444',
                  '#8B5CF6',
                  '#EC4899',
                  '#6366F1'
                ],
                borderWidth: 0,
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'right',
                  labels: {
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle'
                  }
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem: TooltipItem<ChartType>) => {
                      const value = tooltipItem.raw as number;
                      const total = Object.values(statusCounts).reduce((a, b) => a + b, 0);
                      const percentage = Math.round((value * 100) / total);
                      return `${tooltipItem.label}: ${value} (${percentage}%)`;
                    }
                  }
                }
              }
            }}
          />
        </div>
      </motion.div>

      {/* Top Earners */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="bg-white/95 backdrop-blur-sm p-6 rounded-lg shadow-sm border border-btnInactive"
      >
        <h3 className="text-lg font-medium text-coolGrey mb-4">Top Financial Readiness</h3>
        <div className="h-[300px] flex items-center justify-center">
          <Bar
            data={{
              labels: topEarnersLabels,
              datasets: [{
                label: 'Financial Readiness Score',
                data: topEarnersScores,
                backgroundColor: '#3B82F6',
                borderRadius: 8,
              }]
            }}
            options={{
              indexAxis: 'y',
              responsive: true,
              maintainAspectRatio: false,
              onClick: (event: ChartEvent, elements: any[]) => {
                if (elements.length > 0) {
                  const index = elements[0].index;
                  const name = topEarnersLabels[index];
                  const selectedMigration = data.find((item: AnyObject) => item.name === name);
                  if (selectedMigration) {
                    navigate(`/migration-assessment/${selectedMigration.id}`);
                  }
                }
              },
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  callbacks: {
                    label: (tooltipItem: TooltipItem<ChartType>) => {
                      return `Score: ${tooltipItem.formattedValue}`;
                    }
                  }
                }
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  },
                  ticks: {
                    precision: 1
                  },
                  title: {
                    display: true,
                    text: 'Financial Readiness Score'
                  }
                },
                y: {
                  grid: {
                    display: false
                  }
                }
              }
            }}
          />
        </div>
      </motion.div>

      {/* Financial Capacity */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="bg-white/95 backdrop-blur-sm p-6 rounded-lg shadow-sm border border-btnInactive"
      >
        <h3 className="text-lg font-medium text-coolGrey mb-4">Financial Capacity Distribution</h3>
        <div className="h-[300px] flex items-center justify-center">
          <Bar
            data={{
              labels: ['Cash at Hand', 'Future Cash', 'Asset Value'],
              datasets: [{
                label: 'Amount (NGN)',
                data: [
                  financialData.cashAtHand,
                  financialData.futureCash,
                  financialData.assetValue
                ],
                backgroundColor: [
                  '#3B82F6',
                  '#10B981',
                  '#F59E0B'
                ],
                borderRadius: 8,
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const value = context.raw as number;
                      return `NGN ${value.toLocaleString()}`;
                    }
                  }
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    callback: (value) => `NGN ${(value as number).toLocaleString()}`
                  }
                }
              }
            }}
          />
        </div>
      </motion.div>

      {/* Age Distribution */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.8 }}
        className="bg-white/95 backdrop-blur-sm p-6 rounded-lg shadow-sm border border-btnInactive"
      >
        <h3 className="text-lg font-medium text-coolGrey mb-4">Age Distribution</h3>
        <div className="h-[300px] flex items-center justify-center">
          <Doughnut
            data={{
              labels: Object.keys(ageGroups),
              datasets: [{
                data: Object.values(ageGroups),
                backgroundColor: [
                  '#3B82F6',
                  '#10B981',
                  '#F59E0B',
                  '#EF4444',
                  '#8B5CF6'
                ],
                borderWidth: 0,
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'right',
                  labels: {
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle'
                  }
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      const value = context.raw as number;
                      const total = Object.values(ageGroups).reduce((a, b) => a + b, 0);
                      const percentage = Math.round((value * 100) / total);
                      return `${context.label}: ${value} (${percentage}%)`;
                    }
                  }
                }
              }
            }}
          />
        </div>
      </motion.div>

      {/* English Proficiency */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1.0 }}
        className="bg-white/95 backdrop-blur-sm p-6 rounded-lg shadow-sm border border-btnInactive"
      >
        <h3 className="text-lg font-medium text-coolGrey mb-4">Average English Proficiency</h3>
        <div className="h-[300px] flex items-center justify-center">
          <Radar
            data={{
              labels: ['Speaking', 'Reading', 'Listening', 'Writing'],
              datasets: [{
                label: 'Average Score',
                data: [
                  englishScores.speaking,
                  englishScores.reading,
                  englishScores.listening,
                  englishScores.writing
                ],
                backgroundColor: 'rgba(59, 130, 246, 0.2)',
                borderColor: '#3B82F6',
                pointBackgroundColor: '#3B82F6',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#3B82F6'
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                r: {
                  angleLines: {
                    display: true
                  },
                  suggestedMin: 0,
                  suggestedMax: 9,
                  ticks: {
                    stepSize: 1,
                    callback: (value) => value.toString()
                  }
                }
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'top'
                },
                tooltip: {
                  callbacks: {
                    label: (context) => {
                      return `Score: ${context.formattedValue}`;
                    }
                  }
                }
              }
            }}
          />
        </div>
      </motion.div>

      {/* Education Levels */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1.2 }}
        className="bg-white/95 backdrop-blur-sm p-6 rounded-lg shadow-sm border border-btnInactive"
      >
        <h3 className="text-lg font-medium text-coolGrey mb-4">Education Level Distribution</h3>
        <div className="h-[300px] flex items-center justify-center">
          <Bar
            data={{
              labels: Object.keys(educationLevels),
              datasets: [{
                label: 'Number of Applicants',
                data: Object.values(educationLevels),
                backgroundColor: '#3B82F6',
                borderRadius: 8,
              }]
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                }
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    precision: 0
                  }
                }
              }
            }}
          />
        </div>
      </motion.div>

      {/* IP Location Map */}
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1.4 }}
        className="col-span-2 bg-white/95 backdrop-blur-sm p-6 rounded-lg shadow-sm border border-btnInactive"
      >
        <h3 className="text-lg font-medium text-coolGrey mb-4">Geographic Distribution</h3>
        <IpLocationMap data={data} />
      </motion.div>
    </>
  );
} 