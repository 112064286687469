import close from "assets/close.svg";

interface ViewProps {
  onClose: () => void;
  image: string;
  type: string;
}

const Document = ({ onClose, image, type }: ViewProps) => {
  return (
    <div className="bg-white w-[450px] m-auto mt-48 rounded">
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">{type}</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>

      <div className="flex items-center justify-center">
        <img
          src={image}
          alt={type}
          loading="lazy"
          className="rounded-lg object-cover w-[400px] h-[400px] pb-6"
        />
      </div>
    </div>
  );
};

export default Document;
