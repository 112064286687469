import close from "assets/close.svg";
import Button from "components/Button";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useAdminHandlers, useFetchRoles } from "../useAdminHandlers";
import { AnyObject } from "utils/types";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

interface ChangeRoleProps {
  onClose: () => void;
  data: AnyObject;
}

export interface ChangeValues {
  email: string;
  role: string;
  id: string;

}
const ChangeRole = ({ onClose, data }: ChangeRoleProps) => {
  const { handleChangeRole, loading, isError, error } =
    useAdminHandlers(onClose);

    const { roles } = useFetchRoles();

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<ChangeValues>({
      initialValues: {
        email: data.email,
        role: data.role,
        id: data.id
      },
      validationSchema: object({
        email: string()
          .email("Please enter a valid email")
          .required("Email is required"),
       
        role: string()
          .oneOf(
            roles?.map((role: AnyObject) => role?.name) ?? [],
            `Role must be one of ${roles?.map((role: AnyObject) => role?.name)?.toString()}`
          )
          .required("Role is required"),
      }),
      onSubmit: (values) => {
        handleChangeRole(values);
      },
    });

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-40 rounded"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Change Role</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>

      <div className="p-4 border-b  border-btnInactive">
        <Input
          type="email"
          label="Email"
          id="email"
          name="email"
          disabled
          value={values.email}
          onChange={handleChange}
          placeholder={"Enter email"}
          error={`${!!errors["email"] && !!touched["email"]}`}
          errorMessage={errors["email"]}
          onBlur={handleBlur}
        />

        <Select
          label="Role"
          id="role"
          name="role"
          value={values.role}
          onChange={handleChange}
          placeholder={"Select role"}
          error={`${!!errors["role"] && !!touched["role"]}`}
          errorMessage={errors["role"]}
          data={roles?.map((role: AnyObject) => ({label: role.name, value: role.name})) ?? []}
          onBlur={handleBlur}
        />
      </div>

      <div className="p-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Change" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default ChangeRole;
