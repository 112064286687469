export default function Metrics() {
  return (
    <div>
      <iframe
        src="https://metabase.blumefy.com/public/dashboard/9adaeabc-ddce-4320-845d-01cea3b1805d"
        frameBorder="0"
        width="100%" 
        height="800"
        allowFullScreen
        title="Metrics"
      ></iframe>
    </div>
  );
  }
  