import { useEffect, useState } from "react";

const Timer = ({
  initialMinute = 0,
  initialSeconds = 0,
}: {
  initialMinute: number;
  initialSeconds: number;
}) => {
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : (
        <p className="text-primary text-sm my-4">
          This code will expire in {minutes} mins{" "}
          {seconds < 10 ? `0${seconds}` : seconds} secs
        </p>
      )}
    </div>
  );
};

export default Timer;
