import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import { api } from "utils/api";
import { FormValues } from "./modals/Invite";
import { ChangeValues } from "./modals/ChangeRole";
import { useNavigate } from "react-router-dom";
import { CreateRoleForm } from "./CreateRole";

// Admins
const getAdmins = async (page: number, filter?: string) => {
  const res = await api.get(
    filter
      ? `users/v1?userType=admin&limit=30&page=${page}&searchTerm=${filter}`
      : `users/v1?userType=admin&limit=30&page=${page}`
  );
  return res.data.data;
};
export const useFetchAdmins = (page: number, filter?: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["admins", page, filter],
    () => getAdmins(page, filter),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    data,
    loadingAdmins: isLoading,
    isError,
    error
  };
};

// Roles
const getRoles = async () => {
  const res = await api.get(
     `users/v1/role/get-roles`
  );
  return res.data.data.roles;
};
export const useFetchRoles = () => {
  const { data, isLoading, isError, error } = useQuery(
    ["roles"],
    () => getRoles(),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    roles: data,
    loadingRoles: isLoading,
    isError,
    error
  };
};

// Single Role
const getRole = async (roleId: string) => {
  const res = await api.get(
     `users/v1/role/${roleId}`
  );
  return res.data.data.role;
};
export const useFetchRole = (roleId: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["role", roleId],
    () => getRole(roleId),
    { retry: false, refetchOnWindowFocus: false }
  );
  return {
    role: data,
    loadingRole: isLoading,
    isError,
    error
  };
};

// UsersByRole
const getUsersByRole = async (roleId: string) => {
  const res = await api.get(
     `users/v1/role/${roleId}/users?limit=100`
  );
  return res.data.data.results;
};
export const useFetchUsersByRole = (roleId: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["usersByRole", roleId],
    () => getUsersByRole(roleId),
    { retry: false, refetchOnWindowFocus: false, enabled: !!roleId }
  );
  return {
    users: data,
    loadingUsers: isLoading,
    isError,
    error
  };
};

// Permissions
const getPermissions = async () => {
  const res = await api.get(
     `users/v1/role/permissions`
  );
  return res.data.data.permissions;
};
export const useFetchPermissions = () => {
  const { data, isLoading, isError, error } = useQuery(
    ["permissions"],
    () => getPermissions(),
    { keepPreviousData: true, retry: false, refetchOnWindowFocus: false }
  );
  return {
    permissions: data,
    loadingPermissions: isLoading,
    isError,
    error
  };
};

// Action handlers
export const useAdminHandlers = (onClose?: () => void) => {

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    (data: FormValues) => {
      return api.post("users/v1", data);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Admin successfully invited");
        queryClient.invalidateQueries(['admins', 1]);

      },
    }
  );

  const {
    mutate: mutateChange,
    isLoading: isLoadingChange,
    isError: isErrorChange,
    error: errorChange,
  } = useMutation(
    ({id, role}: ChangeValues) => {
      return api.patch(`users/v1/${id}`, {role});
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["admins", 1]);
        queryClient.invalidateQueries(["usersByRole", variables.id]);
        toast.success("Admin role changed");
      },
    }
  );

  const {
    mutate: mutateCreateRole,
    isLoading: isLoadingCreateRole,
    isError: isErrorCreateRole,
    error: errorCreateRole,
  } = useMutation(
    (data: CreateRoleForm) => {
      return api.post(`users/v1/role`, data);
    },
    {
      onSuccess: async (res) => {
        navigate("/manage-role");
        toast.success("System role created successfully");
      },
    }
  );

  const {
    mutate: mutateUpdateRole,
    isLoading: isLoadingUpdateRole,
    isError: isErrorUpdateRole,
    error: errorUpdateRole,
  } = useMutation(
    ({id, ...rest}: CreateRoleForm & {id: string}) => {
      return api.put(`users/v1/role/${id}`, rest);
    },
    {
      onSuccess: async (res) => {
        navigate("/manage-role");
        toast.success("System role updated");
      },
    }
  );



  const handleInviteAdmin = (data: FormValues) => {
    mutate(data as FormValues);
  };

  const handleChangeRole = (data: ChangeValues) => {
    mutateChange(data as ChangeValues);
  };

  const handleCreateRole = (data: CreateRoleForm) => {
    mutateCreateRole(data);
  };

  const handleUpdateRole = (data: CreateRoleForm & {id: string}) => {
    mutateUpdateRole(data);
  };

  return {
    handleInviteAdmin,
    handleChangeRole,
    handleCreateRole,
    handleUpdateRole,
    loading: isLoading || isLoadingChange || isLoadingCreateRole || isLoadingUpdateRole,
    isError: isError || isErrorChange || isErrorCreateRole || isErrorUpdateRole,
    error: error || errorChange || errorCreateRole || errorUpdateRole,
  };
};
