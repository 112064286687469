import React from 'react';

interface SortButtonProps {
  field: string;
  currentSort: { field: string; order: 'asc' | 'desc' };
  onSort: (field: string) => void;
}

const SortButton: React.FC<SortButtonProps> = ({ field, currentSort, onSort }) => {
  const isActive = currentSort.field === field;
  
  return (
    <button
      onClick={() => onSort(field)}
      className="ml-1 p-1 focus:outline-none"
    >
      <span className="inline-block">
        {isActive ? (
          currentSort.order === 'asc' ? '↑' : '↓'
        ) : (
          <span className="text-gray-300">↕</span>
        )}
      </span>
    </button>
  );
};

export default SortButton;