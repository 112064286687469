import Button from "components/Button";
import { useDocumentTitle } from "hooks/useDocument";
import { Fragment } from "react";
import success from "assets/success.svg";
import { useLocation, useNavigate } from "react-router-dom";

function LoginSuccess() {
  useDocumentTitle("Login success");
  const navigate = useNavigate();

  const { state } = useLocation();

  return (
    <Fragment>
      <img src={success} alt="success" className="mx-auto my-4" />
      <p className="text-blackDarkDark text-xl font-bold text-center mb-8">
        Successful
      </p>
      <p className="text-lightText text-center text-sm">
        Login successful. To get full control of this account, change your admin
        password
      </p>

      <Button
        label="Change Password"
        onClick={() => navigate("/change-password", { state: { ...state } })}
      />
    </Fragment>
  );
}

export default LoginSuccess;
