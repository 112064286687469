import Button from "components/Button";
import Timer from "components/Timer";
import OTPInput from "components/inputs/OTPInput";
import { useDocumentTitle } from "hooks/useDocument";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuthHandlers } from "./useAuthHandlers";
import { toast } from "react-hot-toast";
import { AnyObject } from "utils/types";
import { api } from "utils/api";

export interface VerifyValues {
  code: string;
  token: string;
}

function VerifyOTP() {
  useDocumentTitle("Verify OTP");
  const { state } = useLocation();
  const { handleVerify, isError, error, loading } = useAuthHandlers();

  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);
  const onResend = async () => {
    try {
      const res = await api.post("users/v1/auth/resend-forgot-password-otp", {email: state.email});
      if (res) {
        toast.success(`Otp resent to ${state.email}`);
      }
    } catch (error) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  };

  const onHandleVerify = async () => {
    await handleVerify({ token: state.token, code: otp.join("") });
  };

  return (
    <Fragment>
      <p className="text-blackDarkDark text-xl font-bold text-center mb-8">
        Forgot your password
      </p>

      <p className="text-[13px] text-center mb-2 text-blackDarkDark">
        Enter the 6 digit code sent to <b>{state.email}</b>
      </p>
      <OTPInput otp={otp} setOtp={setOtp} error={false} />
      <Button
        loading={loading}
        onClick={onHandleVerify}
        label="Submit"
        disabled={otp.join("").length !== 6}
      />
      <Timer initialMinute={5} initialSeconds={0} />
      <p className="text-blackDark text-sm">
        Didn’t receive code?{" "}
        <span onClick={onResend} className="text-primary cursor-pointer">
          Resend Code
        </span>
      </p>
    </Fragment>
  );
}

export default VerifyOTP;
