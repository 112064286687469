import { useDebounce } from './useDebounce';
import { useFetchMigrations } from '../pages/authenticated/migration/useMigrationHandlers';

interface Migration {
  id: string;
  name: string;
  [key: string]: any;
}

interface Suggestion {
  value: string;
  label: string;
  data: Migration;
}

export function useMigrationSearch(searchTerm: string) {
  const debouncedSearch = useDebounce(searchTerm, 300);
  
  const { data, isLoading } = useFetchMigrations({
    page: 1,
    pageSize: 10,
    searchTerm: debouncedSearch,
    sortBy: 'createdAt',
    status: '',
    query: ''
  });

  const suggestions: Suggestion[] = data?.results?.map((migration: Migration) => ({
    value: migration.id,
    label: migration.name,
    data: migration
  })) || [];

  return {
    suggestions,
    isLoading
  };
} 