import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";
import { AxiosError } from 'axios';

type AssignedToType = {
  userName: string;
  userId: string;
  email: string;
};

type ConsultantData = {
  consultant: { userName: string; email: string };
  leadId: string;
};

type RecommendationData = {
  recommendations: Array<{ country: string; pathway: string }>;
  leadId: string;
};

// Get all Migrations
const getMigrations = async (
  page: number,
  searchTerm?: string,
  status?: string,
  query?: string,
  pageSize: number = 10,
  sortBy: string = "-createdAt"
) => {
  const queryParams = buildQuery({
    page,
    searchTerm,
    status,
    limit: pageSize,
    sortBy,
  });
  const res = await api.get(`migration/v1/leads/all?${queryParams}${query ? `&${query}` : ""}`);
  return res.data.data;
};

export const useFetchMigrations = ({
  page,
  searchTerm,
  status,
  query,
  pageSize,
  sortBy = "-createdAt"
}: {
  page: number,
  searchTerm?: string,
  status?: string,
  query?: string,
  pageSize: number,
  sortBy: string
}) => {
  const { data, isLoading, isError, error } = useQuery(
    ["migrations", page, searchTerm, status, query, pageSize, sortBy],
    () => getMigrations(page, searchTerm, status, query, pageSize, sortBy),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    data,
    isLoading,
    error: isError ? error : null,
  };
};

const getMigration = async (id: string) => {
  const res = await api.get(`migration/v1/leads/${id}`);
  return res.data.data;
};

export const useFetchMigration = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["migration", id],
    () => getMigration(id),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  return {
    data,
    isLoading,
    error: isError ? error : null,
  };
};

const getComments = async (id: string) => {
  const res = await api.get(`migration/v1/leads/${id}/comment`);
  return res.data.data;
};

export const useFetchComments = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["comments", id],
    () => getComments(id),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id,
    }
  );

  return {
    data,
    isLoading,
    error: isError ? error : null,
  };
};

// Add these new queries to fetch status-specific data
export const useStatusMigrations = (
  status: string, 
  enabled: boolean = true,
  queryString: string = ''
) => {
  return useQuery(['migrations', 'status', status, queryString], async () => {
    const url = `migration/v1/leads/all?status=${status}${queryString ? `&${queryString.replace('assignedTo=', 'assignLead=')}` : ''}`;
    
    try {
      const response = await api.get(url);
      return response.data.data;
    } catch (error) {
      // Type guard for AxiosError
      if (error instanceof Error && (error as AxiosError).response?.status === 429) {
        // Add a delay before retrying
        await new Promise(resolve => setTimeout(resolve, 1000));
        // Retry the request
        const retryResponse = await api.get(url);
        return retryResponse.data.data;
      }
      throw new Error('Failed to fetch migrations');
    }
  }, {
    staleTime: 30000, // Consider data fresh for 30 seconds
    cacheTime: 5 * 60 * 1000, // Cache for 5 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: enabled,
    retry: 3, // Retry failed requests 3 times
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000), // Exponential backoff
  });
};

// Action handlers
export const useMigrationActions = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    ({
      leadId,
      ...rest
    }: {
      assignType: string;
      assignedTo: AssignedToType;
      leadId: string;
    }) => {
      return api.post(`migration/v1/leads/${leadId}/assign`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Migration lead assigned successfully");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateStatus,
    isLoading: isLoadingStatus,
    isError: isErrorStatus,
    error: errorStatus,
  } = useMutation(
    ({ status, leadId }: { status: string; leadId: string }) => {
      return api.patch(`migration/v1/leads/${leadId}`, { status });
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Migration lead status successfully updated");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateConsultant,
    isLoading: isLoadingConsultant,
    isError: isErrorConsultant,
    error: errorConsultant,
  } = useMutation(
    ({ leadId, ...rest }: ConsultantData) => {
      return api.post(`migration/v1/leads/${leadId}/set-consultant`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Consultant successfully set");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateRecommendation,
    isLoading: isLoadingRecommendation,
    isError: isErrorRecommendation,
    error: errorRecommendation,
  } = useMutation(
    ({ leadId, ...rest }: RecommendationData) => {
      return api.post(`migration/v1/leads/${leadId}/addRecommendations`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Recommendations added successfully");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateComment,
    isLoading: isLoadingComment,
    isError: isErrorComment,
    error: errorComment,
    isSuccess,
  } = useMutation(
    ({ leadId, ...message }: { leadId: string; message: string }) => {
      return api.post(`migration/v1/leads/${leadId}/comment`, message);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Comment added successfully");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const {
    mutate: mutateTag,
    isLoading: isLoadingTag,
    isError: isErrorTag,
    error: errorTag,
  } = useMutation(
    ({ leadId, ...rest }: { leadId: string; tags: Array<string> }) => {
      return api.post(`migration/v1/leads/${leadId}/add-tag`, rest);
    },
    {
      onSuccess: async (res) => {
        onClose?.();
        toast.success("Tags added successfully");
        queryClient.invalidateQueries();
      },
      onError: async (error: Error) => {
        toast.error(error.message);
      },
    }
  );

  const handleAssign = (data: {
    assignType: string;
    assignedTo: AssignedToType;
    leadId: string;
  }) => {
    mutate(data);
  };

  const handleStatus = (data: { status: string; leadId: string }) => {
    mutateStatus(data);
  };

  const handleConsultant = (data: ConsultantData) => {
    mutateConsultant(data);
  };

  const handleRecommendation = (data: RecommendationData) => {
    mutateRecommendation(data);
  };

  const handleComment = (data: { message: string; leadId: string }) => {
    mutateComment(data);
  };

  const handleTag = (data: { tags: Array<string>; leadId: string }) => {
    mutateTag(data);
  };

  return {
    handleAssign,
    handleStatus,
    handleConsultant,
    handleRecommendation,
    handleComment,
    handleTag,
    loading:
      isLoading ||
      isLoadingStatus ||
      isLoadingConsultant ||
      isLoadingRecommendation ||
      isLoadingComment ||
      isLoadingTag,
    isError:
      isError ||
      isErrorStatus ||
      isErrorConsultant ||
      isErrorRecommendation ||
      isErrorComment ||
      isErrorTag,
    error:
      error ||
      errorStatus ||
      errorConsultant ||
      errorRecommendation ||
      errorComment ||
      errorTag,
    isSuccess,
  };
};

// Example of a new hook to fetch metrics data
export const useFetchMetrics = () => {
  return useQuery(['metrics'], async () => {
    const response = await fetch('/api/metrics'); // Adjust the endpoint as necessary
    if (!response.ok) {
      throw new Error('Error fetching metrics');
    }
    return response.json();
  });
};

export const useFetchCallLogs = (id: string) => {
  return useQuery({
    queryKey: ["callLogs", id],
    queryFn: async () => {
      const res = await api.get(`migration/v1/leads/${id}/call-logs`);
      return res.data.data;
    },
    enabled: !!id,
  });
};
