import { Outlet } from "react-router-dom";
import logo from "assets/logo.svg";

function Root() {
  return (
    <div className="max-w-md mx-auto">
      <div className="flex items-center justify-center p-12 mt-10 -mb-4">
        <img src={logo} alt="blumefy logo" className="w-72 h-20" />
      </div>
      <div className="bg-white py-8 px-10 rounded-[10px] relative">
        <Outlet />
      </div>
    </div>
  );
}

export default Root;
