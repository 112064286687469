import Button from "components/Button";
import { useCardHandlers } from "../useCardHandlers";
import { AnyObject } from "utils/types";
import { useEffect } from "react";
import toast from "react-hot-toast";

interface FreezeProps {
  onClose: () => void;
  cardId: string;
  status: string;
  userId: string;
  queryId: string;
}

const Freeze = ({ onClose, cardId, status, userId, queryId }: FreezeProps) => {
  const { handleFreeze, loading, error, isError } = useCardHandlers(onClose);

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);
  return (
    <div className="bg-white w-[315px] m-auto mt-48 rounded">
      <div className="p-4">
        <p className="text-blackDark text-center mt-8 font-medium text-lg">
          Are you sure you want to {status === "disabled" ? "unfreeze" : "freeze"} this card?
        </p>
      </div>

      <div className="px-4 flex flex-col justify-between items-center">
        <Button
          loading={loading}
          variant="primary"
          label={`Yes, ${status === "disabled" ? "unfreeze" : "freeze"}`}
          onClick={() =>
            handleFreeze({
              cardId,
              userId: userId,
              type: status === "disabled" ? "unfreeze" : "freeze",
              queryId
            })
          }
        />
        <Button variant="neutral" label="Cancel" onClick={onClose} />
      </div>
    </div>
  );
};

export default Freeze;
