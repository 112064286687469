import axios, { InternalAxiosRequestConfig, AxiosHeaders } from "axios";

const baseURL = process.env.REACT_APP_API;
const AppToken = process.env.REACT_APP_APP_TOKEN;

export const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "x-app-token": AppToken,
  },
});

// Request interceptor for API calls
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const token = user?.tokens?.access?.token;
    if (token) {
      (config.headers as AxiosHeaders).set("Authorization", `Bearer ${token}`);
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const refreshToken = async () => {
  // const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  // const token = user?.tokens?.refresh?.token;
  // const _data = { refreshToken: token };
  // const res = await api.post("users/v1/auth/refresh-tokens", _data);
  // user.tokens = res.data.tokens;
  // localStorage.setItem("user", JSON.stringify(user));
  // return res.data;

  localStorage.clear();
  window.location.href = "/";
};

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const errStatus = error.response.status;
    const errMsg: string = error.response.data.message;
    if (errStatus === 401 && !originalRequest._retry) {
      const messages = [
        "Please authenticate",
        "Invalid authorization token",
        "Bad/expired authorisation token",
        "authentication failed"
      ];
      if (messages.includes(errMsg)) {
        originalRequest._retry = true;
        await refreshToken();
        return api(originalRequest);
      }
    }
    if (error.response) {
      return Promise.reject(error.response.data);
    }

    if (error.request) {
      return Promise.reject(error.request);
    }

    return Promise.reject(error);
  }
);
