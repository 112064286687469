interface PaginationProps {
  data: any;
  prev: () => void;
  next: () => void;
  currentPage?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
}

const Pagination = ({ 
  data, 
  prev, 
  next, 
  currentPage, 
  totalPages,
  onPageChange 
}: PaginationProps) => {
  const page = currentPage || data?.page || 1;
  const total = totalPages || data?.totalPages || Math.ceil((data?.totalResults || 0) / 10);
  const hasNext = page < total;
  const hasPrev = page > 1;

  // Function to generate page numbers array
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    let startPage = Math.max(1, page - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(total, startPage + maxVisiblePages - 1);

    // Adjust start page if we're near the end
    if (endPage - startPage + 1 < maxVisiblePages) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  return (
    <div className="flex items-center gap-4">
      <div className="text-sm text-gray-700">
        Showing <span className="font-medium">{data?.results?.length || 0}</span> of{' '}
        <span className="font-medium">{data?.totalResults || 0}</span> results
      </div>

      <div className="flex items-center gap-2">
        {hasPrev && (
          <button
            onClick={prev}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm font-medium bg-white text-gray-700 hover:bg-gray-50"
          >
            <svg className="h-4 w-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
            Previous
          </button>
        )}

        <div className="flex items-center gap-1">
          {getPageNumbers().map((pageNum) => (
            <button
              key={pageNum}
              onClick={() => onPageChange?.(pageNum)}
              className={`px-3 py-1.5 text-sm font-medium rounded-md ${
                pageNum === page
                  ? 'bg-primary text-white'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
              }`}
            >
              {pageNum}
            </button>
          ))}
        </div>

        {hasNext && (
          <button
            onClick={next}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 rounded-md text-sm font-medium bg-white text-gray-700 hover:bg-gray-50"
          >
            Next
            <svg className="h-4 w-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default Pagination;
