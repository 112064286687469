import Button from "components/Button";
import Input from "components/inputs/Input";
import filter from "assets/filter.svg";
import { useState } from "react";
import Modal from "components/Modal";
import InviteAdmin from "./modals/Invite";
import { useDocumentTitle } from "hooks/useDocument";
import { useFetchAdmins } from "./useAdminHandlers";
import { AnyObject } from "utils/types";
import { startCase } from "lodash";
import Pagination from "components/Pagination";
import ChangeRole from "./modals/ChangeRole";
import { useNavigate } from "react-router-dom";
import AccessDenied from "components/AccessDenied";
import { useAuthContext } from "hooks/useAuthContext";
import Loader from "components/Loader";
import EmptyTable from "components/EmptyTable";
import { useDebounce } from "hooks/useDebounce";

type ModalType = "invite" | "change" | undefined;
export default function Administrators() {
  useDocumentTitle("Administrators");

  const navigate = useNavigate();

  const { user } = useAuthContext();
  
  const [modalType, setModalType] = useState<ModalType>(undefined);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [resultToChange, setResultToChange] = useState({});
  const [searchTerm, setSearchTerm] = useState("");


  const showModal = (type: ModalType) => {
    setShow(true);
    setModalType(type);
  };

  const onChangeRole = (type: ModalType, result: AnyObject) => {
    setShow(true);
    setModalType(type);
    setResultToChange(result);
  };

  const onClose = () => {
    setShow(false);
    setModalType(undefined);
    setResultToChange({});
  };

  const { data, error, loadingAdmins } = useFetchAdmins(page, useDebounce(searchTerm));

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  if(loadingAdmins) return <Loader />

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }
  return (
    <>
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">
          Administrators - {data?.totalResults}
        </p>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div className="flex gap-2 items-center mt-2">
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => 
                setSearchTerm(e.target.value)
              }
              placeholder="Enter filter"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          <div className="flex gap-2 justify-end">
            <Button
              variant="neutral"
              label="+ Manage Roles"
              onClick={() => navigate("/manage-role")}
            />
            <Button label="+ Invite User" onClick={() => showModal("invite")} />
          </div>
        </div>
      </div>
      <div className="py-6 px-12 bg-white border border-btnInactive gap-4 grid grid-cols-[1fr_2fr_1fr_1fr] items-center">
        <p className="text-sm font-bold text-blackDark">Name</p>
        <p className="text-sm font-bold text-blackDark">Email</p>
        <p className="text-sm font-bold text-blackDark">Role</p>
        <p className="text-sm font-bold text-blackDark"></p>
      </div>
      
      {data?.results?.map((result: AnyObject) => (
        <div
          key={result.id}
          className="py-6 px-12 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[1fr_2fr_1fr_1fr] items-center"
        >
          <p className="font-normal text-sm text-blackDark">
            {result.firstName} {result.lastName}
          </p>
          <p className="font-normal text-sm text-blackDark">{result.email} </p>
          <p className="font-normal text-sm text-blackDark">
            {startCase(result.role)}{" "}
          </p>
          {user.id !== result.id && (
            <div className="flex gap-3 items-center justify-center">
              <span
                role="button"
                onClick={() => onChangeRole("change", result)}
                className="text-primary text-sm"
              >
                Change role
              </span>
              <span className="w-[2px] h-4 bg-greyDark" />
              <span className="text-danger text-sm">Remove</span>
            </div>
          )}
        </div>
      )) || <EmptyTable />}

      {data?.results.length > 0 && <Pagination data={data} prev={prev} next={next} />}

      <Modal isShown={show}>
        {modalType === "invite" && <InviteAdmin onClose={onClose} />}
        {modalType === "change" && (
          <ChangeRole onClose={onClose} data={resultToChange} />
        )}
      </Modal>
    </>
  );
}
