import { KeyboardEvent, ReactNode, useEffect, useState } from "react";
import { useFetchComments, useMigrationActions } from "./useMigrationHandlers";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";
import { convertDate, convertTime } from "utils/fx";
import { AnyObject } from "utils/types";

interface ChatMessageProps {
  user: string;
  department: string;
  message: ReactNode;
  time: string;
  mention?: string;
}

const ChatMessage = ({
  user,
  department,
  message,
  time,
  mention,
}: ChatMessageProps) => (
  <div className="mb-4 bg-chatBg p-3 rounded-lg w-fit">
    <div className="flex justify-between items-baseline mb-1 gap-4">
      <div>
        <span className="font-bold text-[#2C2C2E] mr-2">{user}</span>
        <span className="text-sm text-[#666668]">{department}</span>
      </div>
      <span className="text-xs text-[#666668]">{time}</span>
    </div>
    <p className="text-[#2C2C2E]">
      {message}
      {mention && (
        <span className="text-[#006BDF] cursor-pointer">{mention}</span>
      )}
    </p>
  </div>
);

const Comments = () => {
  const param = useParams();
  const { data, isLoading } = useFetchComments(param.id ?? "");

  const { handleComment, loading, isSuccess } = useMigrationActions();

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isSuccess) {
      setMessage("");
    }
  }, [isSuccess]);

  if (isLoading) return <Loader />;

  const addComment = () => {
    if(!message) return
    handleComment({ message, leadId: param?.id as string });
  };

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if(event.key === 'Enter') {
      if(!message) return
      handleComment({ message, leadId: param?.id as string });
    }
  }
  return (
    <div className="p-4 bg-white shadow-lg rounded border border-btnInactive">
      <div className="text-center mb-4 text-gray-600"></div>

      {data?.length > 0 ? (
        data?.map((item: AnyObject) => (
          <ChatMessage
            key={item.id}
            user={item.author.username}
            department=""
            message={item.comment}
            time={`${convertDate(new Date(item.createdAt))} ${convertTime(
              new Date(item.createdAt)
            )}`}
          />
        ))
      ) : (
        <div className="flex items-center justify-center p-10">
          <p>No comments yet</p>
        </div>
      )}

      <div className="mt-4 flex items-center border-t pt-4 max-w-2xl">
        <input
          type="text"
          placeholder="Start typing..."
          className="flex-grow p-2 rounded-l-lg outline-none focus:outline-none"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onKeyDown}
        />
        {/* <button className="">
          <span role="img" aria-label="Send">
            📎
          </span>
        </button> */}
        <button
          onClick={addComment}
          className="bg-transparent text-white p-2 rounded-lg ml-2"
        >
          <span role="img" aria-label="Send" className="text-[#8E8E93]">
            {loading ? "sending..." : "▶️"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default Comments;
