export type Doc = (image: string, name: string) => void;
export const ViewDocument = ({
  file,
  name,
  showDocument,
}: {
  file: string;
  name: string;
  showDocument: Doc;
}) => (
  <div>
    <p className="text-coolGrey text-sm mb-3">{name}</p>
    {file?.includes(".pdf") ? (
      <a
        target="_blank"
        rel="noreferrer"
        href={file}
        className="text-sm text-primary font-medium cursor-pointer"
      >
        {" "}
        View Document
      </a>
    ) : (
      <button
        onClick={file ? () => showDocument(file, name) : () => {}}
        className={`text-sm  font-medium ${
          file
            ? "text-primary cursor-pointer"
            : "text-btnInactive cursor-not-allowed"
        }`}
      >
        View Document
      </button>
    )}
  </div>
);
