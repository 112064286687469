import increase from "assets/increase.svg";
import decrease from "assets/decrease.svg";

interface CardProps {
  title: string;
  value: string;
  cardIcon: string;
  type?: "increase" | "decrease";
  change?: string;
}

const icon = {
  increase,
  decrease,
};
const CardItem = ({ title, value, cardIcon, type, change }: CardProps) => (
  <div className="bg-white py-2.5 px-5 rounded-lg w-[220px] 2xl:w-[300px]">
    <p className="text-xs font-semibold text-lightText mb-2">{title}</p>
    <div className="flex justify-end items-center">
      <img src={cardIcon} alt={title} />
    </div>
    <p className="text-[20px] text-black font-bold tracking-widest -mt-4">
      {value}
    </p>
    <div className="flex items-center justify-between">
      {change ? (
        <div className="flex items-center gap-0.5">
          {type && <img src={icon[type]} alt={type} />}
          <p
            className={`text-${
              type === "increase" ? "primary" : "danger"
            } text-sm font-bold`}
          >
            {change}
          </p>
        </div>
      ) : (
        <div />
      )}
      {/* <button className="bg-transparent text-primary text-xs font-semibold">
        View All
      </button> */}
    </div>
  </div>
);

export default CardItem;
