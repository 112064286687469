import Pagination from "components/Pagination";
import Status from "components/Status";
import {
  convertDate,
  convertTime,
  copyToClipboard,
  formatNumber,
  formatReference,
} from "utils/fx";
import { AnyObject } from "utils/types";
import EmptyTable from "components/EmptyTable";
import copy from "assets/copy-grey.svg";
import { useFetchCardTransactions } from "./useCardHandlers";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "components/Loader";

export default function CardTransactions() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { transactions, loadingTransactions } = useFetchCardTransactions(page, id as string);

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < transactions?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  if(loadingTransactions) return <Loader />

  if (transactions?.cards?.length === 0) {
    return <EmptyTable message="No transactions available for this card" />;
  }

  return (
    <>
      <div className="py-6 px-10 bg-white border border-btnInactive gap-4 grid grid-cols-[1fr_1.2fr_150px_.8fr_150px_100px] items-center">
        <p className="text-sm font-bold text-blackDark">Amount</p>
        <p className="text-sm font-bold text-blackDark">
          Transaction Reference
        </p>
        <p className="text-sm font-bold text-blackDark">Type</p>
        <p className="text-sm font-bold text-blackDark">Beneficiary</p>
        <p className="text-sm font-bold text-blackDark">
          Transaction Date/Time
        </p>
        <p className="text-sm font-bold text-blackDark">Status</p>
      </div>

      {transactions?.cards?.map((result: AnyObject) => (
        <div key={result.id} className="py-6 px-10 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[1fr_1.2fr_150px_.8fr_150px_100px] items-center">
          <p className="font-normal text-sm text-blackDark">
            {formatNumber(result.amount / 100, result.currency)}
          </p>
          {formatReference(result.transactionRef) ? (
            <span className="font-normal text-sm text-blackDark inline-flex gap-0.5">
              <span className="">{formatReference(result.transactionRef)}</span>
              <img
                src={copy}
                alt="copy"
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard(result.transactionRef);
                }}
              />
            </span>
          ) : (
            <span className="font-normal text-sm text-blackDark inline-flex gap-0.5"></span>
          )}
          <p className="font-normal text-sm text-blackDark break-words">
            {result.type}
          </p>
          <p className="font-normal text-sm text-blackDark break-words">
            {result.merchant?.name ?? result.merchant}
          </p>

          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.createdAt))}
            <br />
            {convertTime(new Date(result.createdAt))}
          </p>

          <Status width="w-full" status={result.status.toLowerCase()} />
        </div>
      ))}
      <Pagination
        data={transactions as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />
    </>
  );
}
