import back from "assets/back.svg";
import Button from "components/Button";
import Checkbox from "components/inputs/Checkbox";
import Input from "components/inputs/Input";
import TextArea from "components/inputs/TextArea";

import { useNavigate } from "react-router-dom";
import { useAdminHandlers, useFetchPermissions } from "./useAdminHandlers";
import { AnyObject } from "utils/types";
import { startCase } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import { object, string } from "yup";
import { toast } from "react-hot-toast";
import Loader from "components/Loader";
import EmptyTable from "components/EmptyTable";

let permArray: Array<string> = [];

export interface CreateRoleForm {
  name: string;
  description: string;
  permissions: Array<string>;
}
const CreateRole = () => {
  const navigate = useNavigate();

  const { permissions, loadingPermissions } = useFetchPermissions();
  const [arrPerms, setArrayPerms] = useState<Array<string>>([]);

  const { handleCreateRole, isError, error, loading } = useAdminHandlers();

  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<CreateRoleForm>({
      initialValues: {
        name: "",
        permissions: [],
        description: "",
      },
      validationSchema: object({
        name: string().required("A role name must be provided"),
        description: string().required("Role description is required"),
      }),
      onSubmit: (values) => {
        values.permissions = arrPerms;
        handleCreateRole(values);
      },
    });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (!permArray.includes(name)) {
      permArray = [...permArray, name];
    } else {
      permArray = permArray.filter((perm) => perm !== name);
    }
    setArrayPerms(permArray);
  };

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  return (
    <>
      <div
        className="pt-2 mb-8 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate("/administrators")}
      >
        <img src={back} alt="back" />
        Administrators
      </div>
      <form onSubmit={handleSubmit}>
        <div className="bg-white shadow-sm max-w-lg mx-auto rounded border border-btnInactive gap-8">
          <div className="border-b border-btnInactive py-5 px-6 mx-auto">
            <p className="flex items-center justify-center font-bold">
              Create Role
            </p>
          </div>
          <div className="mt-4 mx-12">
            <Input
              id="name"
              label="Role Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              error={`${!!errors["name"] && !!touched["name"]}`}
              errorMessage={errors["name"]}
              onBlur={handleBlur}
            />

            <TextArea
              id="description"
              label="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              error={`${!!errors["description"] && !!touched["description"]}`}
              errorMessage={errors["description"]}
              onBlur={handleBlur}
            />
          </div>

          <div className="mx-12">
            <p className="text-blackDark font-medium">Permissions</p>
            <div className=" flex flex-col">
              {loadingPermissions && <Loader />}
              {permissions?.length > 0 ? (
                permissions?.map((permission: AnyObject) => {
                  const { name, description, id } = permission;
                  const [action, entity] = name.split(":");
                  return (
                    <div
                      key={id}
                      className="flex justify-start items-center gap-4 my-4"
                    >
                      <Checkbox
                        id={id}
                        name={name}
                        value={name}
                        onChange={onChange}
                        data={arrPerms}
                      />
                      <p className="text-sm text-blackDark" title={description}>
                        {startCase(action)} {startCase(entity)}
                      </p>
                    </div>
                  );
                })
              ) : (
                <div className="py-4">
                  <EmptyTable message="No permissions yet" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mx-60">
          <Button
            disabled={loading}
            size="small"
            label="Create Role"
            type="submit"
            loading={loading}
          />
        </div>
      </form>
    </>
  );
};

export default CreateRole;
