import close from "assets/close.svg";
import Button from "components/Button";
import { ChangeEvent } from "react";

interface FilterProps {
  onClose: () => void;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  filterTerm: string;
}

const statusGroups = {
  "Initial Stage": [
    { label: "Submitted", value: "Submitted" },
    { label: "Incomplete", value: "Incomplete" },
    { label: "Assessment form filled", value: "Assessment form filled" },
  ],
  "Consultation": [
    { label: "Scheduled appointment", value: "Scheduled appointment with a consultant" },
    { label: "Met with consultant", value: "Met with consultant" },
    { label: "Group Consultation Done", value: "Group Consultation Done" },
    { label: "Recommendation complete", value: "Recommendation complete" },
  ],
  "Service": [
    { label: "Subscribe to service", value: "Subscribe to service" },
    { label: "Customer Not Ready", value: "Customer Not Ready" },
  ],
  "Migration": [
    { label: "Migration Journey ongoing", value: "Migration Journey ongoing" },
    { label: "Migration Journey Completed", value: "Migration Journey Completed" },
  ]
};

const Filter = ({ onClose, onChange, filterTerm }: FilterProps) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50">
      <div className="bg-white w-[400px] mt-20 rounded-lg shadow-lg overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b border-btnInactive">
          <p className="text-black text-base font-bold">Filter by Status</p>
          <img role="button" src={close} alt="close" onClick={onClose} className="cursor-pointer" />
        </div>

        <div className="p-4 space-y-4">
          <div className="mb-2">
            <button
              className={`px-3 py-1 rounded-full text-sm mr-2 ${
                !filterTerm ? "bg-primary text-white" : "bg-gray-100 text-coolGrey"
              }`}
              onClick={() => onChange({ target: { value: "" } } as ChangeEvent<HTMLSelectElement>)}
            >
              All Statuses
            </button>
          </div>

          {Object.entries(statusGroups).map(([group, statuses]) => (
            <div key={group} className="border-b border-btnInactive last:border-0 pb-4">
              <h3 className="text-sm font-medium text-coolGrey mb-2">{group}</h3>
              <div className="flex flex-wrap gap-2">
                {statuses.map((status) => (
                  <button
                    key={status.value}
                    className={`px-3 py-1 rounded-full text-sm ${
                      filterTerm === status.value ? "bg-primary text-white" : "bg-gray-100 text-coolGrey"
                    } hover:bg-opacity-90 transition-colors duration-200`}
                    onClick={() => onChange({ target: { value: status.value } } as ChangeEvent<HTMLSelectElement>)}
                  >
                    {status.label}
                  </button>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="p-4 border-t border-btnInactive flex justify-end">
          <Button
            variant="neutral"
            size="small"
            label="Close"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default Filter;
