import home from "assets/home.svg";
import card from "assets/card-white.svg";
import metrics from "assets/filter.svg";
import user from "assets/user.svg";
import migration from "assets/migration.svg";

export const RouteLinks = [
  {
    name: "Home",
    path: "/home",
    icon: <img src={home} alt="home" loading="lazy" />,
  },
  
  {
    name: "Cards",
    path: "/cards",
    icon: <img src={card} alt="cards" loading="lazy" />,
  },
  {
    name: "Migration Assessment",
    path: "/migration-assessment",
    icon: <img src={migration} alt="migration" loading="lazy" />,
  },
  {
    name: "Analytics",
    path: "/analytics",
    icon: <img src={metrics} alt="metrics" loading="lazy" />,
  },
  {
    name: "Administrators",
    path: "/administrators",
    icon: <img src={user} alt="user" loading="lazy" />,
  },
]
