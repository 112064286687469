export const personalKYC = [
  "Status",
  "Name",
  "CurrentTier",
  "RequestedTier",
  "Phone Number",
  "Email",
  "Date Submitted",
];

export const businessKYC = ["Status", "Name", "RC/BC Number", "Date Submitted"];

export const allUsers = [
  "Status",
  "Name",
  "Email",
  "Phone Number",
  "Date Created",
  "User Type",
];
export const users = [
  "Status",
  "Name",
  "Email",
  "Phone Number",
  "Date Created",
];
export const blacklistedUsers = ["Name", "Email", "Reason", "Date Created"];
export const accounts = [
  "Status",
  "Account Name",
  "Account Number",
  "User Name",
  "Currency",
  "Account Balance",
  "Current Tier",
  "Date Created",
];

export const localTransactions = [
  "Account Name",
  "Amount",
  "Transaction Reference",
  "Type",
  "Beneficiary",
  "Transaction Date/Time",
  "Description",
];

export const crossBorder = [
  "Account Name",
  "Amount",
  "Transaction Reference",
  "Type",
  "Beneficiary",
  "Transaction Date/Time",
  "Status",
];

export const cards = [
    "Status",
    "Card Holder",
    "Card Name",
    "Masked Pan",
    "Card Scheme Type",
    "Account Type",
    "Date Created",
  ];

  export const transactionHistory = [
    "Amount",
    "Transaction Reference",
    "Type",
    "Beneficiary",
    "Transaction Date/Time",
    "Status",
  ];
