import Pagination from "components/Pagination";
import Status from "components/Status";
import { useOutletContext } from "react-router-dom";
import {
  convertDate,
  convertTime,
  copyToClipboard,
  formatNumber,
  formatReference,
} from "utils/fx";
import { AnyObject, ContextType } from "utils/types";
import EmptyTable from "components/EmptyTable";
import copy from "assets/copy-grey.svg";
import { transactionHistory } from "utils/tableHeaders";

export default function CardsTransactions() {
  const { data, next, prev } = useOutletContext<ContextType>();

  if (data.cards.length === 0) {
    return <EmptyTable />;
  }

  return (
    <>
      <div className="py-6 px-10 bg-white border border-btnInactive gap-4 grid grid-cols-[1fr_1.2fr_150px_.8fr_150px_100px] items-center">
        {transactionHistory.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
      </div>

      {data.cards?.map((result: AnyObject) => (
        <div
          className="py-6 px-10 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[1fr_1.2fr_150px_.8fr_150px_100px] items-center"
          key={result.id}
        >
          <p className="font-normal text-sm text-blackDark">
            {formatNumber(result.amount / 100, result.currency)}
          </p>
          {formatReference(result.transactionRef) ? (
            <span className="font-normal text-sm text-blackDark inline-flex gap-0.5">
              <span className="">{formatReference(result.transactionRef)}</span>
              <img
                src={copy}
                alt="copy"
                className="cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  copyToClipboard(result.transactionRef);
                }}
              />
            </span>
          ) : (
            <span className="font-normal text-sm text-blackDark inline-flex gap-0.5"></span>
          )}
          <p className="font-normal text-sm text-blackDark break-words">
            {result.type}
          </p>
          <p className="font-normal text-sm text-blackDark break-words">
            {result.merchant?.name ?? result.merchant}
          </p>

          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(result.createdAt))}
            <br />
            {convertTime(new Date(result.createdAt))}
          </p>

          <Status width="w-full" status={result.status.toLowerCase()} />
        </div>
      ))}
      <Pagination
        data={data as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />
    </>
  );
}
