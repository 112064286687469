import { useOutletContext } from "react-router-dom";
import { ContextType } from "utils/types";
import CallLogsComponent from "./components/CallLogs";

const CallLogs = () => {
  const { data: lead } = useOutletContext<ContextType>();
  
  return (
    <div className="mt-8">
      <CallLogsComponent 
        migrationId={lead.id} 
      />
    </div>
  );
};

export default CallLogs; 