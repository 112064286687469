import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { motion } from 'framer-motion';
import { subDays, startOfMonth, endOfMonth, subMonths } from 'date-fns';

interface DateRangeFilterProps {
  startDate: Date | null;
  endDate: Date | null;
  onDateChange: (start: Date | null, end: Date | null) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  startDate,
  endDate,
  onDateChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const presetRanges = [
    { 
      label: 'Clear Selection', 
      onClick: () => {
        onDateChange(null, null);
        setIsOpen(false);
      }
    },
    { label: 'Today', onClick: () => {
      const today = new Date();
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      onDateChange(today, tomorrow);
    }},
    { label: 'Yesterday', onClick: () => {
      const yesterday = subDays(new Date(), 1);
      const today = new Date();
      onDateChange(yesterday, today); 
    }},
    { label: 'Last 7\ndays', onClick: () => {
      const end = new Date();
      const start = subDays(end, 6);
      onDateChange(start, end);
    }},
    { label: 'Last 30\ndays', onClick: () => {
      const end = new Date();
      const start = subDays(end, 29);
      onDateChange(start, end);
    }},
    { label: 'This\nmonth', onClick: () => {
      const now = new Date();
      const start = startOfMonth(now);
      const end = endOfMonth(now);
      onDateChange(start, end);
    }},
    { label: 'Last\nmonth', onClick: () => {
      const lastMonth = subMonths(new Date(), 1);
      const start = startOfMonth(lastMonth);
      const end = endOfMonth(lastMonth);
      onDateChange(start, end);
    }},
  ];

  return (
    <div className="relative">
      <div className="flex items-center gap-2">
        <button
          onClick={() => setIsOpen(!isOpen)} 
          className="flex-1 flex items-center gap-2 px-4 py-2 bg-white border border-btnInactive rounded-lg text-sm text-coolGrey hover:bg-gray-50"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <span className="flex-1 text-left">
            {startDate && endDate 
              ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
              : 'Select date range'}
          </span>
        </button>
        {(startDate || endDate) && (
          <button
            onClick={() => onDateChange(null, null)}
            className="p-2 hover:bg-gray-100 rounded-lg"
            title="Clear date range"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        )}
      </div>

      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          className="absolute left-0 mt-2 bg-white rounded-lg shadow-lg border border-btnInactive z-50 w-[700px]"
        >
          <div className="flex overflow-y-scroll">
            <div className="w-48 p-4 bg-gray-50 border-r border-btnInactive">
              <h3 className="text-sm font-medium text-coolGrey mb-4">Quick select</h3>
              <div className="space-y-3">
                {presetRanges.map((range) => (
                  <button
                    key={range.label}
                    onClick={() => {
                      range.onClick();
                      setIsOpen(false);
                    }}
                    className="w-full text-left px-3 py-2 text-sm text-coolGrey hover:bg-white rounded transition-colors whitespace-pre-line"
                  >
                    {range.label}
                  </button>
                ))}
              </div>
            </div>

            <div className="flex-1 p-4">
              <h3 className="text-sm font-medium text-coolGrey mb-4">Select range</h3>
              <div className="flex gap-8">
                <div className="flex-1">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => onDateChange(date, endDate)}
                    selectsStart
                    startDate={startDate || undefined}
                    endDate={endDate || undefined}
                    maxDate={endDate || new Date()}
                    placeholderText="Start date"
                    className="hidden"
                    calendarClassName="!border-none !font-sans"
                    dayClassName={date => 
                      date && startDate && date.getTime() === startDate.getTime()
                        ? "!bg-primary !text-white rounded-full"
                        : "hover:!bg-gray-100 rounded-full"
                    }
                    inline
                    showPopperArrow={false}
                  />
                </div>
                <div className="flex-1">
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => onDateChange(startDate, date)}
                    selectsEnd
                    startDate={startDate || undefined}
                    endDate={endDate || undefined}
                    minDate={startDate || undefined}
                    maxDate={new Date()}
                    placeholderText="End date"
                    className="hidden"
                    calendarClassName="!border-none !font-sans"
                    dayClassName={date => 
                      date && endDate && date.getTime() === endDate.getTime()
                        ? "!bg-primary !text-white rounded-full"
                        : "hover:!bg-gray-100 rounded-full"
                    }
                    inline
                    showPopperArrow={false}
                  />
                </div>
              </div>
               {/* Action Buttons */}
               <div className="flex justify-between mt-4 pt-4 border-t border-gray-200">
                  <button
                    onClick={() => {
                      onDateChange(null, null);
                      setIsOpen(false);
                    }}
                    className="px-4 py-2 text-sm text-coolGrey hover:bg-gray-100 rounded-md transition-colors"
                  >
                    Clear
                  </button>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="px-4 py-2 text-sm text-white bg-primary hover:bg-primary/90 rounded-md transition-colors"
                  >
                    Apply
                  </button>
                </div>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default DateRangeFilter;
