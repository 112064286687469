import Button from "components/Button";
import Input from "components/inputs/Input";
import { useFormik } from "formik";
import { useDocumentTitle } from "hooks/useDocument";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { object, string } from "yup";
import { useAuthHandlers } from "./useAuthHandlers";
import { toast } from "react-hot-toast";
import { AnyObject } from "utils/types";

export interface LoginValues {
  email: string;
  password: string;
}
function Login() {
  useDocumentTitle("Login");

  const { handleLogin, isError, error, loading } = useAuthHandlers();
  const [type, setType] = useState("password");

  const showAction = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error(
        (error as AnyObject)?.message || "An error occurred"
      );
    }
  }, [error, isError]);

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
  } = useFormik<LoginValues>({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: object({
      email: string()
        .email("Please enter a valid email")
        .required("Email is required"),
      password: string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      await handleLogin(values);
    },
  });

  return (
    <Fragment>
      <p className="text-blackDarkDark text-xl font-bold text-center mb-8">
        Sign in to your account
      </p>

      <form noValidate onSubmit={handleSubmit}>
        <Input
          type="email"
          label="Email"
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          placeholder={"Enter email"}
          error={`${!!errors["email"] && !!touched["email"]}`}
          errorMessage={errors["email"]}
          onBlur={handleBlur}
        />
        <Input
          type={type}
          label="Password"
          id="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          placeholder={"Enter password"}
          showText={type === "password" ? "show" : "hide"}
          showAction={showAction}
          error={`${!!errors["password"] && !!touched["password"]}`}
          errorMessage={errors["password"]}
          onBlur={handleBlur}
        />
        <Button
          loading={loading}
          label="Sign in"
          type="submit"
          disabled={values.email.length === 0 || values.password.length === 0}
        />

        <div className="flex items-center justify-center">
          <Link to="/forgot-password" className="text-primary">
            Forgot password?
          </Link>
        </div>
      </form>
    </Fragment>
  );
}

export default Login;
