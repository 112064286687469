import { useState, ChangeEvent } from 'react';
import Button from 'components/Button';
import StarRating from 'components/StarRating';

interface AppraisalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { rating: number; feedback: string }) => Promise<void>;
  callLogId: string;
}

export default function AppraisalModal({ isOpen, onClose, onSubmit, callLogId }: AppraisalModalProps) {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      await onSubmit({
        rating,
        feedback
      });
      onClose();
    } catch (error) {
      console.error('Failed to submit appraisal:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={`fixed inset-0 z-50 ${isOpen ? 'block' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black bg-opacity-50" onClick={onClose} />
      <div className="fixed left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-md bg-white rounded-lg shadow-xl">
        <div className="p-6">
          <h2 className="text-lg font-semibold mb-4">Appraise Call Log</h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Rating
              </label>
              <StarRating
                rating={rating}
                onChange={setRating}
                size={32}
                className="space-x-2"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Feedback
              </label>
              <textarea
                value={feedback}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setFeedback(e.target.value)}
                placeholder="Enter your feedback about this call..."
                required
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-primary focus:border-primary"
              />
            </div>

            <div className="flex justify-end space-x-3">
              <Button
                variant="secondary"
                size="small"
                label="Cancel"
                onClick={onClose}
                type="button"
              />
              <Button
                variant="primary"
                size="small"
                label={isSubmitting ? 'Submitting...' : 'Submit Appraisal'}
                type="submit"
                disabled={isSubmitting || rating === 0 || !feedback.trim()}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
} 