import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

interface TourStep {
  target: string;
  title: string;
  content: string;
  position: "top" | "bottom" | "left" | "right";
}

const tourSteps: TourStep[] = [
  {
    target: '[data-tour="summary-cards"]',
    title: "Summary Cards",
    content:
      "These cards show key metrics of your migrations. Click on any card to filter the table by that status.",
    position: "bottom",
  },
  {
    target: '[data-tour="search-filter"]',
    title: "Search & Filters",
    content:
      "Use these filters to search and filter migrations by various criteria like status, assigned user, or date range.",
    position: "bottom",
  },
  {
    target: '[data-tour="date-range"]',
    title: "Date Range Filter",
    content:
      "Filter migrations by date range. Use quick select options or choose custom dates.",
    position: "bottom",
  },
  {
    target: '[data-tour="insights-toggle"]',
    title: "Insights View",
    content:
      "Toggle between table view and insights view to see detailed analytics and visualizations.",
    position: "left",
  },
  {
    target: '[data-tour="migration-table"]',
    title: "Migrations Table",
    content:
      "View all your migrations here. Click on any row to see detailed information.",
    position: "top",
  },
  {
    target: '[data-tour="refresh-button"]',
    title: "Refresh Data",
    content:
      "Click here to refresh the dashboard data and see the latest updates.",
    position: "left",
  },
];

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DashboardTour: React.FC<Props> = ({ isOpen, onClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [targetElement, setTargetElement] = useState<DOMRect | null>(null);

  useEffect(() => {
    if (isOpen && tourSteps[currentStep]) {
      const element = document.querySelector(tourSteps[currentStep].target);
      if (element) {
        // Get element position
        const rect = element.getBoundingClientRect();
        setTargetElement(rect);

        // Calculate scroll position to center the element
        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        const elementTop = rect.top + scrollTop;
        const windowHeight = window.innerHeight;
        const elementHeight = rect.height;

        // Calculate target scroll position to center element
        const targetScroll = elementTop - windowHeight / 2 + elementHeight / 2;

        // Smooth scroll to position
        window.scrollTo({
          top: targetScroll,
          behavior: "smooth",
        });

        // Add highlight effect to the element
        element.classList.add("tour-highlight");

        return () => {
          element.classList.remove("tour-highlight");
        };
      }
    }
  }, [currentStep, isOpen]);

  const handleNext = () => {
    if (currentStep < tourSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onClose();
      setCurrentStep(0);
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSkip = () => {
    onClose();
    setCurrentStep(0);
  };

  if (!isOpen || !targetElement) return null;

  const step = tourSteps[currentStep];
  const isLastStep = currentStep === tourSteps.length - 1;

  const getPopupPosition = () => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const popupWidth = 300;
    const popupHeight = 200;
    const offset = 12;

    let top = 0;
    let left = 0;

    switch (step.position) {
      case "top":
        top = targetElement.top - popupHeight - offset;
        left = targetElement.left + targetElement.width / 2 - popupWidth / 2;
        // Ensure popup stays within viewport
        if (top < 0) {
          top = targetElement.bottom + offset;
        }
        break;
      case "bottom":
        top = targetElement.bottom + offset;
        left = targetElement.left + targetElement.width / 2 - popupWidth / 2;
        // Ensure popup stays within viewport
        if (top + popupHeight > windowHeight) {
          top = targetElement.top - popupHeight - offset;
        }
        break;
      case "left":
        top = targetElement.top + targetElement.height / 2 - popupHeight / 2;
        left = targetElement.left - popupWidth - offset;
        // Ensure popup stays within viewport
        if (left < 0) {
          left = targetElement.right + offset;
        }
        break;
      case "right":
        top = targetElement.top + targetElement.height / 2 - popupHeight / 2;
        left = targetElement.right + offset;
        // Ensure popup stays within viewport
        if (left + popupWidth > windowWidth) {
          left = targetElement.left - popupWidth - offset;
        }
        break;
    }

    // Ensure left is within viewport
    left = Math.max(offset, Math.min(left, windowWidth - popupWidth - offset));
    // Ensure top is within viewport
    top = Math.max(offset, Math.min(top, windowHeight - popupHeight - offset));

    return { top, left };
  };

  return (
    <div className="fixed inset-0 z-50">
      {/* Semi-transparent backdrop with mask */}
      <div
        className="absolute inset-0 bg-black/50"
        style={{
          mask: `path('M 0 0 L 0 ${window.innerHeight} L ${window.innerWidth} ${window.innerHeight} L ${window.innerWidth} 0 L 0 0 M ${targetElement.left} ${targetElement.top} L ${targetElement.left} ${targetElement.bottom} L ${targetElement.right} ${targetElement.bottom} L ${targetElement.right} ${targetElement.top} Z')`,
          WebkitMask: `path('M 0 0 L 0 ${window.innerHeight} L ${window.innerWidth} ${window.innerHeight} L ${window.innerWidth} 0 L 0 0 M ${targetElement.left} ${targetElement.top} L ${targetElement.left} ${targetElement.bottom} L ${targetElement.right} ${targetElement.bottom} L ${targetElement.right} ${targetElement.top} Z')`,
        }}
      />

      {/* Highlight border for current element */}
      <div
        className="absolute border-2 border-primary pointer-events-none"
        style={{
          top: targetElement.top - 4,
          left: targetElement.left - 4,
          width: targetElement.width + 8,
          height: targetElement.height + 8,
          borderRadius: "8px",
          transition: "all 0.3s ease-in-out",
          zIndex: 1,
        }}
      />

      {/* Tour popup */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className="absolute bg-white rounded-lg shadow-xl p-6 pointer-events-auto"
        style={{
          width: "300px",
          ...getPopupPosition(),
          zIndex: 2,
        }}
      >
        <div className="mb-4">
          <h3 className="text-lg font-semibold text-gray-900 mb-2">
            {step.title}
          </h3>
          <p className="text-sm text-gray-600">{step.content}</p>
        </div>

        <div className="flex items-center justify-between mt-6">
          <div className="flex items-center gap-2">
            <button
              onClick={handlePrevious}
              disabled={currentStep === 0}
              className="px-3 py-1.5 text-sm text-coolGrey hover:bg-gray-100 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Previous
            </button>
            <button
              onClick={handleNext}
              className="px-3 py-1.5 text-sm text-white bg-primary hover:bg-primary/90 rounded-md transition-colors"
            >
              {isLastStep ? "Finish" : "Next"}
            </button>
          </div>

          <button
            onClick={handleSkip}
            className="text-sm text-coolGrey hover:text-gray-700"
          >
            Skip tour
          </button>
        </div>

        {/* Progress indicator */}
        <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-100 rounded-b-lg overflow-hidden">
          <div
            className="h-full bg-primary transition-all duration-300 ease-in-out"
            style={{
              width: `${((currentStep + 1) / tourSteps.length) * 100}%`,
            }}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default DashboardTour;
