import close from "assets/close.svg";
import { useFormik } from "formik";
import { object, string } from "yup";

import { useMigrationActions } from "../useMigrationHandlers";
import { AnyObject } from "utils/types";
import Button from "components/Button";
import Select from "components/inputs/Select";

interface ConsultantProps {
  onClose: () => void;
  data: AnyObject;
}

export interface ConsultantValues {
  consultant: string;
}

// Predefined consultants list
const CONSULTANTS = [
  {
    label: "Tope Hundeyin",
    value: JSON.stringify({
      userName: "Tope Hundeyin",
      email: "tope@blumefy.com"
    })
  },
  {
    label: "Victoria Ekanem",
    value: JSON.stringify({
      userName: "Victoria Ekanem",
      email: "victoria@blumefy.com"
    })
  },
  {
    label: "Kika Odaika",
    value: JSON.stringify({
      userName: "Kika Odaika",
      email: "kika.o@blumefy.com"
    })
  }
];

const Consultant = ({ onClose, data }: ConsultantProps) => {
  const { handleConsultant, loading } = useMigrationActions(onClose);

  const { handleSubmit, values, handleChange, handleBlur, errors, touched } =
    useFormik<ConsultantValues>({
      initialValues: {
        consultant: "",
      },
      validationSchema: object({
        consultant: string().required("Please select a consultant"),
      }),
      onSubmit: (values) => {
        const selectedConsultant = JSON.parse(values.consultant);
        const payload = {
          consultant: {
            userName: selectedConsultant.userName,
            email: selectedConsultant.email,
          },
          leadId: data.id,
        }
        handleConsultant(payload);
      },
    });

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-20 rounded overflow-auto"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-1">
        <p className="text-black text-base font-bold">Set Consultant</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>

      <div className="p-4 border-b border-btnInactive">
        <Select
          label="Select Consultant"
          id="consultant"
          name="consultant"
          value={values.consultant}
          onChange={handleChange}
          placeholder="Select a consultant"
          error={`${!!errors["consultant"] && !!touched["consultant"]}`}
          errorMessage={errors["consultant"]}
          data={CONSULTANTS}
          onBlur={handleBlur}
        />
      </div>

      <div className="p-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button 
          label="Set" 
          size="small" 
          type="submit" 
          loading={loading}
          disabled={!values.consultant || loading}
        />
      </div>
    </form>
  );
};

export default Consultant;
