import { useState } from "react";
import Button from "components/Button";
import CallLogModal from "../modal/CallLogModal";
import SortButton from "components/SortButton";
import DateRangeFilter from "components/inputs/DateRangeFilter";
import { useCallLogs } from "../hooks/useCallLogs";
import Toast from "components/Toast";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import Pagination from "components/Pagination";
import { motion } from "framer-motion";
import AppraisalModal from "../modal/AppraisalModal";
import { api } from "utils/api";
import { TooltipItem } from "chart.js";
import { generateConstantHexColors } from "utils/fx";
import { AnyObject } from "utils/types";
import { Doughnut } from "react-chartjs-2";

type ChartType = "bar" | "doughnut" | "radar";
interface CalledBy {
  userId: string;
  userName: string;
  email: string;
}

interface Appraisal {
  rating: number;
  feedback: string;
  appraisedBy: {
    userId: string;
    userName: string;
    email: string;
  };
  appraisedAt: string;
}

interface CallLog {
  id: string;
  leadId: {
    id: string;
    name: string;
    email: string;
  };
  calledBy: CalledBy;
  summary: string;
  statusByCall: string;
  statusAfterCall: string;
  dateTime: string;
  isApproved: boolean;
  comments: any[];
  createdAt: string;
  updatedAt: string;
  appraisal?: Appraisal;
}

interface CallLogsProps {
  migrationId?: string;
}

interface SortConfig {
  field: string;
  order: "asc" | "desc";
}

const formatDateTime = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(date);

  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }).format(date);

  return `${formattedDate} at ${formattedTime}`;
};

export default function CallLogs({ migrationId }: CallLogsProps) {
  const {
    callLogs,
    analytics,
    totalResults,
    isLoading,
    filters,
    setFilters,
    addCallLog,
  } = useCallLogs({
    page: 1,
    limit: 50,
    leadId: migrationId,
  });

  const [showModal, setShowModal] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: "dateFrom",
    order: "desc",
  });

  const [toast, setToast] = useState<{
    isVisible: boolean;
    message: string;
    type: "success" | "error";
  }>({
    isVisible: false,
    message: "",
    type: "success",
  });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [appraisalModal, setAppraisalModal] = useState<{
    isOpen: boolean;
    callLogId: string | null;
  }>({
    isOpen: false,
    callLogId: null,
  });

  console.log(analytics);

  const [expandedSummary, setExpandedSummary] = useState<{
    isOpen: boolean;
    text: string;
  }>({
    isOpen: false,
    text: "",
  });

  const handleAddCallLog = async (formData: any) => {
    try {
      await addCallLog.mutateAsync({
        leadId: migrationId || formData.accountId,
        summary: formData.summary,
        statusByCall: formData.statusByCall,
        statusAfterCall: formData.statusAfterCall,
        dateTime: formData.dateTime,
      });

      setToast({
        isVisible: true,
        message: "Call log created successfully!",
        type: "success",
      });

      queryClient.invalidateQueries(["callLogs"]);

      setTimeout(() => {
        setToast((prev) => ({ ...prev, isVisible: false }));
      }, 3000);
    } catch (error) {
      console.error("Failed to add call log:", error);

      setToast({
        isVisible: true,
        message: "Failed to create call log. Please try again.",
        type: "error",
      });

      setTimeout(() => {
        setToast((prev) => ({ ...prev, isVisible: false }));
      }, 3000);
    }
  };

  const handleFilterChange = (filterUpdates: Partial<typeof filters>) => {
    setFilters((prev) => ({
      ...prev,
      ...filterUpdates,
      leadId: migrationId,
      page: 1,
    }));
  };

  const handleSort = (field: string) => {
    setSortConfig((prev) => ({
      field,
      order: prev.field === field && prev.order === "desc" ? "asc" : "desc",
    }));
    handleFilterChange({ sortBy: `${field}:${sortConfig.order}` });
  };

  const handleAccountClick = (leadId: string) => {
    navigate(`/migration-assessment/${leadId}`);
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await queryClient.invalidateQueries(["callLogs"]);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setIsRefreshing(false);
  };

  const handlePageChange = (newPage: number) => {
    handleFilterChange({ page: newPage });
  };

  const handleAppraise = async (callLogId: string) => {
    setAppraisalModal({
      isOpen: true,
      callLogId,
    });
  };

  const handleSubmitAppraisal = async (data: {
    rating: number;
    feedback: string;
  }) => {
    if (!appraisalModal.callLogId) return;

    try {
      const response = await api.post(
        `/migration/v1/call-logs/${appraisalModal.callLogId}/appraisal`,
        {
          rating: data.rating,
          feedback: data.feedback,
        }
      );

      if (!response.data) {
        throw new Error("Failed to appraise call log");
      }

      queryClient.invalidateQueries(["callLogs"]);

      setToast({
        isVisible: true,
        message: "Call log appraised successfully!",
        type: "success",
      });
    } catch (error) {
      console.error("Failed to appraise call log:", error);
      setToast({
        isVisible: true,
        message: "Failed to appraise call log. Please try again.",
        type: "error",
      });
      throw error;
    }
  };

  // const handleResetDateRange = () => {
  //   handleFilterChange({ dateFrom: null, dateTo: null });
  // };

  const metrics =
    analytics?.metrics?.map((metric: AnyObject) => ({
      name: metric._id,
      value: metric.count,
    })) || [];

  const COLORS = generateConstantHexColors(metrics.length, 42);

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-coolGrey">
          {migrationId ? "Migration Call Logs" : "All Call Logs"}
        </h2>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleRefresh}
          disabled={isRefreshing}
          className={`p-2 rounded-full bg-white shadow hover:shadow-md transition-all duration-300 text-primary disabled:opacity-50 disabled:cursor-not-allowed ${
            isRefreshing ? "opacity-75 cursor-not-allowed" : ""
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 transition-transform duration-700 ease-in-out ${
              isRefreshing ? "animate-spin" : ""
            }`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </motion.button>
      </div>

      {/* Search and Filters Section */}
      <div className="grid grid-cols-3 gap-4">
        <div>
          <label className="block text-sm text-coolGrey mb-2">Date Range</label>
          <DateRangeFilter
            startDate={filters.dateFrom || null}
            endDate={filters.dateTo || null}
            onDateChange={(start, end) =>
              handleFilterChange({ dateFrom: start, dateTo: end })
            }
          />
        </div>
        <div>
          <label className="block text-sm text-coolGrey mb-2">
            Status Before Call
          </label>
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-primary focus:border-primary"
            value={filters.statusByCall || ""}
            onChange={(e) =>
              handleFilterChange({ statusByCall: e.target.value })
            }
          >
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="In Progress">In Progress</option>
            <option value="Completed">Completed</option>
            <option value="No Answer">No Answer</option>
            <option value="Customer Not Ready">Customer Not Ready</option>
            <option value="Follow Up Required">Follow Up Required</option>
          </select>
        </div>
        <div>
          <label className="block text-sm text-coolGrey mb-2">
            Status After Call
          </label>
          <select
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-primary focus:border-primary"
            value={filters.statusAfterCall || ""}
            onChange={(e) =>
              handleFilterChange({ statusAfterCall: e.target.value })
            }
          >
            <option value="">All</option>
            <option value="Pending">Pending</option>
            <option value="In Progress">In Progress</option>
            <option value="Submitted">Submitted</option>
            <option value="Subscribed">Subscribed</option>
            <option value="Completed">Completed</option>
            <option value="Follow Up Required">Follow Up Required</option>
          </select>
        </div>
      </div>
      <div className="h-[300px] flex items-center justify-center">
        <Doughnut
          data={{
            labels: metrics?.map((metric: any) => metric.name),
            datasets: [
              {
                data: metrics?.map((metric: any) => metric.value),
                backgroundColor: COLORS,
                borderWidth: 0,
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "right",
                labels: {
                  padding: 20,
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
              tooltip: {
                callbacks: {
                  label: (tooltipItem: TooltipItem<ChartType>) => {
                    const value = tooltipItem.raw as number;
                    const total = metrics
                      .map((metric: any) => metric.value)
                      .reduce((a: number, b: number) => a + b, 0);
                    const percentage = Math.round((value * 100) / total);
                    return `${tooltipItem.label}: ${value} (${percentage}%)`;
                  },
                },
              },
            },
          }}
        />
      </div>

      {/* Add Call Log Button */}
      <div className="flex justify-end">
        <Button
          variant="primary"
          size="small"
          label="Add Call Log"
          onClick={() => setShowModal(true)}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
          }
        />
      </div>

      {/* Call Logs Table */}
      {isLoading ? (
        <div className="text-center py-4">
          <div className="w-8 h-8 border-2 border-primary border-t-transparent rounded-full animate-spin mx-auto" />
        </div>
      ) : (
        <motion.div
          animate={{
            opacity: isRefreshing ? 0.5 : 1,
            scale: isRefreshing ? 0.99 : 1,
          }}
          transition={{ duration: 0.3 }}
          className="bg-white shadow-sm rounded-lg overflow-hidden"
        >
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <div className="flex items-center w-[100px]">
                    Account Name
                    <SortButton
                      field="name"
                      currentSort={sortConfig}
                      onSort={handleSort}
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <div className="flex items-center w-[120px]">
                    Called By
                    <SortButton
                      field="calledBy.userName"
                      currentSort={sortConfig}
                      onSort={handleSort}
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 max-w-xs text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Summary
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <div className="flex items-center">
                    Status Before Call
                    <SortButton
                      field="statusByCall"
                      currentSort={sortConfig}
                      onSort={handleSort}
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <div className="flex items-center">
                    Status After Call
                    <SortButton
                      field="statusAfterCall"
                      currentSort={sortConfig}
                      onSort={handleSort}
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  <div className="flex items-center w-[110px]">
                    Date & Time
                    <SortButton
                      field="dateTime"
                      currentSort={sortConfig}
                      onSort={handleSort}
                    />
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {callLogs.map((log: CallLog) => (
                <tr key={log.id} className="hover:bg-gray-50">
                  <td className="px-3 py-2 text-sm">
                    <div className="text-sm font-medium text-primary w-[120px] hover:text-primary/10 hover:underline focus:outline-none">
                      <button
                        onClick={() => handleAccountClick(log.leadId?.id)}
                        className="break-words text-left"
                      >
                        {log.leadId?.name || "Unknown Account"}
                      </button>
                    </div>
                  </td>
                  <td className="px-3 py-2 text-sm text-gray-900">
                    {log.calledBy.userName}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-900">
                    <div className="flex items-center gap-2">
                      <div className="w-[180px] truncate">{log.summary}</div>
                      {log.summary.length > 15 && (
                        <button
                          onClick={() =>
                            setExpandedSummary({
                              isOpen: true,
                              text: log.summary,
                            })
                          }
                          className="text-primary hover:text-primary-dark focus:outline-none"
                          title="View full summary"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </td>
                  <td className="px-3 py-2">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        log.statusByCall === "Completed"
                          ? "bg-green-100 text-green-800"
                          : log.statusByCall === "In Progress"
                          ? "bg-yellow-100 text-yellow-800"
                          : "bg-green-100 text-gray-800"
                      }`}
                    >
                      {log.statusByCall}
                    </span>
                  </td>
                  <td className="px-3 py-2">
                    <span
                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        log.statusAfterCall === "Completed"
                          ? "bg-green-100 text-green-800"
                          : log.statusAfterCall === "In Progress"
                          ? "bg-red-100 text-yellow-800"
                          : "bg-green-100 text-gray-800"
                      }`}
                    >
                      {log.statusAfterCall}
                    </span>
                  </td>
                  <td className="px-3 py-2 text-sm text-gray-900">
                    {log.dateTime ? (
                      <div className="w-[140px] break-words">
                        {formatDateTime(log.dateTime)}
                      </div>
                    ) : (
                      <span className="text-gray-400">No date set</span>
                    )}
                  </td>
                  <td className="px-3 py-2 text-sm text-gray-900">
                    {log.appraisal ? (
                      <div className="flex items-center space-x-1 w-[160px] break-words">
                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                          Appraised
                        </span>
                        <span className="text-gray-500 w-[150px] break-words">
                          by {log.appraisal.appraisedBy.userName}
                        </span>
                      </div>
                    ) : (
                      <div className="text-sm">
                        <Button
                          variant="primary"
                          size="small"
                          label="Appraise"
                          onClick={() => handleAppraise(log.id)}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
              {callLogs.length === 0 && (
                <tr>
                  <td
                    colSpan={6}
                    className="px-6 py-4 text-center text-sm text-gray-500"
                  >
                    No call logs found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="px-6 py-4 border-t border-gray-200">
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-700">
                Showing{" "}
                <span className="font-medium">
                  {(filters.page - 1) * filters.limit + 1}
                </span>{" "}
                to{" "}
                <span className="font-medium">
                  {Math.min(filters.page * filters.limit, totalResults)}
                </span>{" "}
                of <span className="font-medium">{totalResults}</span> results
              </div>
              <Pagination
                currentPage={filters.page}
                totalPages={Math.ceil(totalResults / filters.limit)}
                onPageChange={handlePageChange}
                data={{
                  totalResults,
                  currentPage: filters.page,
                  totalPages: Math.ceil(totalResults / filters.limit),
                }}
                prev={() => handlePageChange(filters.page - 1)}
                next={() => handlePageChange(filters.page + 1)}
              />
            </div>
          </div>
        </motion.div>
      )}

      <CallLogModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleAddCallLog}
        preselectedAccountId={migrationId}
        hideAccountSelection={!!migrationId}
      />

      <AppraisalModal
        isOpen={appraisalModal.isOpen}
        onClose={() => setAppraisalModal({ isOpen: false, callLogId: null })}
        onSubmit={handleSubmitAppraisal}
        callLogId={appraisalModal.callLogId || ""}
      />

      {expandedSummary.isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div
            className="fixed inset-0 bg-black bg-opacity-50"
            onClick={() => setExpandedSummary({ isOpen: false, text: "" })}
          />
          <div className="relative min-h-screen flex items-center justify-center p-4">
            <div className="relative bg-white rounded-lg max-w-2xl w-full p-6">
              <div className="flex justify-between items-start mb-4">
                <h3 className="text-lg font-semibold">Summary Details</h3>
                <button
                  onClick={() =>
                    setExpandedSummary({ isOpen: false, text: "" })
                  }
                  className="text-gray-400 hover:text-gray-500"
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="text-sm text-gray-900 whitespace-pre-wrap break-words">
                {expandedSummary.text}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add Toast component */}
      <Toast
        message={toast.message}
        type={toast.type}
        isVisible={toast.isVisible}
        onClose={() => setToast((prev) => ({ ...prev, isVisible: false }))}
      />
    </div>
  );
}
