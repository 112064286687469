import Pagination from "components/Pagination";
import Status from "components/Status";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { convertDate } from "utils/fx";
import { AnyObject, ContextType } from "utils/types";
import EmptyTable from "components/EmptyTable";
import visa from "assets/visa.svg";
import mastercard from "assets/mastercard.svg";
import { cards } from "utils/tableHeaders";

export default function Cards() {
  const navigate = useNavigate();
  const { data, next, prev } = useOutletContext<ContextType>();

  if (data?.cards?.length === 0) {
    return <EmptyTable />;
  }

  const getCardType = (card: AnyObject) => {
    if (card?.issuer === "VISA") {
      return (
        <>
          <img src={visa} alt="visa" />{" "}
          <span>{card?.maskedPan?.slice(-4)}</span>{" "}
        </>
      );
    }
    return (
      <>
        <img src={mastercard} alt="mastercard" />{" "}
        <span>{card?.maskedPan?.slice(-4)}</span>{" "}
      </>
    );
  };

  return (
    <>
      <div className="py-6 px-10 bg-white border border-btnInactive gap-4 grid grid-cols-[100px_1.5fr_1fr__1.2fr_150px_150px_1fr] items-center">
        {cards.map((header) => (
          <p key={header} className="text-sm font-bold text-blackDark">
            {header}
          </p>
        ))}
      </div>

      {data.cards?.map((card: AnyObject) => (
        <Link
          key={card?.id}
          to={`/cards/${card?.cardId}`}
          className="py-6 px-10 bg-white rounded-b border-b border-x border-btnInactive gap-4 grid grid-cols-[100px_1.5fr_1fr__1.2fr_150px_150px_1fr] items-center"
        >
          <Status width="w-full" status={card?.status?.toLowerCase()} />
          <div
            role="button"
            className="font-medium text-sm text-primary break-words underline"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/users/${card?.userId}`);
            }}
          >
            {card?.author?.firstName} {card?.author?.lastName}
          </div>
          <p className="font-normal text-sm text-blackDark">{card?.name}</p>
          <p className="font-normal text-sm text-blackDark">
            {card?.maskedPan}
          </p>
          <p className="font-normal text-sm text-blackDark flex items-center gap-2">
            {getCardType(card)}
          </p>

          <p className="font-normal text-sm text-blackDark">{card?.type}</p>
          <p className="font-normal text-sm text-blackDark">
            {convertDate(new Date(card?.createdAt))}
          </p>
        </Link>
      ))}
      <Pagination
        data={data as AnyObject}
        prev={prev as () => void}
        next={next as () => void}
      />
    </>
  );
}
