import Button from "components/Button";
import Input from "components/inputs/Input";
import { useFormik } from "formik";
import { useDocumentTitle } from "hooks/useDocument";
import { Fragment, useEffect } from "react";
import { object, string } from "yup";
import { useAuthHandlers } from "./useAuthHandlers";
import { toast } from "react-hot-toast";
import { AnyObject } from "utils/types";

export interface ForgotValues {
  email: string;
}
function ForgotPassword() {
  useDocumentTitle("Forgot Password");

  const {handleForgotPassword, isError, error, loading } = useAuthHandlers();
  useEffect(() => {
    if (isError) {
      toast.error(
        (error as AnyObject)?.message || "An error occurred"
      );
    }
  }, [error, isError]);

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
  } = useFormik<ForgotValues>({
    initialValues: {
      email: "",
    },
    validationSchema: object({
      email: string().email().required("Email is required"),
    }),
    onSubmit: async (values) => {
      await handleForgotPassword(values)
    },
  });

  return (
    <Fragment>
      <p className="text-blackDarkDark text-xl font-bold text-center mb-8">
        Forgot your password
      </p>

      <form noValidate onSubmit={handleSubmit}>
        <Input
          type="email"
          label="Enter your registered email address"
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          placeholder={"Email address"}
          error={`${!!errors["email"] && !!touched["email"]}`}
          errorMessage={errors["email"]}
          onBlur={handleBlur}
        />
        <p className="text-blackDark text-sm">
          Can’t access Email Address?{" "}
          <span className="text-primary">Contact Support</span>
        </p>
        <Button
          loading={loading}
          label="Submit"
          type="submit"
          disabled={values.email === ""}
        />
      </form>
    </Fragment>
  );
}

export default ForgotPassword;
