import close from "assets/close.svg";
import Button from "components/Button";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import { useFormik } from "formik";
import { object, string } from "yup";
import { useAdminHandlers, useFetchRoles } from "../useAdminHandlers";
import { AnyObject } from "utils/types";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

interface InviteAdminProps {
  onClose: () => void;
}

export interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  userType: string;
  dialCode: string;
  phoneNumber: string;
}
const InviteAdmin = ({ onClose }: InviteAdminProps) => {
  const { handleInviteAdmin, loading, isError, error } =
    useAdminHandlers(onClose);

  const { roles } = useFetchRoles();

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  const { values, handleChange, touched, errors, handleSubmit, handleBlur } =
    useFormik<FormValues>({
      initialValues: {
        email: "",
        firstName: "",
        lastName: "",
        role: "",
        userType: "admin",
        dialCode: "+234",
        phoneNumber: `${parseInt(Math.random().toFixed(10).replace("0.", ""))}`,
      },
      validationSchema: object({
        email: string()
          .email("Please enter a valid email")
          .required("Email is required"),
        firstName: string().required("First name is required"),
        lastName: string().required("Last name is required"),
        role: string()
          .oneOf(
            roles?.map((role: AnyObject) => role?.name) ?? [],
            `Role must be one of ${roles?.map((role: AnyObject) => role?.name)?.toString()}`
          )
          .required("Role is required"),
      }),
      onSubmit: (values) => {
        handleInviteAdmin(values);
      },
    });
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-40 rounded"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive mb-4">
        <p className="text-black text-base font-bold">Invite Administrator</p>
        <img role="button" src={close} alt="close" onClick={onClose} />
      </div>

      <div className="p-4 border-b  border-btnInactive">
        <Input
          type="email"
          label="Email"
          id="email"
          name="email"
          value={values.email}
          onChange={handleChange}
          placeholder={"Enter email"}
          error={`${!!errors["email"] && !!touched["email"]}`}
          errorMessage={errors["email"]}
          onBlur={handleBlur}
        />

        <div className="flex gap-4">
          <Input
            type="text"
            label="First Name"
            id="firstName"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            placeholder={"Enter first name"}
            error={`${!!errors["firstName"] && !!touched["firstName"]}`}
            errorMessage={errors["firstName"]}
            onBlur={handleBlur}
          />
          <Input
            type="text"
            label="Last Name"
            id="lastName"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            placeholder={"Enter last name"}
            error={`${!!errors["lastName"] && !!touched["lastName"]}`}
            errorMessage={errors["lastName"]}
            onBlur={handleBlur}
          />
        </div>

        <Select
          label="Role"
          id="role"
          name="role"
          value={values.role}
          onChange={handleChange}
          placeholder={"Select role"}
          error={`${!!errors["role"] && !!touched["role"]}`}
          errorMessage={errors["role"]}
          data={roles?.map((role: AnyObject) => ({label: role.name, value: role.name})) ?? []}
          onBlur={handleBlur}
        />
      </div>

      <div className="p-4 flex justify-between items-center">
        <Button
          variant="neutral"
          size="small"
          label="Cancel"
          onClick={onClose}
        />
        <Button label="Invite" size="small" type="submit" loading={loading} />
      </div>
    </form>
  );
};

export default InviteAdmin;
