import React, { useEffect, useState } from 'react';
import { VectorMap } from '@react-jvectormap/core';
import { worldMill } from '@react-jvectormap/world';
import { AnyObject } from 'utils/types';

// type IpLocation = {
//   lat: number;
//   lng: number;
//   count: number;
// };

type Props = {
  data: AnyObject[];
};

// Define the jQuery-like element interface
interface JQueryElement {
  html(): string;
  html(content: string): JQueryElement;
}

// Mapping of common IP ranges to countries (simplified for demo)
const ipToCountryMap: { [key: string]: string } = {
  '172.31': 'NG', // Nigeria
  '192.168': 'NG',
  '10.0': 'NG',
};

const IpLocationMap: React.FC<Props> = ({ data }) => {
  const [locations, setLocations] = useState<{ [key: string]: number }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocations = async () => {
      const locationCounts: { [key: string]: number } = {};
      
      for (const item of data) {
        if (item.ipAddress) {
          try {
            // First try to map IP using our predefined ranges
            const ipPrefix = item.ipAddress.split('.').slice(0, 2).join('.');
            if (ipToCountryMap[ipPrefix]) {
              const countryCode = ipToCountryMap[ipPrefix];
              locationCounts[countryCode] = (locationCounts[countryCode] || 0) + 1;
              continue;
            }

            // If not in our predefined map, try the API with CORS proxy
            const response = await fetch(`https://api.dev.blumefy.com/migration/v1/ip-lookup/${item.ipAddress}`, {
              method: 'GET',
              headers: {
                'Accept': 'application/json',
              },
            });

            if (!response.ok) {
              throw new Error('IP lookup failed');
            }

            const locationData = await response.json();
            
            if (locationData.country_code) {
              locationCounts[locationData.country_code] = (locationCounts[locationData.country_code] || 0) + 1;
            }
          } catch (error) {
            console.warn('IP location lookup failed, using default:', error);
            // Default to Nigeria if lookup fails
            locationCounts['NG'] = (locationCounts['NG'] || 0) + 1;
          }
        }
      }
      
      // Ensure we have at least one location for visualization
      if (Object.keys(locationCounts).length === 0) {
        locationCounts['NG'] = 1;
      }
      
      setLocations(locationCounts);
      setLoading(false);
    };

    fetchLocations();
  }, [data]);

  if (loading) {
    return (
      <div className="h-[300px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className="map-container" style={{ width: '100%', height: '400px', position: 'relative' }}>
      <VectorMap
        map={worldMill}
        style={{
          width: '100%',
          height: '100%'
        }}
        regionStyle={{
          initial: {
            fill: '#e4e4e4',
            fillOpacity: 0.9,
            stroke: 'none',
            strokeWidth: 0,
            strokeOpacity: 0
          },
          hover: {
            fillOpacity: 0.8,
            cursor: 'pointer'
          },
          selected: {
            fill: '#2938bc'
          },
          selectedHover: {}
        }}
        series={{
          regions: [{
            values: locations,
            scale: ['#C8EEFF', '#0071A4'],
            normalizeFunction: 'polynomial',
            attribute: 'fill'
          }]
        }}
        onRegionTipShow={(e, element, code) => {
          const el = element as unknown as JQueryElement;
          const currentContent = el.html();
          el.html(`${currentContent} (Total Users: ${locations[code] || 0})`);
        }}
      />
    </div>
  );
};

export default IpLocationMap; 