import { CSVLink } from "react-csv";

const CsvButton = ({
  data = [],
  filename,
}: {
  data: Array<Array<string | number | boolean>>;
  filename: string;
}) => {
  return (
    <CSVLink
      data={data}
      filename={filename}
      className={`relative disabled:bg-btnInactive disabled:cursor-not-allowed bg-primary text-center text-white disabled:text-formInactive rounded text-xs font-bold w-1/2 p-3 my-4`}
    >
      Download CSV
    </CSVLink>
  );
};

export default CsvButton;
