import close from "assets/close.svg";

import { useMigrationActions } from "../useMigrationHandlers";
import { AnyObject } from "utils/types";
import Button from "components/Button";
import { countries } from "utils/countries";
import Select from "components/inputs/Select";
import { ChangeEvent, FormEvent, useState } from "react";

interface RecommendationProps {
  onClose: () => void;
  data: AnyObject;
}

const pathways = [
  { label: "Study", value: "Study" },
  { label: "Work", value: "Work" },
  { label: "Permanent Residency", value: "Permanent Residency" },
];

const Recommendation = ({ onClose, data }: RecommendationProps) => {
  const {handleRecommendation, loading } = useMigrationActions(onClose);

  const [fields, setFields] = useState([
    {
      country: "",
      pathway: "",
    },
  ]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>, i: number) => {
    const { name, value } = e.target;
    setFields((prev) => {
      const newOpts = [...prev];
      (newOpts[i] as AnyObject)[name] = value;
      return newOpts;
    });
  };
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload = {
        recommendations: fields,
        leadId: data.id,
    }
    handleRecommendation(payload);
  };

  const addMore = () => {
    const currArr = [...fields];
    currArr.push({ country: "", pathway: "" });
    setFields(currArr);
  };

  const removeItem = (i: number) => {
    const currOpts = [...fields];
    const newOpts = currOpts.filter((_, idx) => idx !== i);
    setFields(newOpts);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white w-[450px] m-auto mt-20 rounded-lg overflow-hidden"
    >
      <div className="flex justify-between items-center p-4 border-b border-btnInactive">
        <p className="text-black text-lg font-semibold">Set Recommendations</p>
        <img 
          role="button" 
          src={close} 
          alt="close" 
          onClick={onClose}
          className="cursor-pointer hover:opacity-70 transition-opacity" 
        />
      </div>

      <div className="p-6 border-b border-btnInactive max-h-[60vh] overflow-y-auto">
        {fields.map((field, i) => (
          <div key={i} className="mb-6 last:mb-4">
            <div className="flex justify-between items-start gap-4">
              <Select
                label="Select Country"
                id="country"
                name="country"
                value={field.country}
                onChange={(e) => handleChange(e, i)}
                placeholder="Select country"
                data={countries.map((country) => ({
                  label: country,
                  value: country,
                }))}
              />

              <Select
                label="Add Pathway"
                id="pathway"
                name="pathway"
                value={field.pathway}
                onChange={(e) => handleChange(e, i)}
                placeholder="Select pathway"
                data={pathways}
              />
            </div>
            {fields.length > 1 && (
              <div className="flex justify-end mt-2">
                <button
                  type="button"
                  onClick={() => removeItem(i)}
                  className="text-xs text-danger hover:text-red-700 transition-colors"
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        ))}

        <div className="flex justify-center mt-4">
          <Button
            onClick={addMore}
            label="Add another recommendation"
            size="small"
            variant="other"
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
            }
          />
        </div>
      </div>

      <div className="p-4 flex justify-center items-center gap-4 bg-gray-50">
        <div className="w-[100px]">
          <Button
            variant="neutral"
            size="small"
            label="Cancel"
            onClick={onClose}
          />
        </div>
        <div className="w-[180px]">
          <Button 
            label="Give Recommendation" 
            size="small" 
            type="submit" 
            loading={loading}
            disabled={fields.some(field => !field.country || !field.pathway)}
          />
        </div>
      </div>
    </form>
  );
};

export default Recommendation;
