import Status from "components/Status";
import { useDocumentTitle } from "hooks/useDocument";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchCard } from "./useCardHandlers";
import Loader from "components/Loader";
import chip from "assets/chip.svg";
import logo from "assets/card-logo.svg";
import mastercard from "assets/mastercard.svg";
import visa from "assets/visa.svg";
import Button from "components/Button";
import Switch from "components/inputs/Switch";
import { useEffect, useState } from "react";
import { formatNumber } from "utils/fx";
import Modal from "components/Modal";
import Terminate from "./modals/Terminate";
import Freeze from "./modals/Freeze";
import back from "assets/back.svg";
import CardTransactions from "./CardTransactions";
import { useAuthContext } from "hooks/useAuthContext";

const cardType: { [key: string]: string } = {
  VISA: visa,
  MASTERCARD: mastercard,
};

export default function CardDetails() {
  useDocumentTitle("Card Details");

  const navigate = useNavigate();

  const [type, setType] = useState<"terminate" | "freeze" | undefined>(
    undefined
  );

  const { id } = useParams();
  const {user } = useAuthContext()

  const canViewCards = user?.permissions?.includes('view:cards')
 

  const { data, isLoading } = useFetchCard(id ?? "");

  useEffect(() => {
    if(!canViewCards && !isLoading) {
      navigate('/home')
    }
  }, [canViewCards, isLoading, navigate])

  const onClose = () => setType(undefined);

  if (isLoading) return <Loader />;

  const card = data?.cards?.[0];

  return (
    <>
      <div
        className="pt-2 mb-4 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate(-1)}
      >
        <img src={back} alt="back" />
        Cards
      </div>
      <Status status={card.status.toLowerCase()} width="w-28" />
      <div className="bg-white rounded p-5 border border-btnInactive mb-4 shadow-sm mt-4">
        <div className="border-b border-grey pb-3 mb-4">
          <p className="text-blackDark text-base font-medium">{card.name}</p>
        </div>

        <div className="flex items-center justify-between">
          <div
            style={{ background: card.cardColor }}
            className="rounded-xl w-[250px] px-4 py-2"
          >
            <small className="text-white text-xs mb-3">Available Balance</small>
            <p className="text-white text-sm font-semibold">
              {formatNumber(card.balance / 100, card.currency)}
            </p>
            <div className="flex items-center justify-between gap-2 mt-3">
              <img className="w-[60px]" src={chip} alt="chip" />
              <div className="flex items-center">
                <img className="w-[20px]" src={logo} alt="card logo" />
                <span className="text-xs text-white font-medium">BLUMEFY</span>
              </div>
            </div>
            <div className="flex justify-end">
              <img
                className="w-[60px]"
                src={cardType[card.issuer]}
                alt={card.issuer}
              />
            </div>
          </div>

          <div className="grid grid-cols-[1fr_150px_150px_150px] gap-8">
            <div className="flex flex-col gap-4">
              <div>
                <p className="text-coolGrey text-sm mb-2">Card Number</p>
                <span className="text-blackDark text-sm font-bold flex items-center gap-2">
                  {card.maskedPan}
                  
                </span>
              </div>
              <div>
                <p className="text-coolGrey text-sm mb-2">CVV</p>
                <p className="text-blackDark text-sm font-bold">{card.cvv}</p>
              </div>
              <div>
                <p className="text-coolGrey text-sm mb-2">Expiry</p>
                <p className="text-blackDark text-sm font-bold">
                  {card.expirationDate}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div>
                <div>
                  <p className="text-coolGrey text-sm mb-2">Billing Address</p>
                  <p className="text-blackDark text-sm font-bold break-words">
                    {card.billingAddress.street}
                  </p>
                </div>
              </div>
              <div>
                <p className="text-coolGrey text-sm mb-2">City/Region</p>
                <p className="text-blackDark text-sm font-bold break-words">
                  {card.billingAddress.city}, {card.billingAddress.state}
                </p>
              </div>
              <div>
                <p className="text-coolGrey text-sm mb-2">Zip Code</p>
                <p className="text-blackDark text-sm font-bold"></p>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              <div>
                <p className="text-coolGrey text-sm mb-2">Card Status</p>
                <p className="text-blackDark text-sm font-bold">
                  {card.status}
                </p>
              </div>
              <div>
                <p className="text-coolGrey text-sm mb-2">Card Scheme</p>
                <p className="text-blackDark text-sm font-bold">
                  {card.issuer}
                </p>
              </div>
              <div>
                <p className="text-coolGrey text-sm mb-2">Account Type</p>
                <p className="text-blackDark text-sm font-bold">{card.type}</p>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              {card.status.toLowerCase() !== "terminated" && (
                <div
                  role="button"
                  className="border border-primary py-2 px-2 rounded flex items-center gap-2 cursor-pointer"
                >
                  <span className="text-primary text-xs font-bold">
                    {card.status.toLowerCase() === "disabled"
                      ? "Unfreeze"
                      : "Freeze"}{" "}
                    Card
                  </span>
                  <Switch
                    checked={card.status.toLowerCase() === "disabled"}
                    onChange={() => setType("freeze")}
                  />
                </div>
              )}
              {card.status.toLowerCase() !== "terminated" && (
                <Button
                  label="Terminate Card"
                  variant="danger"
                  onClick={() => setType("terminate")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal isShown={["terminate", "freeze"].includes(type as string)}>
        {type === "terminate" && (
          <Terminate
            onClose={onClose}
            cardId={card.id}
            userId={card.userId}
            queryId={id as string}
          />
        )}
        {type === "freeze" && (
          <Freeze
            onClose={onClose}
            cardId={card.id}
            status={card.status.toLowerCase()}
            userId={card.userId}
            queryId={id as string}
          />
        )}
      </Modal>

      <>
        <p className="py-2 text-greyDark font-bold"> Card Transactions</p>
        <CardTransactions />
      </>
    </>
  );
}
