import { ReactNode, createContext, useEffect, useReducer } from "react";

export const AuthContext = createContext<{[key: string]: any}>({});

export const authReducer = (state: any, action: any) => {

  switch (action.type) {
    case "LOGIN":
      return { user: action.payload.user };

    case "LOGOUT":
      return { user: {} };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }: {children: ReactNode}) => {
  const [state, dispatch] = useReducer(authReducer, { user: {} });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    if(user) {
        dispatch({type: "LOGIN", payload: user})
    }
  }, [])
  

  return (
    <AuthContext.Provider value={{...state, dispatch}}>
        {children}
    </AuthContext.Provider>
  )
};