import { convertDate, convertTime } from "utils/fx";
import { AnyObject } from "utils/types";

const TimelineItem = ({
  date,
  time,
  event,
}: {
  date: string;
  time: string;
  event: string;
}) => (
  <div className="flex flex-col items-center w-32">
    <div className="mb-2">
      <p className="text-sm font-semibold text-center">{event}</p>
      <p className="text-xs text-black text-center">{date}</p>
      <p className="text-xs text-black text-center">{time}</p>
    </div>
    <div className="w-3 h-3 bg-primary rounded-full z-10"></div>
  </div>
);

const Timeline = ({ data }: { data: AnyObject }) => {
  if (data.length < 1) {
    return (
      <div className="w-full max-w-[70rem] p-4">
        <p className="text-black">No timeline for this migration.</p>
      </div>
    );
  }

  const timelineItems = data.map((item: AnyObject) => ({
    event: item.description,
    date: convertDate(new Date(item.date)),
    time: convertTime(new Date(item.date)),
  }));

  return (
    <div className="w-full max-w-[70rem] p-4">
      <div className="relative overflow-x-auto">
        <div className="flex items-end space-x-12 pb-4">
          {timelineItems.map((item: AnyObject, index: number) => (
            <TimelineItem
              key={index}
              date={item.date}
              time={item.time}
              event={item.event}
            />
          ))}
        </div>
        <div
          className="absolute left-[1.8rem] bottom-5 h-0.5 bg-primary"
          style={{ width: `${timelineItems.length * 12}%` }}
        />
      </div>
    </div>
  );
};

export default Timeline;
