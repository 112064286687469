import { Link, useNavigate } from "react-router-dom";
import {
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  useFetchMigrations,
  useStatusMigrations,
} from "./useMigrationHandlers";
import { motion, AnimatePresence } from "framer-motion";
import filter from "assets/filter.svg";
import Input from "components/inputs/Input";
import Select from "components/inputs/Select";
import DateRangeFilter from "components/inputs/DateRangeFilter";
import { AnyObject } from "utils/types";
import Modal from "components/Modal";
import Filter from "./modal/Filter";
import Button from "components/Button";
import { convertDate } from "utils/fx";
import { useDebounce } from "hooks/useDebounce";
import Pagination from "components/Pagination";
import { exportToExcel } from "utils/exportToExcel";
import DashboardTour from "./components/DashboardTour";
import MigrationInsights from "./components/MigrationInsights";
import SortButton from "components/SortButton";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  RadialLinearScale,
  LineElement,
} from "chart.js";
import CallLogs from "./components/CallLogs";
import { isSameDay, isSameMonth } from "date-fns";
import { useAuthContext } from "hooks/useAuthContext";

// Register Chart.js components
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  RadialLinearScale,
  LineElement,
  Tooltip,
  Legend
);

export type DateFilter = {
  dateFrom?: string;
  dateTo?: string;
};

// Add loading component
const LoadingCard = () => (
  <div className="bg-white/95 backdrop-blur-sm p-4 rounded-lg shadow-sm border border-btnInactive animate-pulse">
    <div className="flex items-center gap-3">
      <div className="w-10 h-10 rounded-full bg-gray-200" />
      <div className="space-y-2">
        <div className="h-4 w-24 bg-gray-200 rounded" />
        <div className="h-6 w-16 bg-gray-200 rounded" />
      </div>
    </div>
  </div>
);

const LoadingTable = () => (
  <div className="bg-white/95 backdrop-blur-sm rounded-lg shadow-sm border border-btnInactive p-4 animate-pulse">
    <div className="space-y-4">
      <div className="h-8 bg-gray-200 rounded w-1/4" />
      <div className="space-y-3">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="h-12 bg-gray-200 rounded" />
        ))}
      </div>
    </div>
  </div>
);

interface SortConfig {
  field: string;
  order: "asc" | "desc";
}

// Define the Lead interface
interface Lead {
  id: string;
  name: string;
  email: string;
  createdAt: string; // Assuming createdAt is a string, adjust if it's a Date object
  updatedAt: string;
  status: string;
  assignedTo?: {
    userName: string;
    email: string;
  };
  consultant?: {
    userName: string;
    email: string;
  };
  accountManager?: {
    userName: string;
    email: string;
  };
  timeline?: Array<{
    status: string;
    date: string;
    description: string;
    author?: {
      userName: string;
      email: string;
    };
  }>;
  // Add other properties as needed
}

// Add this type definition
type AssignedTabSection = "assigned-to-me" | "assigned-by-me";

export default function Migrations() {
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [assignedToFilter, setAssignedToFilter] = useState<string>("");
  const [page, setPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState("");
  const navigate = useNavigate();
  const [fetchedStatuses, setFetchedStatuses] = useState<Set<string>>(
    new Set()
  );
  const isInitialMount = useRef(true);
  const [pageSize, setPageSize] = useState<number>(100);
  const [consultant, setConsultant] = useState<string>("");
  const [accountManager, setAccountManager] = useState<string>("");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isTableRefreshing, setIsTableRefreshing] = useState(false);
  const [showTour, setShowTour] = useState(() => {
    const hasSeenTour = localStorage.getItem("migration-dashboard-tour-seen");
    return !hasSeenTour;
  });
  const [showInsights, setShowInsights] = useState(false);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    field: "createdAt",
    order: "desc",
  });
  const [tab, setTab] = useState<
    "details" | "comments" | "account" | "call-logs" | "metrics" | "assigned"
  >("details");
  const [, setSelectedMigrationId] = useState<string | undefined>();
  const [assignedTabSection, setAssignedTabSection] =
    useState<AssignedTabSection>("assigned-to-me");
  const { user: authUser } = useAuthContext();

  const toggleInsights = useCallback(() => {
    setShowInsights((prev) => !prev);
  }, []);

  const buildQueryString = () => {
    const params = new URLSearchParams();

    if (consultant) {
      params.append("consultant", consultant);
    }

    if (assignedToFilter) {
      params.append("assignLead", assignedToFilter);
    }

    if (accountManager) {
      params.append("accountManager", accountManager);
    }

    if (startDate) {
      params.append("dateFrom", startDate.toISOString());
    }

    if (endDate) {
      params.append("dateTo", endDate.toISOString());
    }

    return params.toString();
  };

  const handleSort = (field: string) => {
    setSortConfig((prev) => ({
      field,
      order: prev.field === field && prev.order === "desc" ? "asc" : "desc",
    }));
  };

  const { data, isLoading, error } = useFetchMigrations({
    page,
    searchTerm: useDebounce(searchTerm, 5000),
    status: filterTerm,
    query: buildQueryString(),
    pageSize,
    sortBy: `${sortConfig.order === "desc" ? "-" : ""}${sortConfig.field}`,
  });

  const summaryQueryString = useMemo(() => {
    const params = new URLSearchParams();

    if (searchTerm) {
      params.append("searchTerm", searchTerm);
    }

    if (assignedToFilter) {
      params.append("assignLead", assignedToFilter);
    }

    if (accountManager) {
      params.append("accountManager", accountManager);
    }

    if (startDate && endDate) {
      params.append("dateFrom", startDate.toISOString());
      params.append("dateTo", endDate.toISOString());
    }

    return params.toString();
  }, [searchTerm, assignedToFilter, accountManager, startDate, endDate]);

  const incompleteQuery = useStatusMigrations(
    "Incomplete",
    !fetchedStatuses.has("Incomplete"),
    summaryQueryString
  );

  const subscribedQuery = useStatusMigrations(
    "Subscribe to service",
    !fetchedStatuses.has("Subscribe to service"),
    summaryQueryString
  );

  const notReadyQuery = useStatusMigrations(
    "Customer Not Ready",
    !fetchedStatuses.has("Customer Not Ready"),
    summaryQueryString
  );

  useEffect(() => {
    setFetchedStatuses(new Set());
  }, [summaryQueryString]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    const newFetchedStatuses = new Set(fetchedStatuses);

    if (incompleteQuery.data && !newFetchedStatuses.has("Incomplete")) {
      newFetchedStatuses.add("Incomplete");
    }
    if (
      subscribedQuery.data &&
      !newFetchedStatuses.has("Subscribe to service")
    ) {
      newFetchedStatuses.add("Subscribe to service");
    }
    if (notReadyQuery.data && !newFetchedStatuses.has("Customer Not Ready")) {
      newFetchedStatuses.add("Customer Not Ready");
    }

    if (newFetchedStatuses.size !== fetchedStatuses.size) {
      setFetchedStatuses(newFetchedStatuses);
    }
  }, [
    incompleteQuery.data,
    subscribedQuery.data,
    notReadyQuery.data,
    fetchedStatuses,
  ]);

  useEffect(() => {
    if (data?.results?.length > 0) {
      setSelectedMigrationId(data.results[0].id);
    }
  }, [data?.results]);

  // Filter data based on date range and assigned to
  const filteredData = {
    ...data,
    results: data?.results?.filter((item: AnyObject) => {
      const dateCondition =
        startDate && endDate
          ? new Date(item.createdAt) >= startDate &&
            new Date(item.createdAt) <= endDate
          : true;

      const assignedCondition = assignedToFilter
        ? item?.assignedTo?.userName === assignedToFilter
        : true;

      return dateCondition && assignedCondition;
    }),
  };

  // Get unique assignees and ensure they're strings
  const uniqueAssignees: string[] = Array.from(
    new Set<string>(
      data?.results
        ?.map((item: AnyObject) => item?.assignedTo?.userName)
        .filter(
          (name: unknown): name is string =>
            typeof name === "string" && name.length > 0
        ) || []
    )
  );

  // Add uniqueConsultants similar to uniqueAssignees
  const uniqueConsultants: string[] = Array.from(
    new Set<string>(
      data?.results
        ?.map((item: AnyObject) => item?.consultant?.userName)
        .filter(
          (name: unknown): name is string =>
            typeof name === "string" && name.length > 0
        ) || []
    )
  );

  // Add uniqueAccountManagers similar to uniqueConsultants
  const uniqueAccountManagers: string[] = Array.from(
    new Set<string>(
      data?.results
        ?.map((item: AnyObject) => item?.accountManager?.userName)
        .filter(
          (name: unknown): name is string =>
            typeof name === "string" && name.length > 0
        ) || []
    )
  );

  const showModal = () => setShow(true);

  const handleFilterChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilterTerm(e.target.value);
    setShow(false);
  };

  const next = () => {
    if (page < Math.ceil((data?.totalResults || 0) / pageSize)) {
      setPage((prev) => prev + 1);
    }
  };

  const prev = () => {
    if (page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  const goToPage = (pageNumber: number) => {
    const totalPages = Math.ceil((data?.totalResults || 0) / pageSize);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setPage(pageNumber);
    }
  };

  const handlePageSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(e.target.value));
    setPage(1); // Reset to first page when changing page size
  };

  const handleRefresh = useCallback(async () => {
    setIsRefreshing(true);
    setIsTableRefreshing(true);

    // Reset all queries to trigger refetch
    setFetchedStatuses(new Set());

    // Force refetch by changing the page
    setPage((curr) => curr);

    // Add a minimum delay for the animation
    await new Promise((resolve) => setTimeout(resolve, 1000));

    setIsRefreshing(false);

    // Keep table refresh animation a bit longer
    setTimeout(() => {
      setIsTableRefreshing(false);
    }, 500);
  }, []);

  // Add these filter handler functions near the top of the component
  const handleMetricFilter = (
    status: string | null = null,
    dateRange: { start: Date | null; end: Date | null } | null = null
  ) => {
    if (status) {
      setFilterTerm(status);
      setShow(false);
    }
    if (dateRange) {
      setStartDate(dateRange.start);
      setEndDate(dateRange.end);
    }
  };

  const handleCloseTour = () => {
    setShowTour(false);
    localStorage.setItem("migration-dashboard-tour-seen", "true");
  };

  const handleDateChange = (start: Date | null, end: Date | null) => {
    setStartDate(start);
    setEndDate(end);
    setPage(1); // Reset to first page when date range changes
  };

  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to the start of the day

  // Calculate metrics for total leads and subscribed leads
  const currentMonth = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(currentMonth.getMonth() - 1);

  const totalLeadsCurrentMonth =
    data?.results?.filter((lead: Lead) => {
      const leadDate = new Date(lead.createdAt);
      return isSameMonth(leadDate, currentMonth);
    }).length || 0;

  const totalLeadsLastMonth =
    data?.results?.filter((lead: Lead) => {
      const leadDate = new Date(lead.createdAt);
      return isSameMonth(leadDate, lastMonth);
    }).length || 0;

  const isSubscribedToday = (lead: Lead) => {
    return lead.timeline?.some((entry) => {
      const entryDate = new Date(entry.date);
      return (
        entry.status === "Subscribe to service" && isSameDay(entryDate, today)
      );
    });
  };

  const isSubscribedMonth = (lead: Lead) => {
    return lead.timeline?.some((entry) => {
      const entryDate = new Date(entry.date);
      return (
        entry.status === "Subscribe to service" &&
        isSameMonth(entryDate, currentMonth)
      );
    });
  };

  const subscribedLeadsCurrentMonth =
    data?.results?.filter((lead: Lead) => {
      const leadDate = new Date(lead.createdAt);
      return isSameMonth(leadDate, currentMonth) && isSubscribedMonth(lead);
    }).length || 0;

  const subscribedLeadsLastMonth =
    data?.results?.filter((lead: Lead) => {
      const leadDate = new Date(lead.createdAt);
      return isSameMonth(leadDate, lastMonth) && isSubscribedToday(lead);
    }).length || 0;

  const totalLeadsChange =
    totalLeadsLastMonth > 0
      ? ((totalLeadsCurrentMonth - totalLeadsLastMonth) / totalLeadsLastMonth) *
        100
      : 0;
  const subscribedLeadsChange =
    subscribedLeadsLastMonth > 0
      ? ((subscribedLeadsCurrentMonth - subscribedLeadsLastMonth) /
          subscribedLeadsLastMonth) *
        100
      : 0;

  const countPrivateConsultationsThisMonth = (leads: Lead[]) => {
    const currentMonth = new Date();
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of the day

    const totalThisMonth = leads.reduce((count, lead) => {
      return (
        count +
        (lead.timeline?.filter((entry) => {
          const entryDate = new Date(entry.date);
          return (
            entry.status === "Scheduled appointment with a consultant" &&
            isSameMonth(entryDate, currentMonth)
          );
        }).length || 0)
      );
    }, 0);

    const totalToday = leads.reduce((count, lead) => {
      return (
        count +
        (lead.timeline?.filter((entry) => {
          const entryDate = new Date(entry.date);
          return (
            entry.status === "Scheduled appointment with a consultant" &&
            isSameDay(entryDate, today)
          );
        }).length || 0)
      );
    }, 0);

    return { totalThisMonth, totalToday };
  };

  const { totalThisMonth, totalToday } = countPrivateConsultationsThisMonth(
    data?.results || []
  );

  const countGroupConsultationsThisMonth = (leads: Lead[]) => {
    const currentMonth = new Date();
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set to the start of the day

    const totalThisMonth = leads.reduce((count, lead) => {
      return (
        count +
        (lead.timeline?.filter((entry) => {
          const entryDate = new Date(entry.date);
          return (
            entry.status === "Group Consultation Done" &&
            isSameMonth(entryDate, currentMonth)
          );
        }).length || 0)
      );
    }, 0);

    return totalThisMonth;
  };

  const groupConsultationsThisMonth = countGroupConsultationsThisMonth(
    data?.results || []
  );

  // Add this helper function inside the component
  const filterAssignedLeads = (leads: Lead[], section: AssignedTabSection) => {
    if (!authUser?.email) return [];

    return leads?.filter((lead: Lead) => {
      if (section === "assigned-to-me") {
        return (
          lead.assignedTo?.email === authUser.email ||
          lead.consultant?.email === authUser.email ||
          lead.accountManager?.email === authUser.email
        );
      } else {
        return lead.timeline?.some(
          (entry) =>
            entry.status.includes("Assigned") &&
            entry.author?.email === authUser.email
        );
      }
    });
  };

  if (error)
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="p-6 text-danger text-center"
      >
        Error loading migrations
      </motion.div>
    );

  if (isLoading)
    return (
      <div className="p-6 space-y-8">
        {/* Loading Summary Cards */}
        <div className="grid grid-cols-4 gap-4">
          {[...Array(4)].map((_, i) => (
            <LoadingCard key={i} />
          ))}
        </div>

        {/* Loading Table */}
        <LoadingTable />
      </div>
    );

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="relative p-2 min-h-screen overflow-hidden"
    >
      {/* Background */}
      <div className="absolute inset-0 -z-10">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0 bg-gradient-to-br from-gray-50 to-white"
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.02 }}
          className="absolute inset-0 bg-[radial-gradient(#3B82F6_1px,transparent_1px)] [background-size:16px_16px]"
        />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: [0.05, 0.1, 0.05] }}
          transition={{ duration: 5, repeat: Infinity, ease: "easeInOut" }}
          className="absolute inset-0 bg-[linear-gradient(45deg,transparent_25%,#3B82F6_25%,#3B82F6_50%,transparent_50%,transparent_75%,#3B82F6_75%)] bg-[length:64px_64px]"
        />
      </div>

      <div className="relative z-10">
        {/* Header with animated entrance */}
        <motion.div
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="flex items-center justify-between mb-6"
        >
          <h1 className="text-2xl font-bold text-coolGrey">Migrations</h1>
          <div className="flex items-center gap-3">
            <Button
              variant="primary"
              size="small"
              label="Export to Excel"
              onClick={() =>
                exportToExcel(filteredData?.results || [], "migrations")
              }
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              }
            />
            <motion.button
              data-tour="refresh-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleRefresh}
              disabled={isRefreshing}
              className={`p-2 rounded-full bg-white shadow hover:shadow-md transition-all duration-300 text-primary disabled:opacity-50 disabled:cursor-not-allowed ${
                isRefreshing ? "opacity-75 cursor-not-allowed" : ""
              }`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 transition-transform duration-700 ease-in-out ${
                  isRefreshing ? "animate-spin" : ""
                }`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </motion.button>
            <Button
              variant={showInsights ? "secondary" : "primary"}
              size="small"
              label={showInsights ? "Hide Insights" : "Show Insights"}
              onClick={toggleInsights}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zM21 19v-6a2 2 0 00-2-2h-2a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2z"
                  />
                </svg>
              }
            />
            <Button
              variant="secondary"
              size="small"
              label="Show Tour"
              onClick={() => setShowTour(true)}
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              }
            />
          </div>
        </motion.div>

        {/* Summary Cards with staggered animation */}
        <div data-tour="summary-cards" className="grid grid-cols-4 gap-4 mb-8">
          {[
            {
              title: "Incomplete Forms",
              query: incompleteQuery,
              status: "Incomplete",
              color: "red",
            },
            {
              title: "Subscribed Users",
              query: subscribedQuery,
              status: "Subscribe to service",
              color: "green",
            },
            {
              title: "Not Ready",
              query: notReadyQuery,
              status: "Customer Not Ready",
              color: "yellow",
            },
            {
              title: "Total Migrations",
              total: data?.totalResults || 0,
              color: "blue",
            },
          ].map((card, index) => (
            <motion.div
              key={card.title}
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.02 }}
              className={`bg-white/95 backdrop-blur-sm p-4 rounded-lg shadow-sm border border-btnInactive cursor-pointer relative group`}
              onClick={() =>
                card.status
                  ? handleMetricFilter(card.status)
                  : handleMetricFilter("")
              }
            >
              <div
                className={`absolute inset-0 bg-${card.color}-50 opacity-0 group-hover:opacity-100 transition-opacity rounded-lg`}
              />
              <div className="relative flex items-center gap-3">
                <div className={`p-2 bg-${card.color}-100 rounded-full`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-4 w-4 text-${card.color}-500`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div>
                  <h3 className="text-xs text-coolGrey">{card.title}</h3>
                  <p
                    className={`text-lg font-bold text-${card.color}-500 mt-1`}
                  >
                    {"total" in card
                      ? card.total
                      : card.query?.isLoading
                      ? "..."
                      : card.query?.data?.totalResults || 0}
                  </p>
                </div>
                <div className="absolute right-2 top-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`h-4 w-4 text-${card.color}-500`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Filters Section */}
        <div
          data-tour="search-filter"
          className="relative z-20 bg-white/95 backdrop-blur-sm rounded-lg shadow-sm border border-btnInactive p-6 mb-8"
        >
          <div className="grid grid-cols-3 gap-6 mb-6">
            {/* Search */}
            <div>
              <label className="text-sm text-coolGrey mb-2 block">Search</label>
              <Input
                type="text"
                placeholder="Search migrations..."
                value={searchTerm}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchTerm(e.target.value)
                }
                id="migration-search"
                name="migration-search"
                className="w-full"
              />
            </div>

            {/* Assigned To */}
            <div>
              <label className="text-sm text-coolGrey mb-2 block">
                Assigned To
              </label>
              <Select
                id="assignedTo"
                name="assignedTo"
                value={assignedToFilter}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setAssignedToFilter(e.target.value)
                }
                placeholder="Filter by assignee..."
                showClear={!!assignedToFilter}
                onClear={() => setAssignedToFilter("")}
                data={uniqueAssignees
                  .sort((a: string, b: string) => a.localeCompare(b))
                  .map((name: string) => ({
                    label: name,
                    value: name,
                  }))}
              />
            </div>

            {/* Status */}
            <div>
              <label className="text-sm text-coolGrey mb-2 block">Status</label>
              <button
                onClick={showModal}
                className="w-full flex items-center justify-between p-2 border border-btnInactive rounded hover:bg-gray-50 bg-white"
              >
                <span className="text-sm text-coolGrey">
                  {filterTerm || "All Statuses"}
                </span>
                <img src={filter} alt="filter" className="w-4 h-4" />
              </button>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-6">
            <div data-tour="date-range">
              <label className="text-sm text-coolGrey mb-2 block">
                Date Range
              </label>
              <DateRangeFilter
                startDate={startDate}
                endDate={endDate}
                onDateChange={handleDateChange}
              />
            </div>

            {/* Account Manager */}
            <div>
              <label className="text-sm text-coolGrey mb-2 block">
                Account Manager
              </label>
              <Select
                id="accountManager"
                name="accountManager"
                value={accountManager}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setAccountManager(e.target.value)
                }
                placeholder="Filter by account manager..."
                showClear={!!accountManager}
                onClear={() => setAccountManager("")}
                data={uniqueAccountManagers
                  .sort((a: string, b: string) => a.localeCompare(b))
                  .map((name: string) => ({
                    label: name,
                    value: name,
                  }))}
              />
            </div>

            {/* Consultant */}
            <div>
              <label className="text-sm text-coolGrey mb-2 block">
                Consultant
              </label>
              <Select
                id="consultant"
                name="consultant"
                value={consultant}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setConsultant(e.target.value)
                }
                placeholder="Filter by consultant..."
                showClear={!!consultant}
                onClear={() => setConsultant("")}
                data={uniqueConsultants
                  .sort((a: string, b: string) => a.localeCompare(b))
                  .map((name: string) => ({
                    label: name,
                    value: name,
                  }))}
              />
            </div>
          </div>
        </div>

        {/* Table Section */}
        {data?.results?.length > 0 && (
          <motion.div
            data-tour="migration-table"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6 }}
          >
            {/* Pagination Controls */}
            <div className="relative z-10 bg-white/95 backdrop-blur-sm rounded-t-lg shadow-sm border border-btnInactive p-4 flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span className="text-sm text-coolGrey">Show</span>
                <select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  className="border border-gray-300 rounded-md px-2 py-1.5 text-sm focus:ring-primary focus:border-primary"
                >
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                  <option value={2000}>2000</option>
                </select>
                <span className="text-sm text-coolGrey">entries</span>
              </div>
              <Pagination
                data={data}
                prev={prev}
                next={next}
                currentPage={page}
                totalPages={Math.ceil((data?.totalResults || 0) / pageSize)}
                onPageChange={goToPage}
              />
            </div>

            {/* Tabs */}
            <div className="relative z-10 bg-white/95 backdrop-blur-sm border-x border-btnInactive">
              <div className="flex items-center px-6 border-b border-btnInactive">
                <button
                  onClick={() => {
                    setTab("details");
                    setShowInsights(false);
                  }}
                  className={`relative py-4 px-6 text-sm font-medium transition-colors duration-200 ${
                    tab === "details" && !showInsights
                      ? "text-primary"
                      : "text-coolGrey hover:text-primary/80"
                  }`}
                >
                  Table View
                  {tab === "details" && !showInsights && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
                      initial={false}
                      transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 30,
                      }}
                    />
                  )}
                </button>
                <button
                  onClick={() => {
                    setTab("details");
                    setShowInsights(true);
                  }}
                  className={`relative py-4 px-6 text-sm font-medium transition-colors duration-200 ${
                    showInsights
                      ? "text-primary"
                      : "text-coolGrey hover:text-primary/80"
                  }`}
                >
                  Insights View
                  {showInsights && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
                      initial={false}
                      transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 30,
                      }}
                    />
                  )}
                </button>
                {/* <button
                  onClick={() => {
                    setTab("account");
                    setShowInsights(false);
                  }}
                  className={`relative py-4 px-6 text-sm font-medium transition-colors duration-200 ${
                    tab === "account" 
                      ? 'text-primary' 
                      : 'text-coolGrey hover:text-primary/80'
                  }`}
                >
                  Account
                  {tab === "account" && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
                      initial={false}
                      transition={{ type: "spring", stiffness: 500, damping: 30 }}
                    />
                  )}
                </button> */}
                <button
                  onClick={() => {
                    setTab("call-logs");
                    setShowInsights(false);
                  }}
                  className={`relative py-4 px-6 text-sm font-medium transition-colors duration-200 ${
                    tab === "call-logs"
                      ? "text-primary"
                      : "text-coolGrey hover:text-primary/80"
                  }`}
                >
                  Call Logs
                  {tab === "call-logs" && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
                      initial={false}
                      transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 30,
                      }}
                    />
                  )}
                </button>
                <button
                  onClick={() => {
                    setTab("metrics");
                    setShowInsights(false);
                  }}
                  className={`relative py-4 px-6 text-sm font-medium transition-colors duration-200 ${
                    tab === "metrics"
                      ? "text-primary"
                      : "text-coolGrey hover:text-primary/80"
                  }`}
                >
                  Metrics
                  {tab === "metrics" && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
                      initial={false}
                      transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 30,
                      }}
                    />
                  )}
                </button>
                <button
                  onClick={() => {
                    setTab("assigned");
                    setShowInsights(false);
                  }}
                  className={`relative py-4 px-6 text-sm font-medium transition-colors duration-200 ${
                    tab === "assigned"
                      ? "text-primary"
                      : "text-coolGrey hover:text-primary/80"
                  }`}
                >
                  Assigned
                  {tab === "assigned" && (
                    <motion.div
                      layoutId="activeTab"
                      className="absolute bottom-0 left-0 right-0 h-0.5 bg-primary"
                      initial={false}
                      transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 30,
                      }}
                    />
                  )}
                </button>
              </div>
            </div>

            {/* Table/Insights/Call Logs Content */}
            {tab === "details" && !showInsights && (
              <motion.div
                animate={{
                  opacity: isTableRefreshing ? 0.5 : 1,
                  scale: isTableRefreshing ? 0.99 : 1,
                }}
                transition={{ duration: 0.3 }}
                className="bg-white/95 backdrop-blur-sm rounded-b-lg shadow-sm border-x border-b border-btnInactive overflow-hidden"
              >
                <div className="overflow-x-auto relative">
                  {isTableRefreshing && (
                    <div className="absolute inset-0 flex items-center justify-center bg-white/50 backdrop-blur-sm z-10">
                      <div className="w-8 h-8 border-2 border-primary border-t-transparent rounded-full animate-spin" />
                    </div>
                  )}
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center">
                            Account Name
                            <SortButton
                              field="name"
                              currentSort={sortConfig}
                              onSort={handleSort}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center">
                            Account Email
                            <SortButton
                              field="email"
                              currentSort={sortConfig}
                              onSort={handleSort}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 w-[150px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center">
                            Date Created
                            <SortButton
                              field="createdAt"
                              currentSort={sortConfig}
                              onSort={handleSort}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 w-[150px] text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center">
                            Date Modified
                            <SortButton
                              field="updatedAt"
                              currentSort={sortConfig}
                              onSort={handleSort}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center">
                            Status
                            <SortButton
                              field="status"
                              currentSort={sortConfig}
                              onSort={handleSort}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center">
                            Tags
                            <SortButton
                              field="tags"
                              currentSort={sortConfig}
                              onSort={handleSort}
                            />
                          </div>
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          <div className="flex items-center">
                            Assigned To
                            <SortButton
                              field="assignedTo"
                              currentSort={sortConfig}
                              onSort={handleSort}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filteredData?.results?.map(
                        (item: AnyObject, index: number) => (
                          <tr
                            key={index}
                            className="hover:bg-gray-50 transition-colors"
                            onClick={() => setSelectedMigrationId(item.id)}
                          >
                            <td className="px-4 py-2">
                              <div className="text-sm font-medium text-primary hover:text-primary-dark w-[120px]">
                                <Link
                                  to={`/migration-assessment/${item.id}`}
                                  className="break-words"
                                >
                                  {item.name}
                                </Link>
                              </div>
                            </td>
                            <td className="px-4 py-2">
                              <p className="text-sm text-gray-900 w-[180px] break-words">
                                {item.email}
                              </p>
                            </td>
                            <td className="px-6 py-2">
                              <p className="text-sm text-gray-500 w-[100px]">
                                {convertDate(new Date(item.createdAt))}
                              </p>
                            </td>
                            <td className="px-6 py-2">
                              <p className="text-sm text-gray-500 w-[100px]">
                                {convertDate(new Date(item.updatedAt))}
                              </p>
                            </td>
                            <td className="px-6 py-2">
                              <span
                                className={`px-3 inline-flex text-xs leading-5 font-semibold rounded-full 
                          ${
                            item.status === "Recommendation complete"
                              ? "bg-green-100 text-primary"
                              : item.status === "Incomplete"
                              ? "bg-yellow-100 text-yellow-800"
                              : item.status === "Subscribe to service"
                              ? "bg-blue-100 text-blue-800"
                              : "bg-gray-100 text-gray-800"
                          }`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td className="px-4 py-2">
                              <div className="flex flex-wrap gap-1.5 max-w-xs">
                                {item.tags?.length > 0 ? (
                                  item.tags.map(
                                    (tag: string, tagIndex: number) => (
                                      <span
                                        key={tagIndex}
                                        className="px-3 py-1 bg-primary/5 text-primary text-xs font-medium rounded-full break-words hover:bg-primary/10 transition-colors duration-200"
                                      >
                                        {tag}
                                      </span>
                                    )
                                  )
                                ) : (
                                  <span className="text-sm text-gray-400 italic">
                                    No tags
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="px-4 py-2">
                              <div className="flex items-center gap-2">
                                {item.assignedTo?.userName ? (
                                  <>
                                    <div className="w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center">
                                      {item.assignedTo.userName[0].toUpperCase()}
                                    </div>
                                    <span className="text-sm text-gray-900">
                                      {item.assignedTo.userName}
                                    </span>
                                  </>
                                ) : (
                                  <Button
                                    variant="primary"
                                    size="small"
                                    label="Assign"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      navigate(
                                        `/migration-assessment/${item.id}/assign`
                                      );
                                    }}
                                    disabled={true}
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </motion.div>
            )}

            {/* Insights Content */}
            <AnimatePresence mode="wait">
              {showInsights && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                  className="relative z-10 bg-white/95 backdrop-blur-sm rounded-b-lg shadow-sm border-x border-b border-btnInactive p-6"
                >
                  <div className="grid grid-cols-2 gap-6">
                    <MigrationInsights data={filteredData?.results || []} />
                  </div>
                </motion.div>
              )}
            </AnimatePresence>

            {/* Call Logs Content */}
            <AnimatePresence mode="wait">
              {tab === "call-logs" && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2 }}
                  className="relative z-10 bg-white/95 backdrop-blur-sm rounded-b-lg shadow-sm border-x border-b border-btnInactive p-6"
                >
                  <CallLogs />
                </motion.div>
              )}
            </AnimatePresence>

            {/* Metrics Content */}
            {tab === "metrics" && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
                className="relative z-10 bg-white/95 backdrop-blur-sm rounded-b-lg shadow-sm border-x border-b border-btnInactive p-6"
              >
                <motion.h2
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  className="text-2xl font-bold text-coolGrey mb-8"
                >
                  Metrics Summary
                </motion.h2>

                <div className="grid grid-cols-3 gap-6">
                  {/* Total Leads Card */}
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.1 }}
                    className="bg-gradient-to-br from-blue-50 to-blue-100/50 rounded-xl p-6 border border-blue-200 hover:shadow-lg transition-shadow duration-300"
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-blue-600 mb-1">
                          Total Leads Generated
                        </h3>
                        <motion.p
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.2 }}
                          className="text-3xl font-bold text-blue-700"
                        >
                          {totalLeadsCurrentMonth}
                        </motion.p>
                      </div>
                      <div className="p-3 bg-blue-100 rounded-lg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-blue-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4v16m8-8H4"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="text-sm text-blue-600">
                        {totalLeadsChange > 0
                          ? `↑ ${totalLeadsChange.toFixed(2)}% from last month`
                          : `↓ ${Math.abs(totalLeadsChange).toFixed(
                              2
                            )}% from last month`}
                      </span>
                    </div>
                  </motion.div>

                  {/* Incomplete Forms Card */}
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.2 }}
                    className="bg-gradient-to-br from-amber-50 to-amber-100/50 rounded-xl p-6 border border-amber-200 hover:shadow-lg transition-shadow duration-300"
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-amber-600 mb-1">
                          Incomplete Migration Forms
                        </h3>
                        <motion.p
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.3 }}
                          className="text-3xl font-bold text-amber-700"
                        >
                          {incompleteQuery.data?.totalResults || 0}
                        </motion.p>
                      </div>
                      <div className="p-3 bg-amber-100 rounded-lg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-amber-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="text-sm text-amber-600">
                        Requires immediate attention
                      </span>
                    </div>
                  </motion.div>

                  {/* Subscribed Leads Card */}
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    className="bg-gradient-to-br from-green-50 to-green-100/50 rounded-xl p-6 border border-green-200 hover:shadow-lg transition-shadow duration-300"
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-primary mb-1">
                          Subscribed Leads
                        </h3>
                        <motion.p
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.3 }}
                          className="text-3xl font-bold text-primary"
                        >
                          {subscribedLeadsCurrentMonth}
                        </motion.p>
                      </div>
                      <div className="p-3 bg-green-100 rounded-lg">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6 text-primary"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="mt-4">
                      <span className="text-sm text-green-600">
                        {subscribedLeadsChange > 0
                          ? `↑ ${subscribedLeadsChange.toFixed(
                              2
                            )}% conversion rate`
                          : `↓ ${Math.abs(subscribedLeadsChange).toFixed(
                              2
                            )}% conversion rate`}
                      </span>
                    </div>
                  </motion.div>

                  {/* Consultations Section */}
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.4 }}
                    className="col-span-3 mt-6"
                  >
                    <h3 className="text-lg font-semibold text-coolGrey mb-4">
                      Consultation Bookings
                    </h3>
                    <div className="grid grid-cols-2 gap-6">
                      {/* Group Consultations */}
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="bg-gradient-to-br from-purple-50 to-purple-100/50 rounded-xl p-6 border border-purple-200"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="text-sm font-medium text-purple-600 mb-1">
                              Group Consultations
                            </h4>
                            <p className="text-2xl font-bold text-purple-700">
                              {groupConsultationsThisMonth}
                            </p>
                            <span className="text-sm text-purple-600 mt-2 block">
                              {groupConsultationsThisMonth > 0
                                ? `${groupConsultationsThisMonth} scheduled for this month`
                                : "No group consultations scheduled for this month"}
                            </span>
                          </div>
                          <div className="p-3 bg-purple-100 rounded-lg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-purple-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 4v16m8-8H4"
                              />
                            </svg>
                          </div>
                        </div>
                      </motion.div>

                      {/* Private Consultations */}
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        className="bg-gradient-to-br from-indigo-50 to-indigo-100/50 rounded-xl p-6 border border-indigo-200"
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <h4 className="text-sm font-medium text-indigo-600 mb-1">
                              Private Consultations
                            </h4>
                            <p className="text-2xl font-bold text-indigo-700">
                              {totalThisMonth}
                            </p>
                            <span className="text-sm text-indigo-600 mt-2 block">
                              {totalToday > 0
                                ? `${totalToday} scheduled for today`
                                : "No consultations scheduled for today"}
                            </span>
                          </div>
                          <div className="p-3 bg-indigo-100 rounded-lg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-indigo-600"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                              />
                            </svg>
                          </div>
                        </div>
                      </motion.div>
                    </div>
                  </motion.div>

                  {/* Recent Activity */}
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                    className="col-span-3 mt-6"
                  >
                    <h3 className="text-lg font-semibold text-coolGrey mb-4">
                      Today's Generated Leads
                    </h3>
                    <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
                      <div className="divide-y divide-gray-200">
                        {data?.results
                          ?.filter((lead: any) => {
                            const leadDate = new Date(lead.createdAt);
                            return isSameDay(leadDate, today);
                          })
                          .slice(0, 5)
                          .map((lead: any, index: number) => (
                            <motion.div
                              key={lead.id}
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ delay: 0.1 * index }}
                              className="p-4 hover:bg-gray-50 transition-colors duration-200"
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-3">
                                  <div className="flex-shrink-0">
                                    <div className="w-10 h-10 rounded-full bg-primary/10 flex items-center justify-center">
                                      <span className="text-primary font-medium">
                                        {lead.name?.[0]?.toUpperCase()}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <p className="text-sm font-medium text-gray-900">
                                      {lead.name}
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      {lead.email}
                                    </p>
                                  </div>
                                </div>
                                <span
                                  className={`px-2 py-1 text-xs font-medium rounded-full ${
                                    lead.status === "Incomplete"
                                      ? "bg-amber-100 text-amber-800"
                                      : lead.status === "Subscribe to service"
                                      ? "bg-green-100 text-green-800"
                                      : "bg-gray-100 text-gray-800"
                                  }`}
                                >
                                  {lead.status}
                                </span>
                              </div>
                            </motion.div>
                          ))}
                      </div>
                    </div>
                  </motion.div>
                </div>
              </motion.div>
            )}

            {/* Assigned Tab Content */}
            {tab === "assigned" && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
                className="relative z-10 bg-white/95 backdrop-blur-sm rounded-b-lg shadow-sm border-x border-b border-btnInactive p-6"
              >
                {/* Section Tabs */}
                <div className="flex gap-4 mb-6">
                  <button
                    onClick={() => setAssignedTabSection("assigned-to-me")}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                      assignedTabSection === "assigned-to-me"
                        ? "bg-primary text-white"
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    }`}
                  >
                    Leads Assigned to Me
                  </button>
                  <button
                    onClick={() => setAssignedTabSection("assigned-by-me")}
                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors duration-200 ${
                      assignedTabSection === "assigned-by-me"
                        ? "bg-primary text-white"
                        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    }`}
                  >
                    Leads I Assigned
                  </button>
                </div>

                {/* Leads Table */}
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Account Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {assignedTabSection === "assigned-to-me"
                            ? "Assigned By"
                            : "Assigned To"}
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date Assigned
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filterAssignedLeads(
                        data?.results || [],
                        assignedTabSection
                      ).map((lead: Lead) => {
                        const assignmentEntry = lead.timeline?.find((entry) =>
                          entry.status.includes("Assigned")
                        );

                        return (
                          <tr key={lead.id} className="hover:bg-gray-50">
                            <td className="px-6 py-4 whitespace-nowrap">
                              <Link
                                to={`/migration-assessment/${lead.id}`}
                                className="text-sm font-medium text-primary hover:text-primary-dark"
                              >
                                {lead.name}
                              </Link>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {lead.email}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span
                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full 
                                ${
                                  lead.status === "Recommendation complete"
                                    ? "bg-green-100 text-green-800"
                                    : lead.status === "Incomplete"
                                    ? "bg-yellow-100 text-yellow-800"
                                    : lead.status === "Subscribe to service"
                                    ? "bg-blue-100 text-blue-800"
                                    : "bg-gray-100 text-gray-800"
                                }`}
                              >
                                {lead.status}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {assignedTabSection === "assigned-to-me"
                                ? assignmentEntry?.author?.userName || "N/A"
                                : lead.assignedTo?.userName ||
                                  lead.consultant?.userName ||
                                  lead.accountManager?.userName ||
                                  "N/A"}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {assignmentEntry
                                ? convertDate(new Date(assignmentEntry.date))
                                : "N/A"}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {filterAssignedLeads(data?.results || [], assignedTabSection)
                    .length === 0 && (
                    <div className="text-center py-8 text-gray-500">
                      No leads found for this section
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </motion.div>
        )}

        {/* Tour Component */}
        <DashboardTour isOpen={showTour} onClose={handleCloseTour} />

        {/* Status Filter Modal */}
        <Modal isShown={show}>
          <Filter
            onClose={() => setShow(false)}
            onChange={handleFilterChange}
            filterTerm={filterTerm}
          />
        </Modal>

        {/* Account Tab Content */}
        {/* <AnimatePresence mode="wait">
          {tab === "account" && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="relative z-10 bg-white/95 backdrop-blur-sm rounded-b-lg shadow-sm border-x border-b border-btnInactive"
            >
              <AccountManagement migrationId={selectedMigrationId} />
            </motion.div>
          )}
        </AnimatePresence> */}
      </div>
    </motion.div>
  );
}
