

export default function EmptyTable({message}: {message?:  string}) {
  return (
      <div className="p-24 bg-white border border-btnInactive flex items-center justify-center">
        <p className="text-2xl"> {message ?? "No data available yet."}</p>
      </div>

      
  );
}