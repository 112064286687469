import Button from "components/Button";
import { useCardHandlers } from "../useCardHandlers";
import { useEffect } from "react";
import { AnyObject } from "utils/types";
import toast from "react-hot-toast";

interface TerminateProps {
  onClose: () => void;
  cardId: string;
  userId: string;
  queryId: string;

}

const Terminate = ({ onClose, cardId, userId, queryId}: TerminateProps) => {

  const {handleTerminate, loading, error, isError} = useCardHandlers(onClose);

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  return (
    <div className="bg-white w-[315px] m-auto mt-48 rounded">
      <div className="p-4">
        <p className="text-blackDark text-center mt-8 font-medium text-lg">
          Are you sure you want to terminate this card?
        </p>
      </div>

      <div className="px-4 flex flex-col justify-between items-center">
        <Button
          loading={loading}
          variant="danger"
          label={"Yes, Terminate"}
          onClick={() => handleTerminate({cardId, userId, queryId})}
        />
        <Button variant="neutral" label="Cancel" onClick={onClose} />
      </div>
    </div>
  );
};

export default Terminate;
