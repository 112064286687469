import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { api } from "utils/api";
import { buildQuery } from "utils/fx";

// Get all Cards
const getCards = async (page: number, type: string, filter?: string) => {
  const query = buildQuery({
    page,
    searchTerm: filter,
    pageSize: 30,
    sortBy: "-createdAt",
  });
  const res = await api.get(
    type === "transaction-history"
      ? `/cards/v1/transactions?${query}`
      : `cards/v1?${query}`
  );
  return res.data.data;
};
export const useFetchCards = (page: number, type: string, filter?: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["cards", page, type, filter],
    () => getCards(page, type, filter),
    {
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!type,
    }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};

// Get card
const getCard = async (id: string) => {
  const res = await api.get(`cards/v1?cardId=${id}`);
  return res.data.data;
};
export const useFetchCard = (id: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["card", id],
    () => getCard(id),
    { retry: false, refetchOnWindowFocus: false, enabled: !!id }
  );
  return {
    data,
    isLoading,
    isError,
    error,
  };
};


// Get card analytics
const getCardAnalytics = async () => {
  const res = await api.get(`cards/v1/metrics`);
  return res.data.data;
};
export const useFetchCardAnalytics = () => {
  const { data, isLoading, isError, error } = useQuery(
    ["cardAnalytics"],
    getCardAnalytics,
    { retry: false, refetchOnWindowFocus: false }
  );
  return {
    analytics: data,
    loadingAnalytics: isLoading,
    isErrorAnalytics: isError,
    errorAnalytics: error,
  };
};

// Get card analytics
const getCardTransactions = async (page: number, cardId: string) => {
  const res = await api.get(`cards/v1/transactions?cardId=${cardId}`);
  return res.data.data;
};
export const useFetchCardTransactions = (page: number, cardId: string) => {
  const { data, isLoading, isError, error } = useQuery(
    ["cardTransactions"],
    () => getCardTransactions(page, cardId),
    { retry: false, refetchOnWindowFocus: false, enabled: !!cardId }
  );
  return {
    transactions: data,
    loadingTransactions: isLoading,
    isErrorTransactions: isError,
    errorTransactions: error,
  };
};

// Action handlers
export const useCardHandlers = (onClose?: () => void) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useMutation(
    ({
      cardId,
      userId,
    }: {
      cardId: string;
      userId: string;
      queryId: string;
    }) => {
      return api.patch(`cards/v1/${cardId}/terminate?userId=${userId}`);
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["card", variables.queryId]);
        toast.success("Card successfully terminated!");
      },
    }
  );

  const {
    mutate: mutateFreeze,
    isLoading: isLoadingFreeze,
    isError: isErrorFreeze,
    error: errorFreeze,
  } = useMutation(
    ({
      cardId,
      userId,
      type,
    }: {
      cardId: string;
      userId: string;
      type: "freeze" | "unfreeze";
      queryId: string;
    }) => {
      return api.patch(`cards/v1/${cardId}/${type}?userId=${userId}`);
    },
    {
      onSuccess: async (res, variables) => {
        onClose?.();
        queryClient.invalidateQueries(["card", variables.queryId]);
        toast.success(`Card successfully ${variables.type}d!`);
      },
    }
  );

  const handleTerminate = (data: {
    cardId: string;
    userId: string;
    queryId: string;
  }) => {
    mutate(data);
  };

  const handleFreeze = (data: {
    cardId: string;
    userId: string;
    type: "freeze" | "unfreeze";
    queryId: string;
  }) => {
    mutateFreeze(data);
  };

  return {
    handleTerminate,
    handleFreeze,
    loading: isLoading || isLoadingFreeze,
    isError: isError || isErrorFreeze,
    error: error || errorFreeze,
  };
};
