import Button from "components/Button";
import { useDocumentTitle } from "hooks/useDocument";
import lock from "assets/lock.svg";
import { useNavigate } from "react-router-dom";

function AccessDenied() {
  useDocumentTitle("Access Denied");
    const navigate = useNavigate();

  return (
    <div className="bg-white my-32 py-8 px-10 rounded-[10px] max-w-md mx-auto">
      <img src={lock} alt="access denied" className="w-12 mx-auto my-4" />
      <p className="text-blackDarkDark text-xl font-bold text-center mb-8">
      Access Denied
      </p>
      <p className="text-lightText text-center text-sm">
      You do not have permission to view this page.
      </p>

      <Button
        label="View home"
        onClick={() => navigate('/home')}
      />
    </div>
  );
}

export default AccessDenied;