import React from 'react';
import { motion } from 'framer-motion';
import { useFormik } from "formik";
import { array, object } from "yup";
import CreatableSelect from "react-select/creatable";
import close from "assets/close.svg";
import { useMigrationActions } from "../useMigrationHandlers";
import { AnyObject } from "utils/types";
import Button from "components/Button";

interface TagsProps {
  onClose: () => void;
  data: AnyObject;
}

export interface TagsValues {
  tags: Array<string>;
}

const Tags = ({ onClose, data }: TagsProps) => {
  const { handleTag, loading } = useMigrationActions(onClose);

  const { handleSubmit, setFieldValue, handleBlur } =
    useFormik<TagsValues>({
      initialValues: {
        tags: data?.tags || [],
      },
      validationSchema: object({
        tags: array()
          .min(1, "You can't leave this blank.")
          .required("You can't leave this blank."),
      }),
      onSubmit: (values) => {
        const payload = {
          tags: values.tags,
          leadId: data.id,
        };
        handleTag(payload);
      },
    });

  const onChange = (data: any) => {
    setFieldValue(
      "tags",
      data.map((d: any) => d.label)
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50"
    >
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        className="bg-white w-[400px] mt-20 rounded-lg shadow-lg overflow-hidden"
      >
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between items-center p-4 border-b border-btnInactive">
            <p className="text-black text-base font-bold">Manage Tags</p>
            <img 
              role="button" 
              src={close} 
              alt="close" 
              onClick={onClose}
              className="cursor-pointer" 
            />
          </div>

          <div className="p-4">
            <div className="space-y-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Tags
              </label>
              <CreatableSelect
                isMulti
                onChange={onChange}
                onBlur={handleBlur}
                defaultValue={data?.tags?.map((tag: string) => ({
                  label: tag,
                  value: tag
                }))}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Type to create or select existing tags..."
              />
            </div>
          </div>

          <div className="p-4 border-t border-btnInactive flex justify-end gap-2">
            <Button
              variant="neutral"
              size="small"
              label="Cancel"
              onClick={onClose}
            />
            <Button 
              label="Save Tags" 
              size="small" 
              type="submit" 
              loading={loading} 
            />
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default Tags;
