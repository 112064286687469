import { useState, useEffect } from "react";
import Button from "components/Button";
import { useMigrationSearch } from "hooks/useMigrationSearch";
import { useDebounce } from "hooks/useDebounce";

interface Suggestion {
  value: string;
  label: string;
  data: {
    id: string;
    name: string;
    [key: string]: any;
  };
}

interface CallLogModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  preselectedAccountId?: string;
  hideAccountSelection?: boolean;
}

export default function CallLogModal({
  isOpen,
  onClose,
  onSubmit,
  preselectedAccountId,
  hideAccountSelection,
}: CallLogModalProps) {
  const [formData, setFormData] = useState({
    accountName: "",
    accountId: "",
    summary: "",
    statusByCall: "",
    statusAfterCall: "",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const { suggestions, isLoading } = useMigrationSearch(debouncedSearchTerm);

  useEffect(() => {
    if (preselectedAccountId) {
      setFormData((prev) => ({
        ...prev,
        accountId: preselectedAccountId,
      }));
    }
  }, [preselectedAccountId]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
    } else {
      setIsSearching(false);
    }
  }, [debouncedSearchTerm]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleAccountSelect = (suggestion: Suggestion) => {
    setFormData((prev) => ({
      ...prev,
      accountId: suggestion.data.id,
      accountName: suggestion.label,
    }));
    setSearchTerm(suggestion.label);
    setIsSearching(false);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      dateTime: new Date().toISOString(),
    });
    onClose();
  };

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "In Progress", label: "In Progress" },
    { value: "Submitted", label: "Submitted" },
    { value: "Subscribed", label: "Subscribed" },
    { value: "Completed", label: "Completed" },
    { value: "No Answer", label: "No Answer" },
    { value: "Customer Not Ready", label: "Customer Not Ready" },
    { value: "Follow Up Required", label: "Follow Up Required" },
  ];

  if (!isOpen) return null;

  return (
    <div className="fixed h-full inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 overflow-y-scroll">
      <div className="bg-white rounded-lg w-full max-w-2xl">
        <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Add Call Log</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {!hideAccountSelection && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Account
              </label>
              <div className="relative">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                  placeholder="Search for an account..."
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                />
                {isSearching && suggestions.length > 0 && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                    {suggestions.map((suggestion: Suggestion) => (
                      <button
                        key={suggestion.value}
                        type="button"
                        onClick={() => handleAccountSelect(suggestion)}
                        className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none"
                      >
                        <div className="font-medium">{suggestion.label}</div>
                        <div className="text-sm text-gray-500">
                          {suggestion.data.email}
                        </div>
                      </button>
                    ))}
                  </div>
                )}
                {isLoading && (
                  <div className="absolute right-3 top-2">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-primary"></div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status Before Call
              </label>
              <select
                name="statusByCall"
                value={formData.statusByCall}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              >
                <option value="">Select status</option>
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Status After Call
              </label>
              <select
                name="statusAfterCall"
                value={formData.statusAfterCall}
                onChange={handleChange}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              >
                <option value="">Select status</option>
                {statusOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Summary
              </label>
              <textarea
                name="summary"
                value={formData.summary}
                onChange={handleChange}
                required
                rows={4}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                placeholder="Enter call summary"
              />
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <Button
              variant="secondary"
              size="small"
              label="Cancel"
              onClick={onClose}
            />
            <Button variant="primary" size="small" label="Save" type="submit" />
          </div>
        </form>
      </div>
    </div>
  );
}
