import { startCase } from "lodash";

type statusType =
  | "pending"
  | "active"
  | "approved"
  | "failed"
  | "successful"
  | "success"
  | "blacklisted"
  | "inactive"
  | "terminated"
  | "disabled"
  | "processing"
  | "PND"
  | "verified";
const statusStyle: Record<statusType, string> = {
  pending: "bg-amberPill text-amber",
  active: "bg-greenAccent text-primary",
  approved: "bg-greenAccent text-primary",
  successful: "bg-greenAccent text-primary",
  success: "bg-greenAccent text-primary",
  failed: "bg-lightDanger text-danger",
  blacklisted: "bg-lightDanger text-danger",
  inactive: "bg-lightDanger text-danger",
  terminated: "bg-lightDanger text-danger",
  disabled: "bg-btnInactive text-coolGrey",
  processing: "bg-blue-100 text-blue-500",
  PND: "bg-lightDanger text-danger",
  verified: "bg-greenAccent text-primary",
};
const Status = ({ status, width }: { status: statusType; width?: string }) => (
  <span
    className={`${statusStyle[status]} ${width} inline-flex p-1 rounded text-sm items-center justify-center`}
  >
    {startCase(status)}
  </span>
);

export default Status;
