import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';

export const exportToExcel = (data: any[], filename: string) => {
  const formattedData = data.map(item => ({
    Name: item.name || '',
    Email: item.email || '',
    Status: item.status || '',
    'Financial Preparedness': item.financialPreparedness || '',
    'Monthly Income': item.monthlyIncome || '',
    'Assigned To': item.assignedTo?.userName || '',
    'Created Date': item.createdAt ? format(new Date(item.createdAt), 'yyyy-MM-dd') : '',
    'Last Updated': item.updatedAt ? format(new Date(item.updatedAt), 'yyyy-MM-dd') : ''
  }));

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Migrations');
  
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const data_blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
  saveAs(data_blob, `${filename}_${format(new Date(), 'yyyy-MM-dd')}.xlsx`);
};
