import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import PrivateRoute from "pages/authenticated/PrivateRoute";
import Migrations from "pages/authenticated/migration/Migrations";
import Migration from "pages/authenticated/migration/Migration";
import Details from "pages/authenticated/migration/Details";
import Comments from "pages/authenticated/migration/Comments";
import CardsLayout from "pages/authenticated/cards/CardsLayout";
import Cards from "pages/authenticated/cards/Cards";
import CardsTransactions from "pages/authenticated/cards/TransactionHistory";
import CardDetails from "pages/authenticated/cards/CardDetails";
import Administrators from "pages/authenticated/admin/Administrators";
import ManageRole from "pages/authenticated/admin/ManageRole";
import CreateRole from "pages/authenticated/admin/CreateRole";
import EditRole from "pages/authenticated/admin/EditRole";
import Metrics from "pages/authenticated/Metrics";
import Error from "components/Error";
import Login from "pages/public/Login";
import Root from "pages/public/Root";
import LoginSuccess from "pages/public/LoginSuccess";
import ForgotPassword from "pages/public/ForgotPassword";
import ChangePassword from "pages/public/ChangePassword";
import PasswordChangeSuccess from "pages/public/PasswordChangeSuccess";
import VerifyOTP from "pages/public/VerifyOTP";
import CallLogs from "pages/authenticated/migration/CallLogs";
const router = createBrowserRouter([
  {
    path: "/*",
    element: <Root />,
    errorElement: <Error />,
    children: [
      { index: true, element: <Navigate to="/login" replace /> },
      { path: "login", Component: () => <Login /> },
      { path: "login-success", Component: () => <LoginSuccess /> },
      { path: "change-password", Component: () => <ChangePassword /> },
      { path: "forgot-password", Component: () => <ForgotPassword /> },
      { path: "forgot-password/verify-otp", Component: () => <VerifyOTP /> },
      {
        path: "password-change-success",
        Component: () => <PasswordChangeSuccess />,
      },
      { path: "*", Component: () => <Navigate to="/login" replace /> },
    ],
  },
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement: <Error />,
    children: [
      { path: "", element: <Navigate to="/migration-assessment" replace /> },
      { path: "home", element: <Navigate to="/migration-assessment" replace /> },
      { path: "migration-assessment", element: <Migrations /> },
      {
        path: "migration-assessment/:id",
        element: <Migration />,
        children: [
          { index: true, element: <Details /> },
          { path: "comments", element: <Comments /> },
          { path: "call-logs", element: <CallLogs /> },
        ],
      },
      {
        path: "cards",
        element: <CardsLayout />,
        children: [
          { index: true, element: <Cards /> },
          { path: "transaction-history", element: <CardsTransactions /> },
        ],
      },
      { path: "cards/:id", element: <CardDetails /> },
      { path: "administrators", element: <Administrators /> },
      { path: "manage-role", element: <ManageRole /> },
      { path: "create-role", element: <CreateRole /> },
      { path: "edit-role/:roleId", element: <EditRole /> },
      { path: "analytics", element: <Metrics /> },
      { path: "*", element: <Navigate to="/migration-assessment" replace /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
