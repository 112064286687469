import { useMutation } from "@tanstack/react-query";
import { useAuthContext } from "hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
import { api } from "utils/api";
import { LoginValues } from "./Login";
import { ForgotValues } from "./ForgotPassword";
import { ResetValues } from "./ChangePassword";
import { VerifyValues } from "./VerifyOTP";

export const useAuthHandlers = () => {
  const { dispatch } = useAuthContext();
  const navigate = useNavigate();
  const { mutate, isLoading, isError, error } = useMutation(
    (data: LoginValues) => {
      return api.post("users/v1/auth/admin-login", data);
    },
    {
      onSuccess: async (res) => {
        if (res.data.data.user.role === "superAdmin") {
          localStorage.setItem("user", JSON.stringify(res.data.data));
          dispatch({ type: "LOGIN", payload: res.data.data });
          navigate("/home");
        } else {
          if (res.data.data.user.hasDefaultPassword) {
            navigate("/login-success", {state: {...res.data.data}});
          } else {
            localStorage.setItem("user", JSON.stringify(res.data.data));
            dispatch({ type: "LOGIN", payload: res.data.data });
            navigate("/home");
          }
        }
      },
    }
  );

  const {
    mutate: mutateResetPassword,
    isLoading: isLoadingReset,
    isError: isErrorReset,
    error: errorReset,
  } = useMutation(
    (data: ResetValues) => {
      return api.post(`users/v1/auth/reset-password`, data);
    },

    {
      onSuccess: (data) => {

        navigate("/password-change-success");
      },
    }
  );

  const {
    mutate: mutateForgotPassword,
    isLoading: isLoadingChange,
    isError: isErrorChange,
    error: errorChange,
  } = useMutation(
    (data: ForgotValues) => {
      return api.post(`users/v1/auth/forgot-password`, data);
    },

    {
      onSuccess: (data, variables) => {
        navigate("/forgot-password/verify-otp", {state: {...data.data.data, ...variables}});
      },
    }
  );

  const {
    mutate: mutateVerify,
    isLoading: isLoadingVerify,
    isError: isErrorVerify,
    error: errorVerify,
  } = useMutation(
    (data: VerifyValues) => {
      return api.post(`users/v1/auth/verify-reset-password`, data);
    },

    {
      onSuccess: (data) => {
        navigate("/change-password",{ state: {...data.data.data}});
      },
    }
  );

  const handleLogin = async ({ email, password }: { email: string, password: string }) => {
    const data = { email, password } as LoginValues;

    await mutate(data);
  };

  const handleLogout = async () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const token = user?.tokens?.refresh?.token;
    await api.post(`auth/logout`, { refreshToken: token });
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };
  const handleResetPassword = async (data: ResetValues) => {
    await mutateResetPassword(data);
  };

  const handleForgotPassword = async (data: ForgotValues) => {
    await mutateForgotPassword(data);
  };

  const handleVerify = async (data: VerifyValues) => {
    await mutateVerify(data);
  };

  return {
    handleLogin,
    handleLogout,
    handleResetPassword,
    handleForgotPassword,
    handleVerify,
    loading: isLoading || isLoadingReset || isLoadingChange || isLoadingVerify,
    isError: isError || isErrorReset || isErrorChange || isErrorVerify,
    error: error || errorReset || errorChange || errorVerify,
  };
};
