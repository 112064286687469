import {
  useState,
  Fragment,
  ChangeEvent,
  useRef,
  useEffect,
  KeyboardEvent,
  Dispatch,
  SetStateAction,
} from "react";

let currentOTPIndex = 0;

interface Props {
    otp: string[];
    setOtp:  Dispatch<SetStateAction<string[]>>;
    error: boolean;
}
function OTPInput({otp, setOtp, error}: Props) {
  const [otpIndex, setOtpIndex] = useState<number>(0);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (
    { target }: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = target;
    const otpValue = [...otp];
    otpValue[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) {
      setOtpIndex(currentOTPIndex - 1);
    } else {
      setOtpIndex(currentOTPIndex + 1);
    }
    setOtp(otpValue);
  };

  const handleKeyDown = (
    { key }: KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    currentOTPIndex = index;
    if (key === "Backspace") {
      setOtpIndex(currentOTPIndex - 1);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [otpIndex]);

  return (
    <div className="grid gap-2 grid-cols-6 items-center">
      {otp.map((_, index) => {
        return (
          <Fragment key={index}>
            <input
              ref={index === otpIndex ? inputRef : null}
              type="number"
              data-error={error}
              className="w-12 h-12 border rounded bg-transparent outline-none text-center text-lg font-medium spin-button-none border-grey focus:grey focus:text-blackDark text-blackDark transition"
              onChange={handleChange}
              onKeyDown={(e) => handleKeyDown(e, index)}
              value={otp[index]}
            />
          </Fragment>
        );
      })}
    </div>
  );
}

export default OTPInput;
