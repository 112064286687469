import { startCase } from "lodash";
import { useEffect, useState } from "react";

import filter from "assets/filter.svg";
import AccessDenied from "components/AccessDenied";
import CardItem from "components/CardItem";
import Loader from "components/Loader";
import Input from "components/inputs/Input";
import { useDocumentTitle } from "hooks/useDocument";

import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AnyObject, ContextType } from "utils/types";
import { useFetchCardAnalytics, useFetchCards } from "./useCardHandlers";

import card from "assets/card.svg";
import money from "assets/money-deposit.svg";
import moneyHand from "assets/money-hand.svg";
import transfer from "assets/transfer.svg";
import {
  abbreviateNumber,
  convertDate,
  convertTime,
  formatNumber,
} from "utils/fx";
import CsvButton from "components/CsvButton";
import { cards, transactionHistory } from "utils/tableHeaders";
import { useAuthContext } from "hooks/useAuthContext";
import { useDebounce } from "hooks/useDebounce";


const tabs = ["cards", "transaction-history"];

type tabType = (typeof tabs)[number];
export default function CardsLayout() {
  const param = useParams<Record<"*", tabType>>();
  const [tab, setTab] = useState<tabType>("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const {user } = useAuthContext()
  const navigate = useNavigate();

  const location = useLocation();

  useDocumentTitle(`Cards - ${tab || "Cards"}`);

  const { analytics, loadingAnalytics } = useFetchCardAnalytics();

  const { data, error, isLoading } = useFetchCards(
    page,
    tab || "cards",
    useDebounce(searchTerm)
  );

  const canViewCards = user?.permissions?.includes('view:cards')
  useEffect(() => {
    if(!canViewCards && !isLoading) {
      navigate('/home')
    }
  }, [canViewCards, isLoading, navigate])

  useEffect(() => {
    if (param["*"]) {
      if (tabs.includes(param["*"].split("/")[1])) {
        setTab(param["*"].split("/")[1]);
      } else {
        setTab("");
      }
    }
  }, [param]);

  useEffect(() => {
    setPage(1);
  }, [location.pathname]);

  if (isLoading || loadingAnalytics) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  const prev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);
  };

  const next = () => {
    if (page < data?.totalPages) {
      setPage((prev) => prev + 1);
    }
  };

  const csvData = [tab === "" ? cards : transactionHistory];

  const getCSVData = () => {
    if (data?.cards?.length === 0) {
      return [];
    }
    if (tab === "") {
      for (let i = 0; i < data?.cards?.length; i++) {
        let arr = [];
        arr.push(data?.cards[i]?.status);

        arr.push(
          `${data?.cards[i]?.author?.firstName}
          ${data?.cards[i]?.author?.lastName}`
        );
        arr.push(data?.cards[i]?.name);
        arr.push(data?.cards[i]?.maskedPan);
        arr.push(data?.cards[i]?.issuer);
        arr.push(data?.cards[i]?.type);

        arr.push(`${convertDate(data?.cards[i]?.createdAt)}`);

        csvData.push(arr);
      }
    } else {
      for (let i = 0; i < data?.cards?.length; i++) {
        let arr = [];
        arr.push(
          `${formatNumber(
            data?.cards[i]?.amount / 100,
            data?.cards[i]?.currency
          )}`
        );
        arr.push(`${data?.cards[i]?.transactionRef}`);

        arr.push(`${data?.cards[i]?.type}}`);
        arr.push(`${data?.cards[i].merchant?.name ?? data?.cards[i].merchant}`);
        arr.push(
          `${convertDate(new Date(data?.cards[i]?.createdAt))} / ${convertTime(
            new Date(data?.cards[i]?.createdAt)
          )}`
        );
        arr.push(`${data?.cards[i]?.status}`);

        csvData.push(arr);
      }
    }
    return csvData;
  };

  return (
    <>
      <div className="flex flex-wrap gap-4 mb-12">
        <CardItem
          type="increase"
          title="Cards Created"
          cardIcon={card}
          value={`${abbreviateNumber(analytics?.cardsCount, 0)}`}
          // change="+180"
        />
        <CardItem
          type="increase"
          title="Total Deposits"
          cardIcon={money}
          value={`${abbreviateNumber(analytics?.depositTotal / 100)}`}
          // change="+200"
        />
        <CardItem
          type="increase"
          title="Total Transfers"
          cardIcon={moneyHand}
          value={`${abbreviateNumber(analytics?.transfersTotal / 100)}`}
          // change="+200"
        />
        <CardItem
          type="decrease"
          title="Total Transaction Volume"
          cardIcon={transfer}
          value={`${abbreviateNumber(analytics?.transactionCount, 0)}`}
          // change="-30"
        />
        <CardItem
          type="increase"
          title="Total Transaction Value"
          cardIcon={transfer}
          value={`${abbreviateNumber(analytics?.transactionValue / 100)}`}
          // change="+120"
        />
      </div>
      <div className="pt-2 flex justify-start items-center gap-8 ml-16 font-medium">
        <Link
          to=""
          role="tab"
          className={`${
            tab === ""
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          } pb-4 cursor-pointer`}
        >
          Cards
        </Link>
        <Link
          to="transaction-history"
          role="tab"
          className={`pb-4 cursor-pointer ${
            tab === "transaction-history"
              ? "text-primary border-b-2 border-b-primary"
              : "text-greyDark"
          }`}
        >
          Transaction History
        </Link>
      </div>
      <div className="py-5 px-7 bg-white border border-b-0 rounded-t border-btnInactive">
        <p className="font-medium text-blackDark text-lg">
          {startCase(tab || "cards")} - {data?.totalResults}
        </p>
        <div className="grid grid-cols-[100px_1.5fr_1fr] gap- my-4 items-center">
          <div className="flex gap-2 items-center mt-2">
            <img src={filter} alt="filter" />
            <p className="text-coolGrey font-medium">Filter</p>
          </div>
          <div className="w-3/4">
            <Input
              id="filter"
              value={searchTerm}
              name="searchTerm"
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="E.g Status, Reference"
              single={false}
              searchInput={!searchTerm}
              type="search"
            />
          </div>
          <div className="flex gap-2 justify-end ml-36">
            {data.cards.length > 0 && (
              <CsvButton
                data={getCSVData()}
                filename={
                  tab === ""
                    ? `cards_${page}.csv`
                    : `transaction_history_${page}.csv`
                }
              />
            )}
          </div>
        </div>
      </div>
      <Outlet context={{ data, next, prev } satisfies ContextType} />
    </>
  );
}
