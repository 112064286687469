/* eslint-disable no-useless-escape */
import Button from "components/Button";
import Input from "components/inputs/Input";
import { useFormik } from "formik";
import { useDocumentTitle } from "hooks/useDocument";
import { Fragment, useEffect, useState } from "react";
import { object, ref, string } from "yup";
import { useAuthHandlers } from "./useAuthHandlers";
import { toast } from "react-hot-toast";
import { AnyObject } from "utils/types";
import { useLocation } from "react-router-dom";

const PasswordCheck = ({ title, check }: { title: string; check: boolean }) => (
  <div className="flex gap-2 items-center mb-2">
    <span
      className={`inline-flex w-[10px] h-[10px] rounded-full ${
        check ? "border border-primary bg-primary" : "border border-grey"
      }`}
    />
    <small className="text-lightText text-xs">{title}</small>
  </div>
);

const specialRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

export interface ResetValues {
  password: string;
  token: string;
}

function ChangePassword() {
  useDocumentTitle("Change Password");

  const { state } = useLocation();

  const { handleResetPassword, isError, error, loading } = useAuthHandlers();
  const [type, setType] = useState("password");
  const [typeConfirm, setTypeConfirm] = useState("password");

  const showAction = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const showActionConfirm = () => {
    if (typeConfirm === "password") {
      setTypeConfirm("text");
    } else {
      setTypeConfirm("password");
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error(
        (error as AnyObject)?.message || "An error occurred"
      );
    }
  }, [error, isError]);

  const {
    values,
    handleChange,
    touched,
    errors,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: object({
      password: string().required("Password is required"),
      confirmPassword: string()
      .oneOf([ref('password'), ""], 'Passwords must match').required("Password is required"),
    }),
    onSubmit: async (values) => {
      const {confirmPassword, ...rest} = values;
       handleResetPassword({...rest, token: state.token})
    },
  });

  return (
    <Fragment>
      <p className="text-blackDarkDark text-xl font-bold text-center mb-8">
        Change your password
      </p>

      <form noValidate onSubmit={handleSubmit}>
        <Input
          type={type}
          label="New Password"
          id="password"
          name="password"
          value={values.password}
          onChange={handleChange}
          placeholder={"Enter password"}
          showText={type === "password" ? "show" : "hide"}
          showAction={showAction}
          error={`${!!errors["password"] && !!touched["password"]}`}
          errorMessage={errors["password"]}
          onBlur={handleBlur}
        />
        <div className="mb-4">
          <p className="text-blackDark text-xs mb-2">Password must:</p>
          <PasswordCheck check={values.password.length >= 8 } title="Be at least 8 characters" />
          <PasswordCheck check={/[a-z]/.test(values.password)} title="Have one lowercase character" />
          <PasswordCheck check={/[A-Z]/.test(values.password)} title="Have one uppercase character" />
          <PasswordCheck check={/[0-9]/.test(values.password)} title="Have one numerical character" />
          <PasswordCheck check={specialRegex.test(values.password)} title="Have one special character" />

        </div>

        <Input
          type={typeConfirm}
          label="Re-enter New Password"
          id="confirmPassword"
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          placeholder={"Enter confirm password"}
          showText={typeConfirm === "password" ? "show" : "hide"}
          showAction={showActionConfirm}
          error={`${
            !!errors["confirmPassword"] && !!touched["confirmPassword"]
          }`}
          errorMessage={errors["confirmPassword"]}
          onBlur={handleBlur}
        />
        <Button
          loading={loading}
          label="Change password"
          type="submit"
          disabled={values.confirmPassword !== values.password}
        />
      </form>
    </Fragment>
  );
}

export default ChangePassword;
