export interface LoaderProps {
  size?: "small" | "medium" | "large";
}

export default function Loader({ size = "medium" }: LoaderProps) {
  return (
    <div
      className="w-12 h-12 rounded-full animate-spin mx-auto mt-48
border-2 border-solid border-primary border-t-transparent"
    />
  );
}
