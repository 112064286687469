import { useFormik } from "formik";
import { object, string } from "yup";
import { motion } from "framer-motion";
import close from "assets/close.svg";
import { useMigrationActions } from "../useMigrationHandlers";
import { AnyObject } from "utils/types";
import Button from "components/Button";

interface StatusProps {
  onClose: () => void;
  data: AnyObject;
}

export interface StatusValues {
  status: string;
  leadId: string;
}

const statuses = [
  { group: "Initial Status", items: ["Submitted", "Incomplete"] },
  { 
    group: "Consultation", 
    items: [
      "Scheduled appointment with a consultant",
      "Met with consultant",
      "Group Consultation Done"
    ] 
  },
  { 
    group: "Migration Progress", 
    items: [
      "Subscribe to service",
      "Customer Not Ready",
      "Migration Journey ongoing",
      "Migration Journey Completed"
    ] 
  }
];

const Status = ({ onClose, data }: StatusProps) => {
  const { handleStatus, loading } = useMigrationActions(onClose);

  const { handleSubmit, setFieldValue, values } = useFormik<StatusValues>({
    initialValues: {
      status: "",
      leadId: data.id,
    },
    validationSchema: object({
      status: string().required("A status must be selected"),
    }),
    onSubmit: (values) => {
      handleStatus(values);
    },
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-50"
    >
      <motion.div
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 20, opacity: 0 }}
        className="bg-white w-[400px] mt-20 rounded-lg shadow-lg overflow-y-scroll"
      >
        <form onSubmit={handleSubmit}>
          <div className="flex justify-between items-center p-4 border-b border-btnInactive">
            <p className="text-black text-base font-bold">Update Status</p>
            <img 
              role="button" 
              src={close} 
              alt="close" 
              onClick={onClose}
              className="cursor-pointer" 
            />
          </div>

          <div className="p-4 space-y-4">
            <div className="mb-2">
              <button
                type="button"
                className={`px-3 py-1 rounded-full text-sm mr-2 ${
                  !values.status ? "bg-primary text-white" : "bg-gray-100 text-coolGrey"
                }`}
                onClick={() => setFieldValue("status", "")}
              >
                Current Status: {data.status || "None"}
              </button>
            </div>

            {statuses.map(({ group, items }) => (
              <div key={group} className="border-b border-btnInactive last:border-0 pb-4">
                <h3 className="text-sm font-medium text-coolGrey mb-2">{group}</h3>
                <div className="flex flex-wrap gap-2">
                  {items.map((status) => (
                    <button
                      type="button"
                      key={status}
                      className={`px-3 py-1 rounded-full text-sm ${
                        values.status === status 
                          ? "bg-primary text-white" 
                          : "bg-gray-100 text-coolGrey"
                      } hover:bg-opacity-90 transition-colors duration-200`}
                      onClick={() => setFieldValue("status", status)}
                    >
                      {status}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="p-4 border-t border-btnInactive flex justify-end gap-2">
            <Button
              variant="neutral"
              size="small"
              label="Cancel"
              onClick={onClose}
            />
            <Button 
              label="Update Status" 
              size="small" 
              type="submit" 
              loading={loading} 
            />
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default Status;
