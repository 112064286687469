import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate, useParams, useLocation } from "react-router-dom";
import back from "assets/back.svg";
import { useFetchMigration, useMigrationActions } from "./useMigrationHandlers";
import Loader from "components/Loader";
import { AnyObject, ContextType, StringArrayToUnion } from "utils/types";
import AccessDenied from "components/AccessDenied";
import { convertDate } from "utils/fx";
import Button from "components/Button";
import Timeline from "components/Timeline";
import { useAuthContext } from "hooks/useAuthContext";
import Modal from "components/Modal";
import Assign from "./modal/Assign";
import Status from "./modal/Status";
import Consultant from "./modal/Consultant";
import Recommendation from "./modal/Recommendations";
import Tags from "./modal/Tags";
import { motion, AnimatePresence } from "framer-motion";
import { FaGlobe, FaArrowRight, FaChevronDown, FaChevronUp, FaCheckCircle, FaClock, FaDollarSign, FaUserTie } from 'react-icons/fa';
import { toast } from "react-hot-toast";
import { api } from "utils/api";

const tabs = ["details", "comments", "call-logs"] as const;
const modals = [
  "status",
  "assign",
  "consultant",
  "tags",
  "recommendation",
] as const;

type tabType = (typeof tabs)[number];

export type ModalType = StringArrayToUnion<typeof modals>;
export type AssignType = Exclude<
  ModalType,
  "status" | "tags" | "recommendation"
>;

// Define the Recommendation interface
interface UserRecommendation {
  country: string;
  pathway: string;
}

// Define the AI Recommendation interface
interface AIRecommendation {
  country: string;
  visa_type: string;
  suitability_analysis: string;
  match_score: number;
  rationale: string;
  requirements: string[];
  preparation_steps: string[];
  challenges: string[];
  mitigation_strategies: string[];
  strengthening_factors: string[];
  estimated_costs: string;
  timeline_estimate: string;
  success_probability: number;
}

// Add new component for Score Badge
const ScoreBadge = ({ score, label }: { score: number, label: string }) => (
  <div className="flex flex-col items-center justify-center bg-gray-50 rounded-lg p-3 hover:bg-gray-100 transition-colors">
    <div className={`text-2xl font-bold ${
      score >= 80 ? 'text-primary' :
      score >= 60 ? 'text-yellow-600' :
      'text-red-600'
    }`}>
      {score}%
    </div>
    <div className="text-sm text-gray-600">{label}</div>
  </div>
);

// Add new component for Recommendation Card
const RecommendationCard = ({ 
  recommendation, 
  expanded, 
  onToggle,
}: {
  recommendation: AIRecommendation;
  expanded: boolean;
  onToggle: () => void;
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="border border-gray-200 rounded-lg mb-4 overflow-hidden hover:shadow-md transition-all duration-300"
  >
    <div 
      className="flex justify-between items-center p-4 cursor-pointer bg-white hover:bg-gray-50 transition-colors"
      onClick={onToggle}
    >
      <div className="flex items-center gap-4">
        <div className="p-2 bg-blue-50 rounded-full">
          <FaGlobe className="text-blue-600 h-5 w-5" />
        </div>
        <div>
          <p className="text-lg font-semibold text-blackDark">{recommendation.country}</p>
          <p className="text-sm text-gray-600">{recommendation.visa_type}</p>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <ScoreBadge score={recommendation.match_score} label="Match Score" />
        <div className="text-gray-400">
          {expanded ? <FaChevronUp /> : <FaChevronDown />}
        </div>
      </div>
    </div>

    <AnimatePresence>
      {expanded && (
        <motion.div
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: "auto", opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="border-t border-gray-200"
        >
          <div className="p-4 bg-white">
            {/* Key Metrics */}
            <div className="grid grid-cols-3 gap-4 mb-6">
              <div className="flex items-center gap-2 p-3 bg-green-50 rounded-lg">
                <FaCheckCircle className="text-primary" />
                <div>
                  <p className="text-sm text-gray-600">Success Rate</p>
                  <p className="text-lg font-semibold">{(recommendation.success_probability * 100).toFixed(0)}%</p>
                </div>
              </div>
              <div className="flex items-center gap-2 p-3 bg-blue-50 rounded-lg">
                <FaClock className="text-blue-600" />
                <div>
                  <p className="text-sm text-gray-600">Timeline</p>
                  <p className="text-lg font-semibold">{recommendation.timeline_estimate}</p>
                </div>
              </div>
              <div className="flex items-center gap-2 p-3 bg-yellow-50 rounded-lg">
                <FaDollarSign className="text-yellow-600" />
                <div>
                  <p className="text-sm text-gray-600">Estimated Cost</p>
                  <p className="text-lg font-semibold">{recommendation.estimated_costs}</p>
                </div>
              </div>
            </div>

            {/* Detailed Information */}
            <div className="space-y-4">
              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Suitability Analysis</h4>
                <p className="text-sm text-gray-600">{recommendation.suitability_analysis}</p>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Rationale</h4>
                <p className="text-sm text-gray-600">{recommendation.rationale}</p>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Requirements</h4>
                <ul className="space-y-2">
                  {recommendation.requirements.map((requirement, index) => (
                    <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                      <div className="w-2 h-2 bg-blue-600 rounded-full"></div>
                      {requirement}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Preparation Steps</h4>
                <ol className="list-decimal list-inside space-y-2">
                  {recommendation.preparation_steps.map((step, index) => (
                    <li key={index} className="text-sm text-gray-600">{step}</li>
                  ))}
                </ol>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Challenges & Mitigation Strategies</h4>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <h5 className="text-sm font-medium mb-2 text-gray-700">Challenges</h5>
                    <ul className="space-y-2">
                      {recommendation.challenges.map((challenge, index) => (
                        <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                          <div className="w-2 h-2 bg-red-400 rounded-full"></div>
                          {challenge}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div>
                    <h5 className="text-sm font-medium mb-2 text-gray-700">Mitigation Strategies</h5>
                    <ul className="space-y-2">
                      {recommendation.mitigation_strategies.map((strategy, index) => (
                        <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                          <div className="w-2 h-2 bg-green-400 rounded-full"></div>
                          {strategy}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-semibold mb-2">Strengthening Factors</h4>
                <ul className="space-y-2">
                  {recommendation.strengthening_factors.map((factor, index) => (
                    <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                      <div className="w-2 h-2 bg-purple-400 rounded-full"></div>
                      {factor}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.div>
);

interface FinancialData {
  cashAtHand: number;
  futureCash: number;
  hasAsset: boolean;
  assetValue: number;
  hasValidPassport: boolean;
  hasSupport: boolean;
}

const calculateFinancialScore = (data: FinancialData): {
  score: number;
  status: 'Excellent' | 'Good' | 'Needs Improvement';
  details: string[];
} => {
  let score = 0;
  const details: string[] = [];

  // Base financial calculation (60% of total score)
  const totalFinancialValue = data.cashAtHand + data.futureCash + (data.hasAsset ? data.assetValue : 0);
  const minimumRequired = 15000; // Example minimum required amount
  const targetAmount = 30000;    // Example target amount

  if (totalFinancialValue >= targetAmount) {
    score += 60;
    details.push('Strong financial position');
  } else if (totalFinancialValue >= minimumRequired) {
    score += 40;
    details.push('Adequate financial resources');
  } else {
    score += 20;
    details.push('Limited financial resources');
  }

  // Additional factors (40% of total score)
  if (data.hasValidPassport) {
    score += 15;
    details.push('Valid passport available');
  }

  if (data.hasSupport) {
    score += 15;
    details.push('Has financial support');
  }

  if (data.hasAsset) {
    score += 10;
    details.push('Has valuable assets');
  }

  // Determine status based on score
  const status = score >= 80 ? 'Excellent' : 
                score >= 60 ? 'Good' : 
                'Needs Improvement';

  return { score, status, details };
};

export default function Migration() {
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const currentTab = location.pathname.includes('comments') 
    ? 'comments' 
    : location.pathname.includes('call-logs')
      ? 'call-logs'
      : 'details';

  const [, setTab] = useState<tabType>("details");
  const [modal, setModal] = useState<ModalType | "">("");
  const [expandedAI, setExpandedAI] = useState<boolean[]>([]); // State to manage expanded AI recommendations
  const [sortBy, setSortBy] = useState<string>("match_score"); // State for sorting recommendations
  const [isRecommendationsExpanded, setIsRecommendationsExpanded] = useState(true);

  // Add new state for AI recommendations
  const [aiRecommendations, setAiRecommendations] = useState<AIRecommendation[]>([]);
  const [isLoadingAI, setIsLoadingAI] = useState(false);
  const [aiError, setAiError] = useState<string | null>(null);

  const toggleDropdown = (val: ModalType) => {
    setModal(val);
  };

  const closeModal = () => setModal("");

  const { user } = useAuthContext();

  const { data, error, isLoading } = useFetchMigration(param.id ?? "");
  const canViewMigrations = user?.permissions?.includes(
    "view:migrationAssessment"
  );
  useEffect(() => {
    if (!canViewMigrations && !isLoading) {
      navigate("/home");
    }
  }, [canViewMigrations, isLoading, navigate]);

  useEffect(() => {
    if (param["*"]) {
      if (tabs.includes(param["*"].split("/")[2] as tabType)) {
        setTab(param["*"].split("/")[2] as tabType);
      } else {
        setTab("details");
      }
    }
  }, [param]);

  // Add fetch function for AI recommendations
  const fetchAIRecommendations = async (leadId: string) => {
    setIsLoadingAI(true);
    setAiError(null);
    try {
      const response = await api.get<AIRecommendation[]>(`/ai-recommendation-engine/users/${leadId}/recommendations`);
      setAiRecommendations(response.data);
    } catch (error) {
      setAiError(error instanceof Error ? error.message : 'Failed to load AI recommendations');
      console.error('Error fetching AI recommendations:', error);
    } finally {
      setIsLoadingAI(false);
    }
  };

  // Add useEffect to fetch AI recommendations when component mounts
  useEffect(() => {
    if (data?.id) {
      fetchAIRecommendations(data.id);
    }
  }, [data?.id]);

  const financialAssessment = React.useMemo(() => {
    if (!data) return null;

    return calculateFinancialScore({
      cashAtHand: data.cashAtHand || 0,
      futureCash: data.futureCash || 0,
      hasAsset: data.hasAsset || false,
      assetValue: data.assetValue || 0,
      hasValidPassport: data.hasValidPassport || false,
      hasSupport: data.hasSupport || false,
    });
  }, [data]);

  // Check if recommendations exist
  const hasRecommendations = React.useMemo(() => {
    return data?.recommendations && data.recommendations.length > 0;
  }, [data?.recommendations]);

  const { handleRecommendation } = useMigrationActions(() => {});

  const handleSetMainRecommendation = () => {
      if (window.confirm('Are you sure you want to set AI recommendations as the main recommendations?')) {
        const recommendations = sortedRecommendations.map(rec => ({
          country: rec.country,
          pathway: rec.visa_type
        })) as UserRecommendation[];

      const payload = {
        recommendations,
        leadId: data?.id
      };
      
      handleRecommendation(payload);
      toast.success('Main recommendations updated successfully');
    }
  };

  if (isLoading) return <Loader />;

  if ((error as AnyObject)?.code === 403) {
    return <AccessDenied />;
  }

  const toggleAIExpansion = (index: number) => {
    setExpandedAI(prev => {
      const newExpanded = [...prev];
      newExpanded[index] = !newExpanded[index];
      return newExpanded;
    });
  };

  // Update the sorting logic to use fetched recommendations
  const sortedRecommendations = [...aiRecommendations].sort((a, b) => {
    if (sortBy === "match_score") {
      return b.match_score - a.match_score;
    } else if (sortBy === "success_probability") {
      return b.success_probability - a.success_probability;
    } else if (sortBy === "processing_time") {
      return a.timeline_estimate.localeCompare(b.timeline_estimate);
    } else if (sortBy === "costs") {
      // Extract numeric values from estimated_costs strings
      const getCostValue = (cost: string) => {
        const matches = cost.match(/\d+/g);
        return matches ? parseInt(matches[0]) : 0;
      };
      return getCostValue(a.estimated_costs) - getCostValue(b.estimated_costs);
    }
    return 0;
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="relative p-6"
    >
      <>
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-4">
            <Link to="/migration-assessment">
              <img src={back} alt="back" className="w-6 h-6" />
            </Link>
            <h1 className="text-2xl font-bold text-blackDark">Migration Details</h1>
          </div>
          <div className="flex items-center gap-3">
            <motion.div whileHover={{ scale: 1.02 }}>
              <Button
                size="medium"
                label="View Form"
                type="button"
                variant="neutral"
                onClick={() => window.open(`https://blumefy.com/migration?hash=${data?.hash}`, '_blank')}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                }
              />
            </motion.div>

            <motion.div whileHover={{ scale: 1.02 }}>
              <Button
                size="medium"
                label="Update Status"
                type="button"
                variant="neutral"
                onClick={() => toggleDropdown("status")}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                }
              />
            </motion.div>

            <motion.div whileHover={{ scale: 1.02 }}>
              <Button
                size="medium"
                label="Set Consultant"
                type="button"
                variant="neutral"
                onClick={() => toggleDropdown("consultant")}
                icon={<FaUserTie className="h-5 w-5" />}
              />
            </motion.div>

            <motion.div whileHover={{ scale: 1.02 }}>
              <Button
                size="medium"
                label="Assign"
                type="button"
                variant="neutral"
                onClick={() => toggleDropdown("assign")}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
                    />
                  </svg>
                }
              />
            </motion.div>

            <motion.div whileHover={{ scale: 1.02 }}>
              <Button
                size="medium"
                label="Add Tags"
                type="button"
                variant="neutral"
                onClick={() => toggleDropdown("tags")}
                icon={
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                  </svg>
                }
              />
            </motion.div>

            <motion.div 
              whileHover={!hasRecommendations ? { scale: 1.02 } : undefined}
              className={hasRecommendations ? 'cursor-not-allowed' : ''}
            >
              <Button
                size="medium"
                label="Add Recommendation"
                type="button"
                variant="primary"
                onClick={() => !hasRecommendations && toggleDropdown("recommendation")}
                disabled={hasRecommendations}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                }
              />
            </motion.div>
          </div>
        </div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="grid grid-cols-4 gap-4 mb-6"
        >
          {/* Status Cards */}
          <div className="bg-white rounded-lg p-4 border border-btnInactive shadow-sm hover:shadow-md transition-all duration-300">
            <h3 className="text-sm text-coolGrey mb-2">Current Status</h3>
            <span className={`text-lg font-semibold px-3 py-1 rounded-full ${
              data?.status === "Migration Journey Completed" ? "bg-green-100 text-primary" :
              data?.status === "Migration Journey ongoing" ? "bg-blue-100 text-blue-600" :
              data?.status === "Customer Not Ready" ? "bg-red-100 text-danger" :
              data?.status === "Subscribe to service" ? "bg-purple-100 text-purple-600" :
              data?.status === "Met with consultant" ? "bg-yellow-100 text-amber" :
              data?.status === "Scheduled appointment with a consultant" ? "bg-indigo-100 text-indigo-600" :
              data?.status === "Assessment form filled" ? "bg-pink-100 text-pink-600" :
              data?.status === "Incomplete" ? "bg-gray-100 text-gray-600" :
              "bg-gray-100 text-gray-600"
            }`}>
              {data?.status || "Submitted"}
            </span>
          </div>
          <div className="bg-white rounded-lg p-4 border border-btnInactive shadow-sm hover:shadow-md transition-all duration-300">
            <h3 className="text-sm text-coolGrey mb-2">Assigned To</h3>
            <div className="flex items-center gap-2">
              <div className="w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center animate-fadeIn">
                {data?.assignedTo?.userName?.[0]?.toUpperCase() || "?"}
              </div>
              <span className="text-lg font-semibold text-blackDark">
                {data?.assignedTo?.userName || "Not Assigned"}
              </span>
            </div>
          </div>
          <div className="bg-white rounded-lg border border-btnInactive p-4">
            <h3 className="text-sm text-coolGrey mb-2">Consultant</h3>
            <div className="flex items-center gap-2">
              {data?.consultant?.userName ? (
                <>
                  <div className="w-8 h-8 rounded-full bg-primary text-white flex items-center justify-center animate-fadeIn">
                    {data?.consultant?.userName?.[0]?.toUpperCase() || "?"}
                  </div>
                  <div>
                    <span className="text-lg font-semibold text-blackDark">
                      {data?.consultant?.userName}
                    </span>
                    <p className="text-xs text-coolGrey">{data?.consultant?.email}</p>
                  </div>
                </>
              ) : (
                <div className="flex items-center gap-2 text-coolGrey">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  <span>Not Assigned</span>
                </div>
              )}
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 border border-btnInactive shadow-sm hover:shadow-md transition-all duration-300">
            <h3 className="text-sm text-coolGrey mb-2">Last Updated</h3>
            <p className="text-lg font-semibold text-blackDark animate-fadeIn">
              {convertDate(new Date(data?.updatedAt))}
            </p>
          </div>
        </motion.div>

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.1 }}
          className="grid grid-cols-4 gap-4 mb-6"
        >
          {/* Account Manager Card */}
          <div className="bg-white rounded-lg border border-btnInactive p-4">
            <p className="text-sm text-coolGrey mb-1">Account Manager</p>
            <div className="flex items-center gap-2">
              {data?.accountManager?.userName ? (
                <>
                  <div className="w-8 h-8 rounded-full bg-primary flex items-center justify-center text-white font-medium">
                    {data.accountManager.userName.charAt(0)}
                  </div>
                  <div>
                    <p className="text-base font-medium text-blackDark">{data.accountManager.userName}</p>
                    <p className="text-xs text-coolGrey">{data.accountManager.email}</p>
                  </div>
                </>
              ) : (
                <div className="flex items-center gap-2 text-coolGrey">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  <span>Not Assigned</span>
                </div>
              )}
            </div>
          </div>

          {/* Customer Success Card */}
          <div className="bg-white rounded-lg border border-btnInactive p-4">
            <p className="text-sm text-coolGrey mb-1">Customer Success</p>
            <div className="flex items-center gap-2">
              {data?.customerSuccess?.userName ? (
                <>
                  <div className="w-8 h-8 rounded-full bg-primary flex items-center justify-center text-white font-medium">
                    {data.customerSuccess.userName.charAt(0)}
                  </div>
                  <div>
                    <p className="text-base font-medium text-blackDark">{data.customerSuccess.userName}</p>
                    <p className="text-xs text-coolGrey break-words w-[180px]">{data.customerSuccess.email}</p>
                  </div>
                </>
              ) : (
                <div className="flex items-center gap-2 text-coolGrey">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                  <span>Not Assigned</span>
                </div>
              )}
            </div>
          </div>

          {/* Created Card */}
          <div className="bg-white rounded-lg border border-btnInactive p-4">
            <p className="text-sm text-coolGrey mb-1">Created Date</p>
            <p className="text-base font-medium text-blackDark">
              {convertDate(new Date(data?.createdAt))}
            </p>
          </div>
        </motion.div>

        {/* Timeline Section */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="mb-6"
        >
          <div className="bg-white rounded-lg border border-btnInactive shadow-sm p-4 hover:shadow-md transition-all duration-300">
            <h3 className="text-lg font-semibold text-blackDark mb-3">Timeline</h3>
            <Timeline data={data?.timeline || []} />
          </div>
        </motion.div>

        {/* Main Content Grid */}
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.3 }}
          className="grid grid-cols-[2fr_1fr] gap-4"
        >
          <div className="space-y-4">
            <div className="bg-white rounded-lg border border-btnInactive shadow-sm hover:shadow-md transition-all duration-300">
              <div className="p-4 border-b border-btnInactive">
                <h2 className="text-lg font-semibold text-blackDark">Migration Details</h2>
              </div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-6">
                  <div className="animate-slideIn">
                    <p className="text-sm text-coolGrey mb-1">Account Name</p>
                    <p className="text-base font-medium text-blackDark">{data?.name}</p>
                  </div>
                  <div className="animate-slideIn animation-delay-100">
                    <p className="text-sm text-coolGrey mb-1">Email</p>
                    <p className="text-base font-medium text-blackDark">{data?.email}</p>
                  </div>
                  <div className="animate-slideIn animation-delay-200">
                    <p className="text-sm text-coolGrey mb-1">Phone</p>
                    <p className="text-base font-medium text-blackDark">
                      {data?.dialCode} {data?.phone}
                    </p>
                  </div>
                  <div className="animate-slideIn animation-delay-300">
                    <p className="text-sm text-coolGrey mb-1">Created Date</p>
                    <p className="text-base font-medium text-blackDark">
                      {convertDate(new Date(data?.createdAt))}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg border border-btnInactive shadow-sm hover:shadow-md transition-all duration-300">
              <div className="p-4 border-b border-btnInactive">
                <h2 className="text-lg font-semibold text-blackDark">Financial Assessment</h2>
              </div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-6">
                  <div className="animate-slideIn">
                    <p className="text-sm text-coolGrey mb-1">Financial Preparedness Score</p>
                    <div className="flex items-center gap-2">
                      <div className={`w-16 h-16 rounded-full flex items-center justify-center text-xl font-bold ${
                        (financialAssessment?.score || 0) >= 80 ? "bg-green-100 text-primary" :
                        (financialAssessment?.score || 0) >= 60 ? "bg-yellow-100 text-yellow-600" :
                        "bg-red-100 text-danger"
                      }`}>
                        {financialAssessment?.score || 0}%
                      </div>
                      <div className="flex flex-col">
                        <span className="text-base font-medium text-blackDark">
                          {financialAssessment?.status || 'Not Available'}
                        </span>
                        <div className="text-sm text-gray-500">
                          {financialAssessment?.details.map((detail, index) => (
                            <div key={index} className="flex items-center gap-1">
                              <div className="w-1 h-1 bg-gray-400 rounded-full"></div>
                              <span>{detail}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="animate-slideIn animation-delay-100">
                    <p className="text-sm text-coolGrey mb-1">Total Financial Resources</p>
                    <p className="text-base font-medium text-blackDark">
                      {((data?.cashAtHand || 0) + (data?.futureCash || 0) + (data?.hasAsset ? (data?.assetValue || 0) : 0)).toLocaleString()}
                    </p>
                    <div className="mt-2 space-y-1">
                      <p className="text-sm text-gray-500">Cash at Hand: {(data?.cashAtHand || 0).toLocaleString()}</p>
                      <p className="text-sm text-gray-500">Future Cash: {(data?.futureCash || 0).toLocaleString()}</p>
                      {data?.hasAsset && (
                        <p className="text-sm text-gray-500">Asset Value: {(data?.assetValue || 0).toLocaleString()}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            {/* Tags Section */}
            <div className="bg-white rounded-lg border border-btnInactive shadow-sm p-4 hover:shadow-md transition-all duration-300">
              <h3 className="text-sm font-semibold text-blackDark mb-3">Tags</h3>
              <div className="flex flex-wrap gap-2">
                {data?.tags?.map((tag: string) => (
                  <span key={tag} className="px-3 py-1 bg-[#0060390D] rounded-full text-sm animate-fadeIn">
                    {tag}
                  </span>
                )) || <p className="text-sm text-coolGrey">No tags added</p>}
              </div>
            </div>

            {/* Recommendations Section */}
            <div className="bg-white rounded-lg border border-btnInactive shadow-lg p-4 transition-all duration-300">
              <div 
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsRecommendationsExpanded(!isRecommendationsExpanded)}
              >
                <h3 className="text-lg font-semibold text-blackDark">Recommendations</h3>
                <div className="text-gray-400">
                  {isRecommendationsExpanded ? <FaChevronUp /> : <FaChevronDown />}
                </div>
              </div>

              <AnimatePresence>
                {isRecommendationsExpanded && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="mt-4"
                  >
                    {/* Suggested Recommendations */}
                    {data?.recommendations && data.recommendations.length > 0 ? (
                      <div className="flex flex-col gap-2">
                        <h4 className="font-semibold text-md">Suggested Recommendations</h4>
                        {data.recommendations.map((recommendation: UserRecommendation, index: number) => (
                          <div key={index} className="flex items-center justify-between p-3 border border-gray-200 rounded-md hover:bg-gray-100 transition-colors duration-200">
                            <div className="flex items-center gap-2">
                              <FaGlobe className="text-blue-600 h-5 w-5" />
                              <p className="text-base font-medium text-blackDark">Country: {recommendation.country}</p>
                            </div>
                            <div className="flex items-center gap-2">
                              <FaArrowRight className="text-primary h-5 w-5" />
                              <p className="text-base text-gray-600">Pathway: {recommendation.pathway}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-sm text-gray-500">No suggested recommendations available.</p>
                    )}

                    {/* AI Recommendations */}
                    <div className="mt-4">
                      <div className="flex justify-between items-center mb-4">
                        <div className="flex items-center gap-4">
                          <h4 className="font-semibold text-lg">AI Recommendations</h4>
                          {(!data?.recommendations || data.recommendations.length === 0) && 
                           !isLoadingAI && 
                           !aiError && 
                           aiRecommendations.length > 0 && (
                            <Button
                              size="small"
                              variant="primary"
                              label="Set as Main Recommendations"
                              onClick={handleSetMainRecommendation}
                              icon={
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                              }
                            />
                          )}
                        </div>
                        {!isLoadingAI && !aiError && aiRecommendations.length > 0 && (
                          <select
                            className="px-3 py-1 border border-gray-200 rounded-md text-sm"
                            value={sortBy}
                            onChange={(e) => setSortBy(e.target.value)}
                          >
                            <option value="match_score">Sort by Match Score</option>
                            <option value="success_probability">Sort by Success Rate</option>
                            <option value="processing_time">Sort by Processing Time</option>
                            <option value="costs">Sort by Cost</option>
                          </select>
                        )}
                      </div>
                      
                      {isLoadingAI ? (
                        <div className="flex justify-center items-center p-8">
                          <Loader />
                        </div>
                      ) : aiError ? (
                        <div className="text-gray-500 text-center p-4">
                          No AI recommendations available
                        </div>
                      ) : aiRecommendations.length === 0 ? (
                        <div className="text-gray-500 text-center p-4">
                          No AI recommendations available
                        </div>
                      ) : (
                        sortedRecommendations.map((recommendation, index) => (
                          <RecommendationCard
                            key={index}
                            recommendation={recommendation}
                            expanded={expandedAI[index]}
                            onToggle={() => toggleAIExpansion(index)}
                          />
                        ))
                      )}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>

        <div className="pb-4 flex justify-start items-center gap-8 ml-16 font-medium mt-8">
          <Link
            to=""
            role="tab"
            className={`pb-1 cursor-pointer ${
              currentTab === "details"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            }`}
          >
            Details
          </Link>

          <Link
            to="comments"
            role="tab"
            className={`pb-1 cursor-pointer ${
              currentTab === "comments"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            }`}
          >
            Comments
          </Link>

          <Link
            to="call-logs"
            role="tab"
            className={`pb-1 cursor-pointer ${
              currentTab === "call-logs"
                ? "text-primary border-b-2 border-b-primary"
                : "text-greyDark"
            }`}
          >
            Call Logs
          </Link>
        </div>

        <Outlet
          context={
            {
              data: { ...data },
              next: () => {},
              prev: () => {},
            } satisfies ContextType
          }
        />
        <Modal isShown={modals.includes(modal as ModalType)}>
          {modal === "assign" && (
            <Assign onClose={closeModal} type={modal as AssignType} lead={data} />
          )}
          {modal === "status" && <Status data={data} onClose={closeModal} />}
          {modal === "consultant" && (
            <Consultant onClose={closeModal} data={data} />
          )}
          {modal === "recommendation" && (
            <Recommendation onClose={closeModal} data={data} />
          )}
          {modal === 'tags' && <Tags onClose={closeModal} data={data} />}
        </Modal>
      </>
    </motion.div>
  );
}

