import back from "assets/back.svg";
import Button from "components/Button";
import Checkbox from "components/inputs/Checkbox";
import Input from "components/inputs/Input";
import TextArea from "components/inputs/TextArea";

import { useNavigate, useParams } from "react-router-dom";
import { useAdminHandlers, useFetchPermissions, useFetchRole } from "./useAdminHandlers";
import { startCase } from "lodash";
import { AnyObject } from "utils/types";
import { ChangeEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import { CreateRoleForm } from "./CreateRole";
import { object, string } from "yup";
import { toast } from "react-hot-toast";
import Loader from "components/Loader";

let permArray: Array<string> = [];

const EditRole = () => {
  const navigate = useNavigate();

  const { permissions } = useFetchPermissions();

  const { roleId } = useParams();
  const { role, loadingRole } = useFetchRole(roleId ?? "");

  const [arrPerms, setArrayPerms] = useState<Array<string>>(role?.permissions ?? []);

  const { handleUpdateRole, isError, error, loading } = useAdminHandlers();

  const { values, handleChange, touched, errors, handleSubmit, handleBlur, setFieldValue } =
    useFormik<CreateRoleForm & {id: string}>({
      initialValues: {
        name: role?.name,
        permissions: [],
        description: role?.description,
        id: role?.id
      },
      validationSchema: object({
        name: string().required("A role name must be provided"),
        description: string().required("Role description is required"),
      }),
      onSubmit: (values) => {
        values.permissions = arrPerms;
        handleUpdateRole(values);
      },
    });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    if (!arrPerms.includes(name)) {
      permArray = [...arrPerms, name];
    } else {
      permArray = arrPerms.filter((perm) => perm !== name);
    }
    setArrayPerms(permArray);
  };

  useEffect(() => {
   setFieldValue("name", role?.name ?? "");
   setFieldValue("description", role?.description ?? "");
   setFieldValue("id", role?.id ?? "");
   setArrayPerms(role?.permissions ?? [])
  }, [role, setFieldValue]);

  useEffect(() => {
    if (isError) {
      toast.error((error as AnyObject)?.message || "An error occurred");
    }
  }, [error, isError]);

  if(loadingRole) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="pt-2 mb-8 cursor-pointer flex justify-start items-center gap-3 text-greyDark font-bold"
        onClick={() => navigate("/administrators")}
      >
        <img src={back} alt="back" />
        Administrators
      </div>

      <form onSubmit={handleSubmit}>
        <div className="bg-white shadow-sm max-w-lg mx-auto rounded border border-btnInactive gap-8">
          <div className="border-b border-btnInactive py-5 px-6 mx-auto">
            <p className="flex items-center justify-center font-bold">
              Edit Role
            </p>
          </div>
          <div className="mt-4 mx-12">
            <Input
              id="role"
              label="Role Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              error={`${!!errors["name"] && !!touched["name"]}`}
              errorMessage={errors["name"]}
              onBlur={handleBlur}
            />

            <TextArea
              id="description"
              label="Description"
              name="description"
              value={values.description}
              onChange={handleChange}
              error={`${!!errors["description"] && !!touched["description"]}`}
              errorMessage={errors["description"]}
              onBlur={handleBlur}
            />
          </div>

          <div className="mx-12">
            <p className="text-blackDark font-medium">Permissions</p>
            <div className="flex justify-start">
              <div className=" flex flex-wrap gap-x-12">
                <div className=" flex flex-col">
                  {permissions?.map((permission: AnyObject) => {
                    const { name, description, id } = permission;
                    const [action, entity] = name.split(":");
                    return (
                      <div
                        key={id}
                        className="flex justify-start items-center gap-4 my-4"
                      >
                        <Checkbox
                          id={id}
                          name={name}
                          value={name}
                          onChange={onChange}
                          data={arrPerms}
                        />
                        <p
                          className="text-sm text-blackDark"
                          title={description}
                        >
                          {startCase(action)} {startCase(entity)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-60 flex justify-center items-center">
          <Button
            disabled={loading}
            size="small"
            label="Update Role"
            type="submit"
            loading={loading}
          />
        </div>
      </form>
    </>
  );
};

export default EditRole;
