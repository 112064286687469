import Button from "components/Button";
import { useDocumentTitle } from "hooks/useDocument";
import { Fragment } from "react";
import success from "assets/success.svg";
import { useNavigate } from "react-router-dom";

function PasswordChangeSuccess() {
  useDocumentTitle("Password Change success");
  const navigate = useNavigate();

  return (
    <Fragment>
        <img src={success} alt="success" className="mx-auto my-4" />
      <p className="text-blackDarkDark text-xl font-bold text-center mb-8">
        Successful
      </p>
      <p className="text-lightText text-center text-sm">Password changed successfully</p>

      <Button label="Go to Login Page" onClick={() => navigate("/")}/>
    </Fragment>
  );
}

export default PasswordChangeSuccess;
